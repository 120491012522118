import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonButtons, IonBackButton,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import "./TermsConditions.css";

type Props = {
    history: any,
};

type State = {
};

class TermsConditions extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            userEmail: undefined,
        }
    }

    render() {
        return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/"/>
                    </IonButtons>
                    <IonTitle>Termini e condizioni</IonTitle> {/* TO LOCALIZE THIS */}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className="termsConditionsGrid">
                    
                    <h2>Termini e condizioni</h2>
                    <h3>
                        Aggiornata il 20 aprile 2020 alle 1:00pm GMT
                    </h3>
                    <p>
                        Estro technologies s.r.l., (“Estro”, “Piattaforma”, “Estro technologies”, “Maestro”, “App Maestro”, 
                        “Maestro app”, “Estro technologies s.r.l.”, “Agorà” o “noi”) è lieta di fornirti l’accesso al 
                        Piattaforma Estro technologies s.r.l., che consente ad un utente (“Utente” o “tu”) di supportare 
                        un soggetto con Disturbi del Neurosviluppo, le funzionalità della piattaforma vengono qui definiti 
                        come “Servizi” e possono essere modificati di volta in volta a nostra esclusiva discrezione. 
                        Registrandosi sulla piattafroma o utilizzando i Servizi, l’utente accetta di essere vincolato 
                        dalle presenti Condizioni d’uso (il “Contratto”) che potremmo aggiornare di volta in volta. 
                        L’uso continuato dei Servizi costituisce il consenso dell’utente a tali modifiche.
                    </p>
                    <br />
                    <p>
                        LEGGERE ATTENTAMENTE IL PRESENTE CONTRATTO E CONTROLLARE QUESTI TERMINI DI UTILIZZO PERIODICAMENTE 
                        PER I CAMBIAMENTI. SE NON ACCETTI QUESTI TERMINI DI UTILIZZO, NON PUOI UTILIZZARE I SERVIZI.
                    </p>
                    <p>
                        La nostra Informativa sulla privacy, che spiega come utilizziamo i tuoi dati personali, si trova 
                        all’indirizzo https://ma.estro.ai/legal/privacy-policy ed è considerata parte del presente
                        Accordo. Utilizzando i Servizi, accetti che le tue informazioni possano essere raccolte e 
                        utilizzate in conformità con la nostra Informativa sulla privacy e il presente Accordo.
                    </p>
                    <br />

                    <h2>1. Condizioni generali</h2>
                    <h3>1.1 Account.</h3>
                    <p>
                        È necessario un account Maestro per sfruttare appieno i Servizi. L’account può essere 
                        creato direttamente compilando il modulo appropriato. Accetti di fornire solo informazioni 
                        accurate e veritiere in relazione alla creazione del tuo account e di aggiornare le tue 
                        informazioni se necessario in modo che rimangano accurate e aggiornate. Non è possibile 
                        trasferire il proprio account a terzi. Sei responsabile di mantenere riservate le informazioni 
                        di accesso al tuo account e di tutte le attività che si verificano sotto il tuo account. 
                        Accetti di avvisarci immediatamente se sospetti o diventi consapevole di qualsiasi utilizzo 
                        non autorizzato del tuo account o di altre violazioni della sicurezza dei Servizi. Avrai la 
                        possibilità di scegliere un nome utente disponibile da collegare al tuo account.
                    </p>
                    <p>
                        Si noti che Estro technologies s.r.l. si riserva il diritto in qualsiasi momento di disattivare, 
                        riallocare o rinominare il nome utente a sua esclusiva discrezione. In caso di disattivazione, 
                        riallocazione o ridenominazione del nome utente, tutti i Contenuti e servizi dell’utente 
                        continueranno a rimanere associati al proprio account, soggetti ai termini del presente Accordo.
                    </p>
                    <h3>1.2 Accesso.</h3>
                    <p>
                        Possiamo, a nostra esclusiva discrezione, rifiutare di offrire i Servizi a qualsiasi persona o 
                        entità. In conformità con il presente Accordo e la Sezione 8, possiamo interrompere il diritto 
                        di utilizzare i Servizi o parte di essi e bloccare o impedire l’accesso e l’utilizzo futuri dei 
                        Servizi o parte di essi.
                    </p>
                    <h3>1.3 Minori.</h3>
                    <p>
                        I SERVIZI NON SONO PREVISTI PER I BAMBINI SOTTO I 13 ANNI, e le persone di età inferiore ai 13 
                        anni non possono registrarsi o utilizzare i Servizi. Dichiari e garantisci di avere più di 18 
                        anni o, se hai più di 13 e meno di 18 anni, sei un minorenne emancipato, o possiedi il consenso 
                        legale dei genitori o dei tutori, e sei pienamente in grado e competente a rispettare e rispettare 
                        i termini, le condizioni, gli obblighi, le affermazioni, le dichiarazioni e le garanzie stabilite 
                        nel presente Accordo. Questa disposizione è nulla ove vietata dalla legge e il diritto di accedere 
                        ai Servizi è revocato in tali giurisdizioni.
                    </p>
                    <br/>

                    <h2>2. Acquisti</h2>
                    <h3>2.1. Rappresentazioni.</h3>
                    <p>
                        L’UTENTE RAPPRESENTA E GARANTISCE DI AVERE IL DIRITTO LEGALE DI UTILIZZARE IL CONTO PAYPAL E 
                        QUALSIASI CONTO BANCARIO O CARTA DI CREDITO, CHE SI UTILIZZA PER PAGARE I SERVIZI. 
                        Accetti di pagare tutte le spese sostenute da te o da qualsiasi utente del tuo account ai prezzi 
                        in vigore quando tali spese sono sostenute. Sarai inoltre responsabile del pagamento di eventuali 
                        imposte applicabili relative ai tuoi acquisti. La verifica delle informazioni applicabili a un 
                        acquisto può essere richiesta prima della nostra accettazione di qualsiasi ordine.
                    </p>
                    <br />

                    <h2>3. Proprietà del contenuto di Estro technologies s.r.l.</h2>
                    <h3>3.1 In generale.</h3>
                    <p>
                        Utilizzando i Servizi, avrai a che fare con i “Contenuti di Estro technologies s.r.l.”, 
                        che includono tutte le immagini, testo, informazioni, dati, audio, video, grafica e altro 
                        materiale incluso o reso disponibile attraverso i Servizi, ad esclusione del Contenuto dell’utente. 
                        Il “Contenuto dell’utente” comprende tutti i video, le immagini, l’audio e il contenuto correlato, 
                        nonché i commenti dell’utente, caricati dagli Utenti, che compongono i Servizi. Salvo quanto 
                        diversamente stabilito nel presente Accordo, non rivendichiamo la proprietà di alcun 
                        Contenuto dell’utente.
                    </p>

                    <h3>3.2 Proprietà.</h3>
                    <p>
                        Tutto il contenuto di Estro technologies s.r.l. è di proprietà di Estro technologies s.r.l., o 
                        dei suoi licenziatari ed è protetto dalle leggi italiane, europee e internazionali sul copyright, 
                        dalle leggi sui marchi e / o da altri diritti e leggi sulla proprietà. Tra l’utente, Estro 
                        technologies s.r.l. e i suoi licenziatari, Estro technologies s.r.l. o i suoi licenziatari possiedono 
                        e mantengono, esclusivamente, tutti i diritti, il titolo e l’interesse nei e sui Servizi e su tutti 
                        i Contenuti di Estro technologies s.r.l. che noi o i nostri licenziatari creano e che mettono a 
                        disposizione dell’utente tramite i Servizi, inclusi, a titolo esemplificativo, tutti i diritti 
                        d’autore, i diritti sui marchi, i diritti segreti commerciali, i diritti sui brevetti, i diritti 
                        sui database e altri diritti di proprietà intellettuale e di proprietà. Il marchio “Estro technologies 
                        s.r.l.”, “Maestro” e tutti i loghi associati e gli altri marchi, marchi di servizio, loghi e 
                        nomi commerciali visualizzati su o in connessione con i Servizi sono marchi e marchi di servizio 
                        registrati e non registrati di Estro technologies s.r.l., o di terzi in Italia e / o in altri paesi.
                    </p>
                    <p>
                        Il tuo utilizzo dei Servizi non garantisce alcuna proprietà su qualsiasi Contenuto Estro technologies 
                        s.r.l. e, fatta eccezione per la licenza limitata che ti concediamo ai sensi del presente Accordo, 
                        il tuo utilizzo dei Servizi non concede all’utente alcuna licenza o autorizzazione in base a copyright, 
                        marchio o altro intellettuale diritti di proprietà di Estro technologies s.r.l. o di terzi. 
                        Ci riserviamo tutti i diritti non espressamente concessi all’utente nel presente Accordo.
                    </p>

                    <h3>3.3. Licenza di servizio e contenuto.</h3>
                    <p>
                        Ti garantiamo una licenza limitata, non esclusiva, non concedibile in licenza e non trasferibile 
                        per utilizzare i Servizi così come sono forniti da noi, solo come stabilito nel presente Accordo. 
                        Salvo quanto espressamente consentito nel presente Accordo, non è possibile riprodurre, distribuire, 
                        adattare, modificare, tradurre, creare opere derivate, pubblicare o utilizzare in altro modo 
                        qualsiasi parte dei Servizi o dei Contenuti Estro technologies s.r.l. per qualsiasi scopo senza 
                        espressa autorizzazione scritta da parte nostra o del titolare dei diritti applicabile. 
                        È severamente vietato qualsiasi sfruttamento commerciale dei Servizi o dei Contenuti 
                        Estro technologies s.r.l. senza espressa autorizzazione scritta da parte nostra o del titolare 
                        dei diritti applicabile.
                    </p>
                    <h3>3.4 Software.</h3>
                    <p>
                        Tutto il software e il software as-a-service (SAAS) utilizzato in connessione con i Servizi 
                        (“Software”) sono di proprietà nostra o di terzi, e salvo quanto potrebbe essere richiesto 
                        per esercitare la concessione di licenza di cui sopra, qualsiasi uso, ridistribuzione è vietata 
                        la vendita, la decompilazione, il reverse engineering, lo smontaggio, la traduzione o la riduzione 
                        di tale software in una forma leggibile dall’uomo. Accetti che possiamo aggiornare il Software 
                        senza preavviso, in qualsiasi momento e a nostra esclusiva discrezione, e che il presente Accordo 
                        si applicherà a tutte le versioni aggiornate.
                    </p>
                    <br/>

                    <h2>4. Contenuto dell’utente</h2>
                    <p>
                        Durante il funzionamento dei Servizi, gli Utenti possono caricare determinati Contenuti 
                        dell’utente inclusi adesivi video e immagini. L’utente rimane sempre il proprietario dei 
                        tuoi contenuti utente e Estro technologies s.r.l. non rivendica alcun diritto di proprietà 
                        sui tuoi contenuti utente. Il contenuto dell’utente è altrimenti soggetto alle seguenti 
                        disposizioni. Il presente Accordo si applica a tutti i Contenuti dell’utente forniti dall’utente 
                        sul nostro sito Web durante il periodo di validità del presente Accordo e sulle nostre piattaforme, 
                        tra cui l’applicazione Maestro.
                    </p>
                    <h3>4.1 In generale.</h3>
                    <p>
                        L’utente è il solo responsabile di garantire che i Contenuti dell’utente inviati ai Servizi 
                        siano conformi alle leggi applicabili e ai diritti di terzi, inclusi ma non limitati a 
                        diritti di proprietà intellettuale, diritti alla privacy e diritti di pubblicità. 
                        Accetti che qualsiasi informazione inclusa nel Contenuto dell’utente possa essere 
                        utilizzata in conformità con la nostra Informativa sulla privacy. Estro technologies 
                        s.r.l. ha sempre il diritto, a sua esclusiva discrezione, di accettare o rifiutare 
                        qualsiasi Contenuto dell’utente.
                    </p>
                    <h3>4.2 Licenza dei contenuti dell’utente.</h3>
                    <h4>4.2.1 Utilizzo da parte nostra.</h4>
                    <p>
                        Utilizzando i Servizi, concedi a Estro technologies s.r.l. il diritto e la licenza in tutto 
                        il mondo, non esclusivi, esenti da royalty, perpetue, irrevocabili, sub-licenziabili 
                        (attraverso più livelli) per utilizzare, riprodurre, visualizzare pubblicamente, 
                        distribuire e adattare il Contenuto dell’utente per finalità di sviluppo, distribuzione, 
                        fornitura, miglioramento e promozione dei Servizi, delle nostre attività e dei Contenuti dell’utente. 
                        Concedi inoltre a Estro technologies s.r.l. il diritto di utilizzare il tuo nome e i tuoi marchi, 
                        se presenti, in relazione al nostro uso dei tuoi Contenuti dell’utente.
                    </p>
                    <h4>4.2.2 Utilizzo gratuito da parte di terzi.</h4>
                    <p>
                        Gli utenti possono rendere disponibili i propri contenuti utente ad altri utenti per il download 
                        gratuito tramite una delle numerose licenze Creative Commons. Per ulteriori informazioni, 
                        consultare https://creativecommons.org/licenses/. L’utente è l’unico responsabile della scelta 
                        della licenza appropriata, se presente, in base alla quale il contenuto è disponibile per il 
                        download. Scaricando i Contenuti dell’utente resi disponibili da altri Utenti, l’utente accetta 
                        di rispettare i termini della licenza Creative Commons che si applica al momento del download.
                    </p>

                    <h3>4.3 Raffigurazione dell’utente.</h3>
                    <p>
                        Inviando i Contenuti dell’utente, dichiari e garantisci che
                    </p>
                    <ul>
                        <li>
                            possiedi o controlli in altro modo tutti i diritti sui Contenuti dell’utente
                        </li>
                        <li>
                            l’uso dei Contenuti dell’utente non viola il presente Accordo o la legge e non violerà alcun diritti o danni a qualsiasi persona o entità                            
                        </li>
                        <li>
                            i Contenuti dell’utente non violano le restrizioni sui contenuti aggiuntivi stabilite nella Sezione 5 del presente Accordo e che
                        </li>
                        <li>
                            nella misura massima consentita dalla legge, l’utente dichiara irrevocabilmente e rinunciare incondizionatamente a tutti i diritti morali di qualsiasi tipo, o diritti di natura simile, nei Contenuti dell’utente, a beneficio o a favore di Estro technologies s.r.l., incluso qualsiasi diritto ad essere associato ai Contenuti dell’utente (diritto di paternità) o qualsiasi diritto per limitare o impedire la modifica o l’uso dei Contenuti dell’utente in qualsiasi modo (diritto all’integrità). Nella misura in cui l’Utente mantiene tali diritti morali ai sensi della legge applicabile, l’utente accetta di non rivendicarli contro Estro technologies s.r.l. o altri utenti di Estro technologies s.r.l. in alcun modo.
                        </li>
                    </ul>
                    <h3>
                        4.4 Feedback dell’utente.
                    </h3>
                    <p>
                        Apprezziamo il tuo feedback e suggerimenti sui nostri Servizi, ma accetti che eventuali feedback o suggerimenti inviati a noi sui Servizi siano interamente volontari e che saremo liberi di utilizzare qualsiasi feedback o suggerimento che riteniamo opportuno e senza alcun obbligo nei tuoi confronti.
                    </p>
                    <h3>
                        4.5 Diritto di monitorare, moderare o rimuovere.
                    </h3>
                    <p>
                        Accetti di sostenere tutti i rischi associati ai Contenuti dell’utente. L’utente è il solo responsabile 
                        della salvaguardia del contenuto dell’utente e Estro technologies s.r.l. non ha l’obbligo di archiviare 
                        copie del contenuto dell’utente per la futura disponibilità dell’utente o di qualsiasi altro utente, 
                        salvo quanto diversamente previsto dal presente accordo. Estro technologies s.r.l. non consente la 
                        violazione dei diritti di proprietà intellettuale sui Servizi e rimuoverà i Contenuti dell’utente dai 
                        Servizi se notificato correttamente che tali Contenuti dell’utente violano i diritti di proprietà 
                        intellettuale altrui. Ci riserviamo il diritto di rimuovere i Contenuti dell’utente dai Servizi, 
                        in tutto o in parte, senza preavviso, per qualsiasi motivo o per nessun motivo. Senza limitare il 
                        nostro diritto di chiudere l’account ad un Utente ai sensi della Sezione 8 del presente Accordo, 
                        ci riserviamo il diritto di chiudere l’account di qualsiasi Utente dei Servizi a cui è stata 
                        notificata l’attività di violazione se per più di due volte e / o se siano stati rimossoùi i 
                        Contenuti dell’utente dal Servizio più di due volte. Ci riserviamo inoltre il diritto di decidere 
                        se il Contenuto dell’utente è appropriato e conforme al presente Accordo per violazioni diverse 
                        dalle violazioni della legge sulla proprietà intellettuale. Ciò si estenderà al diritto di 
                        Estro technologies s.r.l. di modificare, modificare, moderare, riformattare, cambiare o altrimenti 
                        rimuovere tutte o parte delle descrizioni, commenti e / o annotazioni che Tu e / o terze parti 
                        aggiungerete e / o farete in relazione al contenuto dell’utente, in qualsiasi modo potremmo 
                        determinare, ogni volta che lo riterremo appropriato.
                    </p>
                    <br/>

                    <h2>5. Limitazioni e obblighi dell’utente</h2>
                    <p>
                        Per noi è importante che i Servizi vengano utilizzati in modo sicuro e in conformità con la legge, 
                        per il godimento di tutti gli Utenti. Accetti di non utilizzare i Servizi per:
                    </p>
                    <ul>
                        <li>
                            Decompilare, disassemblare, decodificare, copiare, trasferire o altrimenti utilizzare i Servizi, 
                            i Contenuti di Estro technologies s.r.l. e i Contenuti dell’utente salvo quanto consentito dal 
                            presente Accordo;
                        </li>

                        <li>
                            Promuovere qualsiasi attività illegale, o sostenere, promuovere o assistere qualsiasi atto illecito;
                        </li>

                        <li>
                            Decompilare, disassemblare, decodificare, copiare, trasferire o altrimenti utilizzare i Servizi, i 
                            Contenuti Estro technologies s.r.l. e i Contenuti dell’utente salvo quanto consentito dal presente Accordo;
                        </li>

                        <li>
                            Violare i diritti legali (compresi i diritti di pubblicità e privacy) di altri o contenere qualsiasi materiale che possa dar luogo a responsabilità civile o penale ai sensi delle leggi o dei regolamenti applicabili o che altrimenti potrebbe essere in conflitto con il presente Accordo e la nostra Informativa sulla privacy;
                        </li>

                        <li>
                            Trasmettere qualsiasi materiale o contenuto pornografico, minaccioso, molesto, calunnioso, orientato all’odio, dannoso, 
                            diffamatorio, razzista, xenofobo o illegale;
                        </li>

                        <li>
                            Trasmettere qualsiasi materiale o contenuto che tenti di dichiarare falsamente o altrimenti travisare la tua identità 
                            o affiliazione con una persona o entità;
                        </li>

                        <li>
                            Trasmettere materiale o contenuti che promuovono, forniscono o si riferiscono a informazioni istruttive 
                            su attività illegali o promuovono danni fisici a persone o gruppi;
                        </li>

                        <li>
                            Trasmettere o altrimenti rendere disponibile qualsiasi pubblicità non richiesta o non autorizzata, 
                            materiale promozionale, “posta indesiderata”, “spam”, “lettere a catena”, “schemi piramidali” o 
                            qualsiasi altra forma di sollecitazione;
                        </li>
                        <li>
                            Trasmettere o incoraggiare la trasmissione di qualsiasi materiale che possa violare i diritti di 
                            proprietà intellettuale o altri diritti di terzi, inclusi marchi, copyright, brevetti o diritti 
                            di pubblicità, o che costituisce o promuove in altro modo materiali o merci contraffatti;
                        </li>

                        <li>
                            Utilizzare i servizi in modo tale che:
                            <ul>
                                <li>
                                    possa interrompere, sospendere, rallentare o ostacolare la continuità dei Servizi;
                                </li>

                                <li>
                                    costituire un’intrusione o tentare di entrare nei Servizi o nei sistemi informatici o di rete 
                                    di Estro technologies s.r.l.;
                                </li>

                                <li>
                                    dirotterà le risorse di sistema dei Servizi,
                                </li>

                                <li>
                                    potrebbe esercitare un carico sproporzionato sull’infrastruttura dei Servizi e
                                </li>

                                <li>
                                    costituire un attacco alle misure di sicurezza e di autenticazione dei Servizi o dei sistemi informatici 
                                    o di rete di Estro technologies s.r.l..
                                </li>
                            </ul>
                        </li>

                        <li>
                            Intromettersi nel sistema informatico di una terza parte, intraprendere qualsiasi attività che possa danneggiare, controllare, interferire con o intercettare tutto o parte del sistema informatico di una terza parte e violare la sua integrità o sicurezza, oppure trasmettere in altro modo materiali o contenuti dannosi per terzi sistemi di informazione delle parti (inclusi ma non limitati a virus, worm, Trojan);
                        </li>

                        <li>
                            All’utente non è consentito modificare il design o alterare l’aspetto della UI di Estro technologies s.r.l. (ad es. in incorporamenti o acquisizioni di schermate) se non utilizzando le opzioni di personalizzazione fornite da Estro technologies s.r.l. e in base al piano dell’utente (gratis o a pagamento). La rimozione, nascondere o alterare la filigrana del logo Estro technologies s.r.l. è vietata a tutti gli utenti.
                        </li>

                        <li>
                            Utilizzare i Servizi per scopi diversi da quelli per i quali sono stati progettati.
                        </li>
                    </ul>
                    <p>
                        Accetti di informare prontamente Estro technologies s.r.l. di qualsiasi reclamo, reclamo o azione legale 
                        relativo al Contenuto dell’utente che hai caricato utilizzando i Servizi.
                    </p>
                    <br />

                    <h2>6. Esonero di responsabilità, limitazioni di responsabilità, indennità</h2>
                    <p>ESCLUSIONE DI GARANZIE.</p>
                    <p>
                        Estro technologies s.r.l. si impegna a fornire i Servizi diligentemente e professionalmente, 
                        altrimenti non rilascia dichiarazioni o garanzie di alcun tipo in merito ai Servizi o al 
                        Contenuto Estro technologies s.r.l..
                    </p>
                    <p>
                        CONVENI ESPRESSAMENTE CHE IL TUO UTILIZZO DEI SERVIZI È A TUO RISCHIO. I SERVIZI SONO FORNITI 
                        “COSÌ COME SONO” E “QUANDO DISPONIBILI” PER IL TUO UTILIZZO, SENZA GARANZIE DI ALCUN TIPO, 
                        ESPRESSI O IMPLICITI, A MENO CHE TALE GARANZIE SIANO LEGALMENTE NON ESCLUSIVE DI ESCLUSIONE. 
                        ESTRO TECHNOLOGIES S.R.L., ESCLUDE ESPRESSAMENTE QUALSIASI RAPPRESENTAZIONE E GARANZIA, ESPRESSA 
                        O IMPLICITA, COMPRESO:
                    </p>
                    <ul>
                        <li>
                            CHE I SERVIZI, IL SITO WEB E IL CONTENUTO DI ESTRO TECHNOLOGIES S.R.L. SODDISFERANNO I VOSTRI REQUISITI, 
                            SARANNO SEMPRE DISPONIBILI, ACCESSIBILI, NON INTERROTTI, TEMPESTIVI, SICURI O FUNZIONANNO SENZA ERRORE;
                        </li>
                        <li>
                            PER QUANTO RIGUARDA I RISULTATI CHE POSSONO ESSERE OTTENUTI DALL’OPERAZIONE, DALL’UTILIZZO O DALL’ALTRA 
                            ESPLICAZIONE DEI SERVIZI, DEL SITO WEB O DEL CONTENUTO DI ESTRO TECHNOLOGIES S.R.L.; E
                        </li>
                        <li>
                            QUANTO SULL’ACCURATEZZA O AFFIDABILITÀ DI QUALSIASI INFORMAZIONE OTTENUTA DAI SERVIZI, SITO WEB 
                            O CONTENUTO DI Estro technologies s.r.l..
                        </li>
                    </ul>
                    <p>
                        Nessun consiglio o informazione ottenuta da Estro technologies s.r.l., o in altro modo attraverso i 
                        Servizi, creerà alcuna garanzia non espressamente dichiarata nel presente documento.
                    </p>
                    <p>
                        LIMITAZIONE DI RESPONSABILITÀ.
                    </p>
                    <p>
                        LA MASSIMA RESPONSABILITÀ DELL’AGGREGATO DI ESTRO TECHNOLOGIES S.R.L. PER QUALSIASI UTENTE SARÀ 
                        L’IMPORTO PAGATO PER IL PERIODO IN CUI VIENE PRESENTE LA RESPONSABILITÀ, SOLO SE L’UTENTE HA 
                        PAGATO LE COMMISSIONI PER L’UTILIZZO DEI SERVIZI. ESTRO TECHNOLOGIES S.R.L. NON SARÀ INOLTRE 
                        RESPONSABILE DI QUALSIASI DANNO DI ALCUN TIPO DERIVANTE DALL’UTILIZZO DEL SERVIZIO O DA 
                        QUALSIASI INFORMAZIONE, CONTENUTO, DISEGNO, PRODOTTI O SERVIZI DI ESTRO TECHNOLOGIES S.R.L. 
                        INCLUSI O ALTRIMENTI DISPONIBILI PER L’UTENTE ATTRAVERSO IL SERVIZIO, COMPRESO, MA NON LIMITATO 
                        A DANNI DIRETTI, INDIRETTI, INCIDENTALI, PUNITIVI E CONSEQUENZIALI, A MENO CHE ALTRIMENTI 
                        SPECIFICATI NELLA SCRITTURA.
                    </p>
                    <p>
                        ALCUNE LEGGI DI STATO NON CONSENTONO LIMITAZIONI SU GARANZIE IMPLICITE O ESCLUSIONE O LIMITAZIONE 
                        DI ALCUNI DANNI. SE QUESTE LEGGI SI APPLICANO A TE, ALCUNE O TUTTE LE ESCLUSIONI DI RESPONSABILITÀ, 
                        ESCLUSIONI O LIMITAZIONI DI CUI SOPRA POTREBBERO NON ESSERE APPLICABILI E POTREBBE AVERE DIRITTI 
                        AGGIUNTIVI.
                    </p>
                    <br />
                    <p><b>Indennità.</b></p>
                    <p>
                        Accetti di indennizzare e detenere Estro technologies s.r.l. e le sue affiliate, sussidiarie, 
                        proprietari, direttori, funzionari, dipendenti e agenti inoffensivi da e contro qualsiasi 
                        rivendicazione, richiesta, causa, procedimento, responsabilità, giudizio, perdita, danno, 
                        spese e costi (inclusi senza limitazione ragionevoli spese legali) valutate o sostenute da 
                        Estro technologies s.r.l. direttamente o indirettamente, in relazione o derivanti da:
                    </p>
                    <ul>
                        <li>
                            inadempienza al presente Accordo;
                        </li>

                        <li>
                            violazione degli obblighi previsti dal presente Accordo;
                        </li>

                        <li>
                            l’utilizzo dei diritti concessi di seguito, inclusi, a titolo esemplificativo, eventuali reclami da parte di terzi;
                        </li>

                        <li>
                            la tua violazione di qualsiasi diritto di terzi, incluso senza limitazione qualsiasi proprietà intellettuale, 
                            diritto di pubblicità o diritto alla privacy; e / o
                        </li>

                        <li>
                            qualsiasi rivendicazione che uno qualsiasi dei Contenuti dell’utente abbia causato danni a terzi.
                        </li>
                    </ul>
                    <br/>

                    <h2>7. Reclami sulla proprietà intellettuale</h2>
                    <p>
                        Estro technologies s.r.l. rispetta i diritti di proprietà intellettuale di terzi e rispetta le 
                        disposizioni in materia di proprietà intellettuale. Rispondiamo a chiare comunicazioni relative a 
                        presunte violazioni del copyright e i materiali in violazione pubblicati dagli Utenti possono 
                        essere identificati e rimossi ai sensi della presente procedura di reclamo.
                    </p>
                    <p>
                        Nel caso in cui ritenessi che qualsiasi Contenuto dell’utente sui Servizi violi il tuo copyright, 
                        ti preghiamo di presentare una notifica di violazione al nostro agente designato:
                    </p>
                    
                    <p>
                        Lorenzo Daidone<br/>
                        Estro technologies s.r.l.,<br/>
                        Via Savona 123,<br/>
                        20144 Milano (MI)<br/>
                        Italy
                    </p>

                    <p>
                        È preferibile la notifica elettronica.
                    </p>

                    <p>
                        Per essere efficace, qualsiasi avviso inviato al nostro Agente per il Copyright deve rispettare 
                        i requisiti stabiliti dalle disposizioni in materia di copyright, e deve includere sotanzialmente 
                        quanto segue:
                    </p>
                    <ol>
                        <li>
                            Una firma fisica o elettronica di una persona autorizzata ad agire per conto del proprietario di un 
                            diritto esclusivo presumibilmente violato;
                        </li>

                        <li>
                            Identificazione dell’opera protetta da copyright che si ritiene sia stata violata o, se più opere 
                            protette da copyright in un singolo sito online sono coperte da un’unica notifica, un elenco 
                            rappresentativo di tali opere in quel sito;
                        </li>

                        <li>
                            Identificazione del materiale che si ritiene sia in violazione o che sia oggetto di attività illecita 
                            e che deve essere rimosso o al quale si deve disabilitare l’accesso e informazioni ragionevolmente 
                            sufficienti per consentirci di individuare il materiale;
                        </li>

                        <li>
                            Una dichiarazione in cui si ritiene in buona fede che l’uso del materiale nel modo in cui è stato contestato 
                            non è autorizzato dal proprietario del copyright, dal suo agente o dalla legge; e
                        </li>

                        <li>
                            Una dichiarazione attestante che le informazioni contenute nella notifica sono esatte e, a pena di spergiuro, 
                            che l’utente è autorizzato ad agire per conto del proprietario di un diritto esclusivo presumibilmente violato.
                        </li>
                    </ol>
                    <p>
                        Estro technologies s.r.l., dopo aver ricevuto la tua notifica conforme di violazione del copyright, contatterà 
                        l’Utente che ha pubblicato i Contenuti dell’utente presumibilmente in violazione dell’avviso di violazione e 
                        rimuoverà rapidamente il Contenuto utente presumibilmente in violazione. È nostra politica documentare tutte le 
                        notifiche di presunta violazione su cui agiamo. Una copia della notifica può essere inviata a una o più terze 
                        parti che possono renderla disponibile al pubblico. Si informa che è nostra politica chiudere gli account degli 
                        utenti che violano ripetutamente il presente Accordo e / o la Politica sul Diritto D’Autore.
                    </p>
                    <p>
                        Se sei un Utente e il materiale che hai caricato nei Servizi è stato rimosso o disabilitato, puoi 
                        presentare una contronotifica. Per essere efficace, la contro notifica deve essere una comunicazione 
                        scritta inviata all’indirizzo dell’agente designato sopra elencato che include quanto segue:
                    </p>
                    <ol>
                        <li>
                            La tua firma fisica o elettronica;
                        </li>

                        <li>
                            Identificazione del materiale che è stato rimosso o al quale l’accesso è stato disabilitato e la posizione 
                            in cui il materiale è apparso prima che fosse rimosso o l’accesso ad esso era disabilitato;
                        </li>

                        <li>
                            Una dichiarazione sotto pena di spergiuro secondo cui si ritiene in buona fede che il materiale sia stato 
                            rimosso o disabilitato a seguito di errore o errata identificazione del materiale da rimuovere o disabilitare; e
                        </li>

                        <li>
                            Il tuo nome, indirizzo e numero di telefono e una dichiarazione che acconsenti alla giurisdizione del 
                            Tribunale distrettuale federale per il distretto giudiziario in cui si trova l’indirizzo o, se il tuo indirizzo 
                            è al di fuori dell’Italia, per qualsiasi distretto giudiziario in cui è possibile trovare la società e accettare 
                            il servizio di elaborazione da parte della persona che ha fornito la notifica o un agente di tale persona.
                        </li>
                    </ol>

                    <p>
                        Se avessi un reclamo relativo a marchi, brevetti, proprietà intellettuale o qualsiasi altra questione diversa 
                        dal diritto d’autore, ti preghiamo di contattarci all’indirizzo fornito alla pagina contatti.
                    </p>

                    <p>
                        Fornisci informazioni dettagliate sulla natura del reclamo, inclusi, a titolo esemplificativo, il nome 
                        e le informazioni di contatto, l’oggetto del reclamo e qualsiasi informazione aggiuntiva che potrebbe 
                        essere necessaria per esaminare la questione e intraprendere azioni (o nessuna azione) in maniera 
                        appropriata.
                    </p>
                    <p>
                        Si informa che possiamo condividere i dettagli del reclamo con l’Utente proprietario dei Contenuti 
                        dell’utente oggetto del reclamo o con le autorità competenti (comprese le autorità di contrasto) se 
                        Estro technologies s.r.l. ritiene a sua esclusiva discrezione che tale azione sia necessaria o se 
                        siamo obbligati a farlo per legge.
                    </p>
                    <br/>
                    
                    <h2>8. Modifica e cessazione dei servizi</h2>
                    <h3>8.1 In qualsiasi momento</h3>
                    <p>
                        Estro technologies s.r.l. si riserva il diritto di modificare o interrompere l’offerta dei Servizi 
                        in tutto o in parte in qualsiasi momento, a propria discrezione, nel qual caso ti forniremo un (1) 
                        mese di preavviso.
                    </p>
                    <h3>8.2 Per causa.</h3>
                    <p>
                        Potremmo chiudere il tuo account utente o il diritto di accedere a tutti o parti dei Servizi in 
                        qualsiasi momento, senza preavviso, per comportamenti che riteniamo violino il presente Accordo 
                        e / o siano dannosi per altri utenti, per Estro technologies s.r.l., per altri servizi o fornitori 
                        di informazioni, o a terzi.
                    </p>
                    <h3>8.3 Sopravvivenza.</h3>
                    <p>
                        Dopo qualsiasi risoluzione del presente Accordo e / o dopo aver smesso in altro modo di utilizzare 
                        i Servizi, i seguenti rimarranno e rimarranno esecutivi e in piena forza ed effetto:
                    </p>
                    <ul>
                        <li>
                            tutti gli obblighi in sospeso tra te e noi;
                        </li>
                        <li>
                            tutti i rimedi per violazione del presente Accordo; e
                        </li>
                        <li>
                            le seguenti sezioni del presente Accordo: 2 (a), 2 (b) e 2 (d) (Proprietà e contenuti); 3 (Contenuto dell’utente); 
                            4 (Altri siti Web e servizi); 5 (Esonero di responsabilità, limitazioni di responsabilità, indennità); 6 
                            (diritti di proprietà intellettuale); 7 (Legge applicabile e foro competente); 8 (Risoluzione delle controversie);
                             9 (cessazione del servizio); e 10 (Varie).
                        </li>
                    </ul>
                    <br/>
                    
                    <h2>9. Altri siti Web e servizi</h2>
                    <p>
                        I Servizi possono contenere collegamenti e funzionalità che consentono di accedere ad altri siti Web o 
                        servizi di terze parti (“Servizi di terze parti”) che non sono di nostra proprietà o controllati. Tali 
                        servizi di terze parti sono regolati dai rispettivi termini di utilizzo. Non controlliamo i 
                        servizi di terze parti e non siamo responsabili per i contenuti di qualsiasi sito collegato. 
                        Un collegamento non implica l’approvazione, la sponsorizzazione o l’affiliazione con i Servizi 
                        di terze parti da parte di Estro technologies s.r.l.. Si prega di prestare attenzione prima di 
                        procedere ai Servizi di terze parti o di stipulare transazioni con terze parti collegate ai Servizi. 
                        Estro technologies s.r.l. non può in alcun caso essere ritenuta responsabile per la disponibilità 
                        tecnica dei Servizi di terze parti, il contenuto, la pubblicità, i prodotti e / o i servizi 
                        disponibili sui Servizi di terze parti o qualsiasi transazione che abbia luogo tra un Utente e 
                        Servizi di terze parti che l’utente è stato diretto tramite i Servizi. Estro technologies s.r.l. 
                        non può in alcun caso essere parte di controversie tra l’utente e terze parti relative ai Servizi 
                        di terze parti.
                    </p>
                    <br />

                    <h2>10. Legge applicabile e giurisdizione; Arbitrato</h2>
                    <h3>10.1 Legge applicabile e giurisdizione.</h3>
                    <p>
                        I servizi sono gestiti principalmente da Estro technologies s.r.l., dai suoi uffici in Italia. 
                        È vietato l’accesso ai Servizi da giurisdizioni in cui tale accesso è illegale. Tutte le questioni 
                        derivanti o relative al presente Accordo e all’uso e al funzionamento dei Servizi saranno regolate 
                        dalle leggi sostanziali dello stato italiano, indipendentemente dai principi sui conflitti di leggi, 
                        tranne nella misura in cui le leggi sulla privacy di altre giurisdizioni regolano il trattamento dei 
                        dati personali come descritto nella nostra Informativa sulla privacy. Senza deroga all’obbligo di 
                        arbitrato stabilito nella Sezione 10.2 di seguito, l’utente accetta di sottoporsi alla giurisdizione 
                        italiana e alla competenza del Foro di Milano, qualora non diversamente disposto dalla legge. Se accedi 
                        ai Servizi da località al di fuori dall’Italia, compresa l’Unione Europea, sarai responsabile del 
                        rispetto di tutte le leggi locali di qualsiasi altra località e in nessun caso utilizzerai i Servizi o 
                        i Contenuti dell’utente in violazione di leggi o regolamenti sulle esportazioni in altre giurisdizioni.
                    </p>
                    
                    <h3>10.2 Arbitrato.</h3>
                    <p>
                        Tutte le controversie derivanti da o relative al presente Accordo o ai Servizi saranno risolte 
                        esclusivamente mediante arbitrato vincolante dinanzi a un singolo arbitro (l’ “Arbitro”) in conformità 
                        con le Regole di arbitrato. L’arbitrato deve essere condotto a Milano, Italia, a meno che l’Arbitro 
                        non stabilisca che tale sede non sia ragionevolmente conveniente per tutte le parti, nel qual caso il 
                        L’arbitro deve stabilire un’altra sede. Nel caso in cui l’Arbitro non sia disponibile o non disposto 
                        a gestire l’arbitrato e le parti non siano in grado di concordare un sostituto, il tribunale nomina un 
                        sostituto. L’Arbitro avrà l’autorità di emettere tutti i rimedi autorizzati dalla legge. Qualsiasi 
                        riconoscimento reso dall’Arbitro sarà definitivo, conclusivo e vincolante per le parti. In relazione a 
                        qualsiasi procedimento arbitrale ai sensi di questi termini e condizioni, a meno che l’Arbitro non 
                        stabilisca diversamente, ciascuna parte dovrà sostenere i propri costi e spese. Fermo restando quanto 
                        precede, un Utente può, a sua discrezione, presentare un reclamo individuale in qualsiasi tribunale per 
                        controversie di modesta entità per controversie o reclami nell’ambito della propria giurisdizione in materia 
                        se tale tribunale ha giurisdizione personale. Estro technologies s.r.l. non rinuncia a difendere che tale 
                        giurisdizione potrebbe mancare nella giurisdizione dell’utente.
                    </p>
                    <br/>

                    <h2>11. Modifica</h2>
                    <p>
                        Estro technologies s.r.l. si riserva il diritto di modificare il Contratto in qualsiasi momento 
                        pubblicando un avviso su questa pagina, inviando una notifica e-mail agli Utenti registrati o 
                        avvisando gli Utenti tramite i Servizi. Gli Utenti Registrati riceveranno una notifica di imminente 
                        modifica un (1) mese prima che l’Accordo modificato sia efficace. Qualsiasi Utente che utilizza i 
                        Servizi dopo che una modifica è diventata effettiva accetta il Contratto come modificato. Un Utente 
                        che non accetta l’Accordo modificato dovrà, prima che diventi efficace, cessare l’utilizzo dei Servizi.
                    </p>
                    <br />

                    <h2>12. Varie</h2>
                    <p>
                        Il presente Accordo, insieme all’Informativa sulla privacy, rappresenta l’intero accordo tra 
                        Estro technologies s.r.l. e l’Utente in relazione alla fornitura dei Servizi e sono escluse tutte 
                        le dichiarazioni e intese precedenti, scritte o orali. Nessun ritardo o omissione da parte di Estro 
                        technologies s.r.l. nell’esercizio di uno qualsiasi dei suoi diritti che si verifichino in caso di 
                        non conformità o inadempienza da parte di qualsiasi Utente rispetto a uno qualsiasi dei termini e 
                        delle condizioni del presente Accordo comprometterà tale diritto o sarà interpretato come una sua 
                        rinuncia e una rinuncia da parte di Estro technologies s.r.l. di qualsivoglia delle alleanze, 
                        condizioni o termini che devono essere eseguiti da un Utente non sarà interpretato come una rinuncia 
                        a qualsiasi violazione successiva della stessa o di qualsiasi altra alleanza, condizione o termini ivi 
                        contenuti. Come utilizzato nel presente Accordo, “incluso” significa “incluso ma non limitato a”. 
                        Se una disposizione del presente Accordo viene giudicata non valida o inapplicabile da un tribunale 
                        della giurisdizione competente, l’Accordo rimarrà in vigore a tutti gli effetti e sarà riformato 
                        per essere valido e applicabile nel rispetto delle intenzioni delle parti nella massima misura 
                        consentito dalla legge. Non è consentito cedere o trasferire il presente Accordo o qualsiasi diritto 
                        o obbligo di seguito a terzi. Estro technologies s.r.l. può cedere liberamente questo Accordo, 
                        incluso l’acquisto di Estro technologies s.r.l., e / o le sue operazioni commerciali. Accetti 
                        che il testo elettronico del presente Accordo costituisca una scrittura e il tuo consenso ai termini 
                        e alle condizioni del presente documento costituisce una “firma” a tutti gli effetti.
                    </p>
                    <p>
                        Acconsenti a ricevere comunicazioni da noi elettronicamente e accetti che possiamo comunicare con te 
                        tramite e-mail o pubblicando avvisi sui Servizi. Accetti che tutti gli accordi, le comunicazioni, 
                        le divulgazioni e le altre comunicazioni che ti forniamo o che ci fornisci, soddisfano elettronicamente 
                        qualsiasi requisito legale che tali comunicazioni siano scritte. Non siamo responsabili per eventuali 
                        filtri automatici che possono essere applicati a qualsiasi avviso e-mail che inviamo all’indirizzo 
                        e-mail fornito.
                    </p>
                    <p>
                        I servizi sono gestiti da Estro technologies s.r.l., una società italiana. Richieste di carattere 
                        generale possono essere indirizzate all’indirizzo email presente alla pagina contatti.
                    </p>
                    <br/>

                    <p>
                        Posta:<br />
                        Estro technologies s.r.l.<br/>
                        Via Savona 123<br/>
                        20144 Milano (MI)<br/>
                        Italia
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    
                </IonGrid>
            </IonContent>
        </IonPage>
        
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions);