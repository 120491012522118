import { isPlatform } from "@ionic/core";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import { auth, database, functions } from "firebase";
import { onValue, ref } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import React from "react";
import { connect } from "react-redux";
import { Product, productsArray, showPurchaseOnAndroidButton, showPurchaseOnIosButton, showPurchaseOnWebButton } from "../productsUtils";
import "./ManageProducts.css";

type Props = {
    history: any,
    onLoadingStarted?: (message: string) => void,
    onLoadingEnded?: () => void,
}

type State = {
    userProducts: Product[] | null,
}

class ManageProducts extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            userProducts: null,
        }
    }

    componentDidMount(): void {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                //console.log("[ManageProducts] Uuid:", userData.uid);
                const userProductsDbRef = ref(database, `u/${userData.uid}/p`)
                onValue(userProductsDbRef, async (data) => {
                    this.setState({ userProducts: productsArray(data.val()) });
                });
            }
        });
    }

    openStripeCustomerPortal() {
        if (this.props.onLoadingStarted) {
            this.props.onLoadingStarted("Sarai reindirizzato/a alla pagina di gestione abbonamento"); /* TO BE LOCALIZED */
        }
        /* const customerTestPortal = httpsCallable(functions, 'customerTestPortal-customerTestPortal');
        customerTestPortal({}) */
        const customerPortal = httpsCallable(functions, 'customerPortal-customerPortal');
        customerPortal({})
            .then((response) => {
                if (this.props.onLoadingEnded) {
                    this.props.onLoadingEnded();
                }
                //console.log("[PurchaseModal] response from customerTestPortal:", response.data);
                if ((response.data as any).portalSessionUrl) {
                    //console.log("[PurchaseModal] customer portal:", (response.data as any).portalSessionUrl);
                    window.location.replace((response.data as any).portalSessionUrl);
                }
                else {
                    if (this.props.onLoadingEnded) {
                        this.props.onLoadingEnded();
                    }
                    console.error("[PurchaseModal] error customer portal:", (response.data as any).error);
                }
            })
            .catch(err => {
                if (this.props.onLoadingEnded) {
                    this.props.onLoadingEnded();
                }
                console.error("[PurchaseModal] error calling customerTestPortal:", err);
            });
    }


    render() {
        return (
            <>
                {
                    this.state.userProducts &&
                    <IonCard className="manageProduct">
                        <IonCardHeader>
                            <IonCardTitle>Gestisci abbonamento</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent className='manageProductsCardContent'>

                            {
                                this.state.userProducts &&
                                this.state.userProducts.map((product, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="manageProductsProductDiv"
                                        >
                                            <p className="manageProductsProductName">
                                                {product.name}
                                            </p>
                                            <p>
                                                {product.expiryDate > new Date() ? `Scade il:` : `Scaduto il:`} {product.expiryDate.toLocaleDateString()}
                                            </p>
                                            {
                                                product.from === "web" &&
                                                (!isPlatform("capacitor") && !isPlatform("cordova")) &&
                                                <IonButton
                                                    className="manageProductsManageProductButton"
                                                    fill="clear"
                                                    onClick={() => {
                                                        this.openStripeCustomerPortal();
                                                    }}
                                                >
                                                    Gestisci abbonamento
                                                </IonButton>
                                            }

                                            {
                                                product.from === "web" &&
                                                (isPlatform("capacitor") || isPlatform("cordova")) &&
                                                <p className="manageProductsFromMobileOnWebParagraph">
                                                    Per gestire l'abbonamento, vai alla versione di Maestro per web.
                                                </p>
                                            }

                                            {
                                                product.from === "ios" &&
                                                <p
                                                    className="manageProductsFromMobileOnWebParagraph"
                                                    onClick={() => {
                                                        window.open("https://apps.apple.com/account/subscriptions", "_blank");
                                                    }}
                                                >
                                                    Per gestire l'abbonamento, clicca qui.
                                                </p>
                                            }

                                            {
                                                product.from === "android" &&
                                                <p
                                                    className="manageProductsFromMobileOnWebParagraph"
                                                    onClick={() => {
                                                        window.open(`https://play.google.com/store/account/subscriptions?package=ai.estro.maestro&sku=${product.productId}`, "_blank");
                                                    }}
                                                >
                                                    Per gestire l'abbonamento, clicca qui.
                                                </p>
                                            }
                                        </div>
                                    )
                                })
                            }

                            {/* TO DO: OPTIMIZE THIS COMPONENT */}

                            {
                                this.state.userProducts &&
                                showPurchaseOnWebButton(this.state.userProducts) &&
                                <IonButton
                                    className="manageProductsPurchaseMaestroOnWebButton"
                                    onClick={() => {
                                        this.props.history.push(`/purchase`)
                                    }}
                                >
                                    Acquista Maestro
                                </IonButton>
                            }

                            {
                                this.state.userProducts &&
                                showPurchaseOnIosButton(this.state.userProducts) &&
                                <IonButton
                                    className="manageProductsPurchaseMaestroOnWebButton"
                                    onClick={() => {
                                        this.props.history.push(`/purchase`);
                                    }}
                                >
                                    Acquista Maestro
                                </IonButton>
                            }

                            {
                                this.state.userProducts &&
                                showPurchaseOnAndroidButton(this.state.userProducts) &&
                                <IonButton
                                    className="manageProductsPurchaseMaestroOnWebButton"
                                    onClick={() => {
                                        this.props.history.push(`/purchase`);
                                    }}
                                >
                                    Acquista Maestro
                                </IonButton>
                            }
                        </IonCardContent>
                    </IonCard>
                }

                {/* {
                    this.state.userProducts &&
                    this.state.userProducts.length < 1 &&
                    (isPlatform("capacitor") || isPlatform("cordova")) &&
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>Gestisci abbonamento</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent className='manageProductsCardContent'>
                            <p className="manageProductsFromMobileOnWebParagraph">
                                Per gestire l'abbonamento, vai alla versione di Maestro per web.
                            </p>
                        </IonCardContent>
                    </IonCard>
                } */}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageProducts);