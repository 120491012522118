import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonButtons, IonBackButton, IonButton
} from '@ionic/react';
import { fromQueryToObjOfParams } from 'common/queryUtils';
import { functions } from 'firebase';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { Player } from '@lottiefiles/react-lottie-player';
import successPayment from "../../../assets/animations/successGreenTick.json";
import loadingShapes from "../../../assets/animations/loadingShapes.json";
import "./PaymentCompleted.css";
import { isPlatform } from '@ionic/core';

type Props = {
    history: any,

    isPaymentSuccessful: boolean,
};

type State = {
    sessionId: string | null,
    paymentSuccess: boolean,
};

class PaymentCompleted extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            sessionId: null,
            paymentSuccess: false,
        }
    }

    componentDidMount(): void {
        if (this.props.history.location.search !== '') {
            let sessionId = fromQueryToObjOfParams(this.props.history.location.search as string)["session_id"];
            //console.log("[PaymentCompleted] session id:", sessionId);
            if (sessionId) {
                this.setState({ sessionId: sessionId });

                /* const retrieveTestSession = httpsCallable(functions, 'retrieveTestSession-retrieveTestSession'); // TEST ENVIRONMENT
                retrieveTestSession({
                    sessionId: sessionId,
                }) */
                const retrieveSession = httpsCallable(functions, 'retrieveSession-retrieveSession'); // TEST ENVIRONMENT
                retrieveSession({
                    sessionId: sessionId,
                })
                    .then((res) => {
                        //console.log('[PaymentCompleted retrieveSession] success:', res);
                        if ((res.data as any).ok) {
                            this.setState({ paymentSuccess: true });
                            window.history.pushState({}, 'Maestro', '/payment-completed');
                        }
                    })
                    .catch(err => {
                        //console.log('[PaymentCompleted retrieveSession] error', err);
                    })
            }
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/" />
                        </IonButtons>
                        <IonTitle>
                            {this.props.isPaymentSuccessful || this.state.paymentSuccess ? "Grazie" : ""}
                        </IonTitle> {/* TO BE LOCALIZED */}
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className="paymentCompletedMainGrid">
                        {
                            (this.props.isPaymentSuccessful ||
                                this.state.paymentSuccess) &&
                            <div className="paymentCompletedSuccessDiv">
                                <Player
                                    autoplay
                                    keepLastFrame
                                    src={successPayment}
                                />

                                <p className="paymentCompletedSuccessText">
                                    Pagamento completato!<br />
                                    Grazie per aver acquistato Maestro. {/* TO BE LOCALIZED */}
                                </p>

                                <div className="paymentCompletedSuccessButtonsDiv">
                                    <IonButton
                                        onClick={() => {
                                            this.props.history.replace(`/settings`);
                                        }}
                                    >
                                        Inizia subito {/* TO BE LOCALIZED */}
                                    </IonButton>

                                    <IonButton
                                        className="paymentCompletedSuccessDiscoverButton"
                                        fill='outline'
                                        onClick={() => {
                                            window.open("https://estro.notion.site/Cosa-puoi-fare-con-Maestro-premium-0815da2a1d854eff95f80e3d13a441d8", "_blank");
                                        }}
                                    >
                                        Scopri cosa puoi fare con Maestro Premium {/* TO BE LOCALIZED */}
                                    </IonButton>
                                </div>
                            </div>
                        }


                        {
                            !isPlatform("capacitor") && !isPlatform("cordova") &&
                            !this.state.paymentSuccess &&
                            <div className="paymentProcessingProcessingDiv">
                                <div>
                                    <Player
                                        autoplay
                                        loop
                                        src={loadingShapes}
                                    />
                                </div>
                                <p className="paymentProcessingProcessingParagraph">
                                    Stiamo processando il pagamento
                                </p>
                            </div>
                        }

                    </IonGrid>
                </IonContent>
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isPaymentSuccessful: state.purchases.paymentSuccess,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCompleted);