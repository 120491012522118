import React from "react";
import {
    IonCard, IonTextarea, IonPopover, IonButton, IonIcon,
} from "@ionic/react";
import { connect } from 'react-redux';
import { closeOutline } from "ionicons/icons";
import './PBReport.css';

type Props = {
    isOpen: boolean,
    pbUuid: string | null,

    onDidDismiss?: () => void,
    onPbReported: (notes?: string) => void,
}

type State = {
    notes: string | undefined,
}

class PBReport extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            notes: undefined,
        }
    }

    componentDidUpdate(prev: Props) {
        if ((prev.pbUuid !== this.props.pbUuid)) {
            this.setState({
                notes: undefined,
            })
        }
    }

    render() {
        return (
            <IonPopover
                isOpen={this.props.isOpen}
                onDidDismiss={() => {
                    if (this.props.onDidDismiss) {
                        this.props.onDidDismiss()
                    }
                }}
                className="pbReportPopover"
                mode="md"
            >
                <IonIcon onClick={() => this.props.onDidDismiss()} icon={closeOutline} size='large' style={{ right: '0', position: 'absolute', cursor: 'pointer' }} />


                <div className="pbReportMainDiv">
                    <p
                        className="pbReportPopoverTitle"
                    >
                        Stai per registrare un comportamento problema {/* TO LOCALIZE */}
                    </p>

                    <p
                        className="pbReportPopoverSubtitle"
                    >
                        Vuoi aggiungere delle note?
                    </p>

                    <IonCard>
                        <IonTextarea
                            placeholder='Aggiungi una descrizione o una nota (opzionale)'
                            value={this.state.notes}
                            onIonChange={(e) => {
                                this.setState({ notes: e.detail.value ? e.detail.value : undefined });
                            }}
                        >
                        </IonTextarea>
                    </IonCard>

                    <IonButton
                        onClick={() => {
                            if (this.props.pbUuid) {
                                // pbServices.pbInteraction(this.props.pbUuid, this.state.notes)
                                //     .then((response) => {
                                this.props.onPbReported(this.state.notes);
                                // })
                                // .catch(err => {
                                //     //console.log("[PBReport] Error reporting problem behavior:", err);
                                // })
                            }
                        }}
                    >
                        Registra
                    </IonButton>
                </div>
            </IonPopover>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PBReport);