import React from 'react';
import {
	IonBackButton, IonButtons, IonGrid,	IonHeader,
    IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import { connect } from 'react-redux';
import "./EmailSent.css";



type Props = {
	history: any,
}

type State = {
}

class EmailSent extends React.Component<Props, State> {
	/* constructor(props) {
		super(props);
		this.state = {
		}
	} */

	render() {
		return (
			<IonPage>
				<IonHeader>
                    <IonToolbar>
                        <IonButtons slot='start'>
                            <IonBackButton defaultHref="/authentication/login" />
                        </IonButtons>
                        <IonTitle>
                            Email inviata {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                {/* <IonContent> */}
                    <IonGrid className="emailSentMainGrid">
                        <div className="emailSentTextDiv">
                            <h2>
                                Email inviata
                            </h2>
                            <p>
                                Ti abbiamo appena inviato un link che ti consentirà di resettare la password.
                            </p>
                        </div>
                    </IonGrid>
				{/* </IonContent> */}
            </IonPage>
		);
	}

}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.userData ? true : false,
	}
}


const mapDispatchToProps = dispatch => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSent);