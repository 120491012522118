import React from "react";
import {
    IonCard, IonCardHeader, IonCardContent, IonContent, IonInput,
    IonLabel, IonGrid, IonButton, IonIcon, IonAlert,
    IonRow, IonPage, IonHeader, IonToolbar, IonTitle,
    IonCardTitle, IonCardSubtitle, IonToast,
    IonBackButton, IonButtons, IonLoading, IonItem, IonPopover, IonToggle,
} from "@ionic/react";
import { Child, ChildAccessUser } from "modules/children/store/reducers";
import { connect } from 'react-redux';
import { childrenActions } from "modules/children/store/actions";
import { chatbubbles, personAdd, trash } from "ionicons/icons";
import FamilyMenuTabBar from "./FamilyMenuTabBar";
import { childrenService } from "modules/children/store/services";
import { fromQueryToObjOfParams } from "common/queryUtils";
import { Invitation, UserProfile } from "../store/reducers";
import { auth, functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import TaskSkeleton from "modules/data-collection/components/TaskSkeleton";
import GetPremiumPopover from "modules/purchase/components/GetPremiumPopover";
import { pbServices } from "modules/problem-behaviour/store/services";
import { ProblemBehavior } from "modules/problem-behaviour/store/reducers";
import PBCreation from "modules/problem-behaviour/pages/PBCreation";
import './ChildDashboard.css';
import { store } from "common/store";
import Tutorial from "common/In-App Tutorial/Tutorial";
import { childDashboardSteps } from "common/tutorialSteps";

type Props = {
    history: any,

    childrenList: Child[],
    currentChild: Child | null,

    setCurrentChild: (child: Child | null, userUuid: string) => void,
    setChildren: (children: Child[]) => void,
    updateChildInList: (child: Child) => void,
}

type State = {
    showShareAlert: boolean,
    showAdvancedAnalysisAlert: boolean,
    showChildSwitch: boolean,
    showChildCreation: boolean,

    currentChild: Child | null,
    accessList: UserProfile[],
    invitations: Invitation[],
    showShareSuccessToast: boolean,

    userUuid: string | null,
    isLoadingChildData: boolean,
    isLoadingDeletion: boolean,
    isLoadingChildSharing: boolean,
    isLoadingUserAccessDeletion: boolean,
    isLoadingPbList: boolean,
    isLoadingPb: boolean,
    isDeleting: boolean,

    showPayPopover: boolean,
    showPbPopover: boolean,

    pbList: ProblemBehavior[],
    showPbCreationPopover: boolean,
}

class ChildDashboard extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            showShareAlert: false,
            showAdvancedAnalysisAlert: false,
            showChildSwitch: false,
            showChildCreation: false,

            currentChild: null,
            accessList: [],
            invitations: [],
            showShareSuccessToast: false,

            userUuid: null,
            isLoadingChildData: false,
            isLoadingDeletion: false,
            isLoadingChildSharing: false,
            isLoadingUserAccessDeletion: false,
            isLoadingPbList: false,
            isLoadingPb: false,
            isDeleting: false,

            showPayPopover: false,
            showPbPopover: false,
            showPbCreationPopover: false,

            pbList: [],
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ userUuid: userData.uid });
            }
            else {
                this.setState({ userUuid: null });
            }
        })
        if (!store.getState().auth.userData)
            this.props.history.replace('/authentication/get-started')

        const childUuid = fromQueryToObjOfParams(this.props.history.location.search as string)["child"];
        if (childUuid) {
            this.setState({ isLoadingChildData: true, isLoadingPbList: true, })
            childrenService.fetchChild(childUuid)
                .then((child) => {
                    //console.log("[ChildDashboard] fetch child:", child);
                    if (child) {
                        this.setState({ currentChild: child, isLoadingChildData: false });
                    }

                })
                .catch(err1 => {
                    //console.log("[ChildDashboard] error getching children:", err1)
                });

            childrenService.getChildAccessList(childUuid)
                .then(accessList => {
                    this.setState({ accessList: accessList, isLoadingChildData: false });
                })
                .catch(err => {
                    //console.log("[ChildDashboard] error getting child access list:", err);
                });


            childrenService.getChildInvitations(childUuid)
                .then(invitations => {
                    this.setState({ invitations: invitations, isLoadingChildData: false });
                })
                .catch(err => {
                    //console.log("[ChildDashboard] error getting child invitations:", err);
                });

            pbServices.fetchPbList(childUuid)
                .then(pbList => {
                    this.setState({ pbList: pbList, isLoadingPbList: false })
                })

        }
    }

    getCurrentChild(childUuid: string) {
        this.setState({ isLoadingChildData: true })
        childrenService.fetchChild(childUuid)
            .then((child) => {
                //console.log("[ChildDashboard] get current child:", child);
                if (child) {
                    this.setState({ currentChild: child, isLoadingChildData: false });
                }
                else {
                    this.setState({ currentChild: null });
                    // TO DO: TAPPULLONE MEGA
                    childrenService.fetchChild(childUuid)
                        .then((child) => {
                            //console.log("[ChildDashboard] get current child:", child);
                            if (child) {
                                this.setState({ currentChild: child, isLoadingChildData: false });
                            }
                            else {
                                this.setState({ currentChild: null });
                            }
                        })
                        .catch(err1 => {
                            //console.log("[ChildDashboard] error getting current child:", err1)
                        })
                }
            })
            .catch(err1 => {
                //console.log("[ChildDashboard] error getting current child:", err1)
            })
    }

    getAccessInvitationUuid(userUuid: string, childAccessList: ChildAccessUser[]) {
        const userAccessInvitations = childAccessList.filter(access => {
            return (access.user.uuid === userUuid) && (access.joined_at === null)
        });
        if (userAccessInvitations.length > 0) {
            return userAccessInvitations[0].uuid;
        }
        else {
            return null;
        }
    }

    getInvitationSenderEmail(childAccessList: ChildAccessUser[]) {
        const userAccessSender = childAccessList.filter(access => {
            return access.is_owner
        });
        if (userAccessSender.length > 0) {
            return userAccessSender[0].user.email;
        }
        else {
            return "";
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar >
                        <IonTitle>
                            Dashboard scheda {/* TO LOCALIZE THIS */}
                        </IonTitle>

                        {
                            this.state.currentChild &&
                            <IonButtons slot="end">
                                <IonButton
                                    onClick={() => {
                                        this.props.history.push(`/dashboard/chat?child=${this.state.currentChild.uuid}`)
                                    }}
                                >
                                    <IonIcon
                                        icon={chatbubbles}
                                        size="large"
                                    />
                                </IonButton>
                            </IonButtons>
                        }
                        
                        <Tutorial hidden={this.state.isLoadingChildData || this.state.isLoadingChildSharing || this.state.isLoadingPbList || this.state.isLoadingPb} steps={childDashboardSteps} />

                        {/* <ChildrenSwitchButton
                            slot="end"
                            onClick={() => {
                                this.setState({ showChildSwitch: true });
                            }}
                        /> */}

                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/children" />
                        </IonButtons>

                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className='childDashboardContentGrid'>

                        <IonCard className="dashboardInfo">
                            <IonCardHeader className='dashboardCardHeader'>
                                <IonCardTitle>Informazioni generali</IonCardTitle>
                                <IonCardSubtitle>Modifica le informazioni relative alla persona</IonCardSubtitle>
                            </IonCardHeader>

                            <IonCardContent className='childDashboardCardContent'>
                                {
                                    this.state.isLoadingChildData &&
                                    <TaskSkeleton skeletonNumber={2} buttonNumber={0} />
                                }

                                {
                                    !this.state.isLoadingChildData &&
                                    <div className="childDashboardCardContentDiv">
                                        <IonLabel
                                            className='dashboardFieldLabel'
                                            color='dark'
                                        >
                                            Nome  {/* TO BE LOCALIZED */}
                                        </IonLabel>
                                        <IonInput
                                            placeholder={'inserire il nome'}
                                            value={this.state.currentChild ? this.state.currentChild.firstName : ''}
                                            onIonChange={(e) => {
                                                if (!this.state.isDeleting) {
                                                    const child = {};
                                                    Object.assign(child, this.state.currentChild);
                                                    (child as Child).firstName = e.detail.value ? e.detail.value : "";
                                                    this.setState({ currentChild: child as Child });
                                                    childrenService.updateChildFirstName(this.state.currentChild.uuid, String((child as Child).firstName).trim())
                                                    .then((child) => {
                                                        this.props.updateChildInList(child);
                                                        if (this.props.currentChild && this.props.currentChild.uuid === child.uuid) {
                                                            this.props.setCurrentChild(child, this.state.userUuid);
                                                        }
                                                    })
                                                    .catch(err => {
                                                        console.error("[ChildDashboard] error updating child first name:", err);
                                                    });
                                                }
                                            }}
                                        />


                                        <IonLabel className='dashboardFieldLabel' color='dark'>
                                            Cognome {/* TO BE LOCALIZED */}
                                        </IonLabel>
                                        <IonInput
                                            placeholder={'inserire il cognome'}
                                            value={this.state.currentChild ? this.state.currentChild.lastName : ''}
                                            onIonChange={(e) => {
                                                if (!this.state.isDeleting) {
                                                    const child = {};
                                                    Object.assign(child, this.state.currentChild);
                                                    (child as Child).lastName = e.detail.value ? e.detail.value : "";
                                                    this.setState({ currentChild: child as Child });
                                                    childrenService.updateChildLastName(this.state.currentChild.uuid, String((child as Child).lastName).trim())
                                                        .then((child) => {
                                                            this.props.updateChildInList(child);
                                                            if (this.props.currentChild && this.props.currentChild.uuid === child.uuid) {
                                                                this.props.setCurrentChild(child, this.state.userUuid);
                                                            }
                                                        })
                                                        .catch(err => {
                                                            console.error("[ChildDashboard] error updating child last name:", err);
                                                        })
                                                }
                                            }}
                                        />
                                    </div>
                                }
                            </IonCardContent>
                        </IonCard>



                        <IonCard className='dashboardAccessListCard dashboardAccessList'>
                            <IonCardHeader className='dashboardCardHeader'>
                                <IonCardTitle>Lista degli accessi</IonCardTitle>
                                <IonCardSubtitle>Gestisci gli utenti che hanno accesso alla scheda</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent style={{ width: '100%' }}>
                                <IonGrid>
                                    <div className="childDashboardAccessListRowDiv">
                                        <div className="childDashboardAccessListEmailDiv">
                                            <b>Email</b>
                                        </div>

                                        <div className='childDashboardAccessListRoleDiv'>
                                            <b>Ruolo</b>
                                        </div>
                                    </div>

                                    {
                                        this.state.isLoadingChildData &&
                                        <TaskSkeleton skeletonNumber={2} buttonNumber={0} />
                                    }

                                    {
                                        this.state.accessList.map((accessUser) => {
                                            return (

                                                <div
                                                    key={accessUser.uuid}
                                                    className="childDashboardAccessListRowDiv"
                                                >
                                                    {
                                                        this.state.userUuid === this.props.currentChild.ownerUuid &&
                                                        (accessUser.uuid !== this.props.currentChild.ownerUuid) &&
                                                        <div className="childDashboardAccessListRemoveAccessDiv">
                                                            <IonIcon
                                                                icon={trash}
                                                                color="medium"
                                                                onClick={() => {
                                                                    this.setState({ isLoadingUserAccessDeletion: true });
                                                                    const removeUserFromChild = httpsCallable(functions, "removeUserFromChild-removeUserFromChild");
                                                                    removeUserFromChild({
                                                                        childUuid: this.state.currentChild.uuid,
                                                                        userToRemoveUuid: accessUser.uuid,
                                                                    })
                                                                        .then(res => {
                                                                            this.setState({ isLoadingUserAccessDeletion: false });
                                                                            //console.log("[ChildDashboard] remove user from child:", res.data);
                                                                            if ((res.data as any).accessListIds) {
                                                                                window.location.reload();
                                                                            }
                                                                        })
                                                                        .catch(err => {
                                                                            this.setState({ isLoadingUserAccessDeletion: false });
                                                                            console.error("[ChildDashboard] error calling removeUserFromChild cloud function:", err);
                                                                        })
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    <div className="childDashboardAccessListEmailDiv">
                                                        <p className='dashboardAccessItemEmailParagraph'>
                                                            {/* <br /> */}

                                                            <i>{accessUser.email}</i>
                                                        </p>
                                                    </div>
                                                    <div className="childDashboardAccessListRoleDiv">
                                                        <p className='dashboardAccessItemEmailParagraph'>
                                                            {/* <br /> */}
                                                            <i>{accessUser.role}</i>
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </IonGrid>
                            </IonCardContent>

                            <IonCardContent>
                                <IonButton
                                    className="childDashboardShareChildButton"
                                    fill='clear'
                                    size='small'
                                    onClick={() => {
                                        this.setState({ showShareAlert: true });
                                        window.analytics.track({
                                            "event": "Share popup",
                                            "properties": {
                                                "category": "Share",
                                                "label": this.state.userUuid,
                                            }
                                        })
                                    }}
                                >
                                    Condividi la scheda con una/un professionista {/* TO BE LOCALIZED */}
                                    <IonIcon
                                        className="childDashboardShareChildButtonIcon"
                                        size="large"
                                        icon={personAdd}
                                    />
                                </IonButton>
                            </IonCardContent>
                        </IonCard>

                        {
                            this.state.invitations.length > 0 &&
                            <IonCard>
                                <IonCardHeader className='dashboardCardHeader'>
                                    <IonCardTitle>Inviti</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent style={{ width: '100%' }}>

                                    <IonGrid>
                                        <div className="childDashboardInvitationsDiv">
                                            <div className="childDashboardInvitationsEmailDiv">
                                                <b>Email</b>
                                            </div>
                                            <div className="childDashboardInvitationsSentAtDiv">
                                                <b>Inviato il</b>
                                            </div>
                                            {
                                                this.state.currentChild &&
                                                this.state.currentChild.ownerUuid === this.state.userUuid &&
                                                <div className="childDashboardInvitationsBinDiv">
                                                    <IonButton
                                                        color="medium"
                                                        fill="clear"
                                                        shape="round"
                                                        className="childDashboardInvitationHeaderButton"
                                                    >
                                                        <IonIcon
                                                            icon={trash}
                                                        />
                                                    </IonButton>
                                                </div>
                                            }
                                        </div>

                                        {
                                            this.state.invitations.map((invite) => {
                                                const monthNames = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu",
                                                    "Lug", "Ago", "Sett", "Ott", "Nov", "Dic"
                                                ];
                                                const accessDate = new Date(invite.createdAt * 1000);
                                                const formattedDate: string = `${accessDate.getDate()} ${monthNames[accessDate.getMonth()]} ${accessDate.getFullYear()}`; // TO LOCALIZE FORMAT */
                                                return (
                                                    <>

                                                        <IonRow
                                                            key={invite.uuid}
                                                        >
                                                            <div className="childDashboardInvitationsEmailDiv">
                                                                <p>
                                                                    <i>{invite.userToInviteEmail}</i>
                                                                </p>
                                                            </div>

                                                            <div className="childDashboardInvitationsSentAtDiv">
                                                                <p>
                                                                    <i>{formattedDate}</i>
                                                                </p>
                                                            </div>
                                                            {
                                                                this.state.currentChild &&
                                                                this.state.currentChild.ownerUuid === this.state.userUuid &&
                                                                <div className="childDashboardInvitationsBinDiv">
                                                                    <IonButton
                                                                        color="medium"
                                                                        fill="clear"
                                                                        shape="round"
                                                                        onClick={() => {
                                                                            this.setState({ isLoadingDeletion: true });
                                                                            const childDeleteInvitation = httpsCallable(functions, "childDeleteInvitation-childDeleteInvitation");
                                                                            childDeleteInvitation({
                                                                                inviteUuid: invite.uuid,
                                                                                childUuid: invite.childUuid,
                                                                            })
                                                                                .then(data => {
                                                                                    this.setState({ isLoadingDeletion: false });
                                                                                    //console.log("[ChildDashboard] received data deleting invitation:", data.data);
                                                                                    if ((data.data as any).error) {
                                                                                        /* this.setState({ isLoading: false }); */
                                                                                        //console.log("[ChildDashboard] delete child invitation returned error:", (data.data as any).error);
                                                                                    }
                                                                                    else {
                                                                                        this.setState({ invitations: (data.data as any).invitations });
                                                                                    }
                                                                                })
                                                                                .catch(err => {
                                                                                    this.setState({ isLoadingDeletion: false });
                                                                                    //console.log("[ChildDashboard] error calling childDeleteInvitation cloud function:", err);
                                                                                });
                                                                        }}
                                                                    >
                                                                        <IonIcon
                                                                            icon={trash}
                                                                        />
                                                                    </IonButton>
                                                                </div>
                                                            }
                                                        </IonRow>
                                                    </>
                                                )
                                            })
                                        }
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        }

                        <IonCard className='dashboardAccessListCard pbList'>
                            <IonCardHeader className='dashboardCardHeader'>
                                <IonCardTitle>Comportamenti problema</IonCardTitle>
                                <IonCardSubtitle>Scegli quali C.P. attivare e quali archiviare</IonCardSubtitle>
                            </IonCardHeader>
                            {
                                this.state.isLoadingPbList &&
                                <TaskSkeleton skeletonNumber={1} buttonNumber={0} />
                            }
                            {!this.state.isLoadingPbList &&
                                <IonCardContent>
                                    Ci sono {this.state.pbList.length} Comportamenti Problema.
                                </IonCardContent>
                            }

                            <IonCardContent>
                                <IonButton
                                    className="childDashboardShareChildButton"
                                    fill='outline'
                                    size='small'
                                    onClick={() => {
                                        this.setState({ showPbPopover: true });
                                    }}
                                >
                                    Gestisci i comportamenti problema {/* TO BE LOCALIZED */}
                                </IonButton>
                            </IonCardContent>

                        </IonCard>

                        {
                            this.state.currentChild &&
                            this.state.currentChild.ownerUuid === this.state.userUuid &&
                            <IonButton
                                className="childDashboardDeleteChildButton"
                                color='danger'
                                fill='clear'
                                onClick={() => {
                                    this.setState({ isLoadingDeletion: true });

                                    childrenService.deleteChild(this.state.currentChild.uuid)
                                        .then(() => {
                                            this.setState({ isDeleting: true });

                                            const remainingChildren = this.props.childrenList.filter(child => { return child.uuid !== this.state.currentChild.uuid });
                                            if (remainingChildren.length > 0) {
                                                this.props.setCurrentChild(remainingChildren[0], this.state.userUuid);
                                            }
                                            else {
                                                this.props.setCurrentChild(null, this.state.userUuid);
                                            }

                                            this.setState({ isLoadingDeletion: false, isDeleting: false }, () => {
                                                this.props.history.replace("/children");
                                            });
                                        })
                                        .catch(err => {
                                            this.setState({
                                                isLoadingDeletion: false,
                                                isDeleting: false,
                                            });
                                            //console.log("[ChildDashboard] error deleting child:", err);
                                        });
                                }}
                            >
                                Cancella {/* TO BE LOCALIZED */}
                            </IonButton>
                        }

                    </IonGrid>

                </IonContent>

                <FamilyMenuTabBar />
                <IonPopover
                    mode="md"
                    className="pbListPopover"
                    backdropDismiss={true}
                    onDidDismiss={() => this.setState({ showPbPopover: false })}
                    isOpen={this.state.showPbPopover}
                >
                    <div className="pbListMainDiv">
                        <h2 className="pbListHeader">Gestisci i Comportamenti Problema</h2>
                        <p style={{ textAlign: 'center' }} ><i>Scegli quali mostrare durante la presa dati</i></p>
                        {this.state.pbList.map((pb, i) => {
                            return (
                                <IonRow className="pbListRow">
                                    <IonItem lines="none" className="pbListItem">{pb.name}</IonItem>
                                    <IonToggle
                                        id={`pbToggle-${i}`}
                                        className="pbListDeleteIcon"
                                        color="success"
                                        checked={this.state.pbList[i].active || this.state.pbList[i].active === null}
                                        onIonChange={(e) => {
                                            if (e.detail.checked === this.state.pbList[i].active)
                                                return
                                            this.setState({ isLoadingPb: true })
                                            let tempPbList = this.state.pbList;
                                            let isActive = e.detail.checked
                                            pbServices.changePbActive(pb.uuid, isActive)
                                                .then((response) => {
                                                    tempPbList[i].active = e.detail.checked;
                                                    this.setState({ pbList: tempPbList })
                                                    this.setState({ isLoadingPb: false })

                                                })
                                                .catch(err => {
                                                    //console.log("[PBCreation] error creating problem behavior:", err);
                                                })
                                        }} />
                                </IonRow>
                            )
                        })}
                    </div>
                </IonPopover>
                <IonLoading isOpen={this.state.isLoadingPb} message='Aggiorno i Comportamenti problema'></IonLoading>
                <PBCreation
                    isOpen={this.state.showPbCreationPopover}
                    onDidDismiss={() => this.setState({ showPbCreationPopover: false })}
                    onSubmit={() => this.setState({ isLoadingPb: true })}
                    onPbCreated={(newPbList) => {
                        this.setState({ showPbCreationPopover: false, isLoadingPb: false, pbList: newPbList, });
                    }}
                />
                {
                    this.state.currentChild &&
                    <IonAlert
                        isOpen={this.state.showShareAlert}
                        onDidDismiss={() => this.setState({ showShareAlert: false })}
                        header={`Condividi scheda`}
                        message={`Cliccando su "Condividi" accetti di condividere i dati della scheda di "${this.state.currentChild.firstName ? this.state.currentChild.firstName : ''} ${this.state.currentChild.lastName ? this.state.currentChild.lastName : ''}" e di inviare un invito alla email sotto indicata.`}
                        inputs={[
                            {
                                name: 'userEmail',
                                type: 'text',
                                placeholder: 'email utente'
                            },
                        ]}
                        buttons={[
                            {
                                role: 'cancel',
                                text: 'Indietro',
                                handler: () => {
                                    window.analytics.track({
                                        "event": "Share Back button",
                                        "properties": {
                                            "category": "Share",
                                            "label": this.state.userUuid,
                                        }
                                    })
                                }
                            },
                            {
                                text: 'Condividi',
                                handler: (data) => {
                                    //console.log('Email a cui condividere la scheda:', data.userEmail);
                                    if (data.userEmail) {
                                        this.setState({ isLoadingChildSharing: true });
                                        childrenService.shareChildByUserEmail(this.state.currentChild.uuid, (data.userEmail as string).trim().toLowerCase())
                                            .then((invitations) => {
                                                window.analytics.track({
                                                    "event": "Shared child",
                                                    "properties": {
                                                        "category": "Share",
                                                        "label": this.state.userUuid,
                                                    }
                                                })
                                                //console.log("[ChildDashboard] share success");
                                                this.setState({
                                                    isLoadingChildSharing: false,
                                                    invitations: invitations,
                                                    showShareSuccessToast: true
                                                });
                                            })
                                            .catch(err => {
                                                //console.log("[ChildDashboard] share error:", err);
                                                this.setState({ isLoadingChildSharing: false });
                                                if (err === 760) {
                                                    this.setState({ showPayPopover: true });
                                                }
                                            })
                                    }
                                }
                            },

                        ]}
                    />
                }

                <IonToast
                    isOpen={this.state.showShareSuccessToast}
                    onDidDismiss={() => this.setState({ showShareSuccessToast: false })}
                    message="Persona invitata con successo ✅" /* TO LOCALIZE THIS */
                    duration={3000}
                />

                {/* <ChildrenSwitch
                    isOpen={this.state.showChildSwitch}
                    onDidDismiss={() => {
                        this.setState({
                            showChildSwitch: false,
                        })
                    }}
                    onSelectedChild={(child) => {
                        this.getCurrentChild(child.uuid);
                    }}
                /> */}

                <GetPremiumPopover
                    history={this.props.history}
                    isOpen={this.state.showPayPopover}
                    onGetPremiumButtonPressed={() => {
                        this.setState({ showPayPopover: false }, () => {
                            this.props.history.push(`/purchase`);
                        });

                    }}
                    onDidDismiss={() => {
                        this.setState({ showPayPopover: false });
                    }}
                />

                <IonLoading
                    backdropDismiss={false}
                    isOpen={this.state.isLoadingDeletion}
                    onDidDismiss={() => {
                        this.setState({ isLoadingDeletion: false });
                    }}
                    message={"Attendere prego..."} /* TO LOCALIZE THIS */
                    duration={10000}
                />

                <IonLoading
                    backdropDismiss={false}
                    isOpen={this.state.isLoadingChildSharing}
                    onDidDismiss={() => {
                        this.setState({ isLoadingChildSharing: false });
                    }}
                    message={"Attendere prego..."} /* TO LOCALIZE THIS */
                    duration={10000}
                />

                <IonLoading
                    backdropDismiss={false}
                    isOpen={this.state.isLoadingUserAccessDeletion}
                    onDidDismiss={() => {
                        this.setState({ isLoadingUserAccessDeletion: false });
                    }}
                    message={"Attendere prego..."} /* TO LOCALIZE THIS */
                    duration={10000}
                />

            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        childrenList: state.children.list,
        currentChild: state.children.currentChild,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentChild: (child: Child | null, userUuid: string) => {
            dispatch(childrenActions.setCurrentChild(child, userUuid));
        },

        setChildren: (children: Child[]) => {
            dispatch(childrenActions.setChildren(children));
        },

        updateChildInList: (child: Child) => {
            dispatch(childrenActions.updateChildInList(child));
        },


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildDashboard);