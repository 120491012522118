import { autonomiesConstants } from './constants';

export interface AutonomiesStore {
  list: Task[],
  steps: Step[],
  currentAutonomy: Task | null,
  currentSession: Session | null,
}

export interface Step {
  uuid: string,
  index: number,
  title: string,
  imageGsUrl?: string,
  videoGsUrl?: string,
}

/* export interface Step {
  task: { uuid: string },
  uuid: string,
  index: number,
  title: string,
  video?: string,
  preview: string,
  autonomy: string,
} */

/* export interface Session {
  user: { uuid: string },
  task: { uuid: string },
  child: { uuid: string },
  uuid: string,
  start_at: string,
  end_at: string,
  notes: string,
} */

export interface Session {
  uuid: string,
  taskUuid: string,
  startedAt: number,
  endedAt: number,
  data: SessionData,
  notes: Notes,
}

export interface Notes {
  b: string,
  d: string,
  a: string,
}

export interface SessionData {
  value: number | string,
  stepUuid: string,
  problemBehaviorUuid?: string
}

/* export interface StepInteraction {
  step: { uuid: string, title: string },
  uuid: string,
  session: string,
  value: number,
  mode: number,
} */

/* export interface Autonomy {
  uuid: string,
  title: string,
  image: string | null,
  created_at: string,
  child: string,
  steps: Step[] | null,
  labels: AutonomyPictureLabel[],
  data_collection_mode: number,
  is_active: boolean,
} */

export interface Task {
  uuid: string,
  childUuid: string,
  createdAt: number,
  dataCollectionMode: number,
  editedAt: number,
  imageGsUrl: string | null,
  imageUrl?: string,
  title: string,
  steps: Step[] | null,
}

const initialState: AutonomiesStore = { list: [], steps: [], currentAutonomy: null, currentSession: null };

const tasks = (state = initialState, action) => {
  switch (action.type) {
    case autonomiesConstants.FETCH_AUTONOMIES_SETTINGS:
      return { ...state, settings: action.autonomiesSettings }

    case autonomiesConstants.UPDATE_AUTONOMIES_SETTINGS:
      return { ...state, settings: action.autonomiesSettings }

    case autonomiesConstants.FETCH_AUTONOMIES_LIST:
      return { ...state, list: action.autonomiesList }

    case autonomiesConstants.FETCH_STEPS_LIST:
      return { ...state, steps: action.stepsList }

    case autonomiesConstants.START_SESSION:
      return { ...state, currentSession: action.session }

    case autonomiesConstants.CREATE_AUTONOMY:
      let tempList = state.list;
      //console.log('tempList:', tempList)
      tempList.push(action.autonomy);
      return { ...state, list: tempList }

    case autonomiesConstants.DELETE_AUTONOMY:
      return { ...state, currentAutonomy: null }

    case autonomiesConstants.SELECT_AUTONOMY:
      if (action.autonomyUuid) {
        let temp = state;
        let autonomyIndex = temp.list.findIndex((autonomy => autonomy.uuid === action.autonomyUuid));
        //console.log('Autonomies reducer, select autonomy index:', autonomyIndex); // TO REMOVE
        return { ...state, currentAutonomy: state.list[autonomyIndex] }
      }
      else {
        return { ...state, currentAutonomy: null }
      }

    case autonomiesConstants.CREATE_STEP:
    case autonomiesConstants.UPDATE_AUTONOMY_COVER:
    case autonomiesConstants.UPDATE_CURRENT_AUTONOMY:
      return { ...state, currentAutonomy: action.autonomy }


    case autonomiesConstants.CLEAR_CURRENT_AUTONOMY:
      return { ...state, currentAutonomy: null }

    default:
      return state

  }
}

export default tasks;