import React from "react";
import {
    IonContent, IonCard, IonCardHeader,
    IonCardTitle, IonRow, IonGrid, IonCardContent,
    IonButton, IonToggle, IonPage, IonHeader, IonToolbar,
    IonTitle, IonInput, IonIcon, IonLoading,
} from "@ionic/react";
import { connect } from 'react-redux';
import { authActions } from "modules/authentication/store/actions";
import { Device, UserProfile } from "../store/reducers";
import { userService } from "../store/services";
import FamilyMenuTabBar from "./FamilyMenuTabBar";
import { logoAndroid, logoApple } from "ionicons/icons";
import { Device as DeviceInfo } from '@ionic-native/device';
import { isPlatform } from "@ionic/core";
import { auth } from "../../../firebase";
import ManageProducts from "../components/ManageProducts";
import { appVersion } from "appVersion";
import './UserSettings.css';
import Tutorial from "common/In-App Tutorial/Tutorial";
import { settingsSteps } from "common/tutorialSteps";

type Props = {
    history: any,

    logout: () => void,
}
type State = {
    userProfile: UserProfile | null,
    userDevices: Device[],

    userUuid: string | null,
    userEmail: string | null,
    isLoading: boolean,
    loadingMessage: string,
}

class UserSettings extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            userProfile: null,
            userDevices: [],

            userUuid: null,
            userEmail: null,
            isLoading: false,
            loadingMessage: '',
        }
    }

    slideOpts = {
        slidesPerView: 3,
        freeMode: true,
        coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        }
    }

    componentDidMount() {

        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({
                    userUuid: userData.uid,
                    userEmail: userData.email,
                });

                userService.fetchDevices(userData.uid)
                    .then(devices => {
                        //console.log("[UserSettings] get devices response:", devices)
                        this.setState({ userDevices: devices });
                    })
                    .catch(err => {
                        console.error("[UserSettings] get devices error:", err)
                    });

                userService.fetchUserProfile(userData.uid)
                    .then(userProfile => {
                        //console.log('[UserSettings] fetch user profile:', userProfile);
                        this.setState({ userProfile: userProfile });
                    })
                    .catch(err => {
                        //console.log("[UserSettings] fetch user data error:", err);
                    });

            }
            else {
                this.setState({
                    userUuid: null,
                    userEmail: null,
                });
            }
        });
    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonTitle>Impostazioni</IonTitle> {/* TO LOCALIZE THIS */}
                        <Tutorial hidden={this.state.isLoading} steps={settingsSteps} />
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonGrid className='moreGrid'>
                        {
                            this.state.userProfile &&
                            <IonCard className="profileInfo">
                                <IonCardHeader>
                                    <IonCardTitle>Profilo</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonInput
                                                className="userSettingsProfileInput"
                                                placeholder="Nome" /* TO LOCALIZE THIS */
                                                autocomplete="given-name"
                                                value={this.state.userProfile.firstName}
                                                onIonChange={e => {
                                                    const newFirstName = e.detail.value ? String(e.detail.value).trim() : "";
                                                    userService.updateUserFirstName(newFirstName)
                                                        .then(newUserData => {
                                                            this.setState({
                                                                userProfile: newUserData,
                                                            });
                                                        })
                                                        .catch(err => {
                                                            //console.log("[UserSettings] update user first name error:", err);
                                                        })
                                                }}
                                                debounce={1000}
                                            />
                                        </IonRow>
                                        <IonRow>
                                            <IonInput
                                                className="userSettingsProfileInput"
                                                placeholder="Cognome" /* TO LOCALIZE THIS */
                                                autocomplete="family-name"
                                                value={this.state.userProfile.lastName}
                                                onIonChange={e => {
                                                    const newLastName = e.detail.value ? String(e.detail.value).trim() : "";
                                                    userService.updateUserLastName(newLastName)
                                                        .then(newUserData => {
                                                            this.setState({
                                                                userProfile: newUserData,
                                                            });
                                                        })
                                                        .catch(err => {
                                                            //console.log("[UserSettings] update user last name error:", err);
                                                        })
                                                }}
                                                debounce={1000}
                                            />
                                        </IonRow>
                                        <IonRow>
                                            <IonInput
                                                className="userSettingsProfileInput"
                                                placeholder="Ruolo" /* TO LOCALIZE THIS */
                                                autocomplete="organization-title"
                                                value={this.state.userProfile.role}
                                                onIonChange={e => {
                                                    const newRole = e.detail.value ? String(e.detail.value).trim() : "";
                                                    userService.updateUserRole(newRole)
                                                        .then(newUserData => {
                                                            window.analytics.track({
                                                                "event": "Change Role",
                                                                "properties": {
                                                                    "category": "Change Role",
                                                                    "label": this.state.userUuid + ": " + newUserData.role,
                                                                }
                                                            })
                                                            this.setState({
                                                                userProfile: newUserData,
                                                            });
                                                        })
                                                        .catch(err => {
                                                            //console.log("[UserSettings] update user role error:", err);
                                                        })
                                                }}
                                                debounce={1000}
                                            />
                                        </IonRow>

                                        <IonRow
                                            onClick={() => {
                                                this.props.history.push("/settings/update-email");
                                            }}
                                        >
                                            <p className="userSettingsProfileEmailParagraph">
                                                {this.state.userEmail}
                                            </p>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        }

                        <ManageProducts
                            history={this.props.history}
                            onLoadingStarted={m => {
                                this.setState({ isLoading: true, loadingMessage: m });
                            }}
                            onLoadingEnded={() => {
                                this.setState({ isLoading: false, loadingMessage: '' })
                            }}
                        />

                        {
                            this.state.userDevices.length > 0 &&
                            <IonCard className="manageDevices">
                                <IonCardHeader>
                                    <IonCardTitle>Dispositivi registrati</IonCardTitle>
                                </IonCardHeader>

                                <IonCardContent>
                                    {
                                        this.state.userDevices.map(device => {
                                            return (
                                                <div
                                                    key={device.uuid}
                                                    className="userSettingsDeviceDiv"
                                                >
                                                    <IonInput
                                                        value={device.name}
                                                        placeholder={"Nome del dispositivo"} /* TO LOCALIZE THIS */
                                                        onIonChange={e => {
                                                            userService.editDeviceName(device.uuid, e.detail.value ? e.detail.value : "")
                                                                .then(() => {
                                                                    userService.fetchDevices(this.state.userUuid)
                                                                        .then(devices => {
                                                                            //console.log("[UserSettings] get devices response:", devices)
                                                                            this.setState({ userDevices: devices });
                                                                        })
                                                                        .catch(err => {
                                                                            //console.log("[UserSettings] get devices error:", err)
                                                                        })
                                                                })
                                                                .catch(err => {
                                                                    //console.log("[UserSetting] editing device status error:", err);
                                                                })
                                                        }}
                                                        debounce={1000}
                                                    />

                                                    {
                                                        device.type === 1 && // ios
                                                        <div className="userSettingsNotificationDeviceOsDiv">
                                                            <IonIcon
                                                                slot="end"
                                                                icon={logoApple}
                                                                className="userSettingsNotificationDeviceOsIcon"
                                                            />
                                                        </div>

                                                    }

                                                    {
                                                        device.type === 2 && // android
                                                        <div className="userSettingsNotificationDeviceOsDiv">
                                                            <IonIcon
                                                                slot="end"
                                                                icon={logoAndroid}
                                                                className="userSettingsNotificationDeviceOsIcon"
                                                            />
                                                        </div>
                                                    }


                                                    <div
                                                        className="userSettingsDeviceActiveToggleDiv"
                                                        slot="end"
                                                    >
                                                        <IonToggle
                                                            checked={device.isActive}
                                                            onIonChange={e => {
                                                                userService.editDeviceStatus(device.uuid, e.detail.checked)
                                                                    .then(() => {
                                                                        userService.fetchDevices(this.state.userUuid)
                                                                            .then(devices => {
                                                                                //console.log("[UserSettings] get devices response:", devices)
                                                                                this.setState({ userDevices: devices });
                                                                            })
                                                                            .catch(err => {
                                                                                //console.log("[UserSettings] get devices error:", err);
                                                                            })
                                                                    })
                                                                    .catch(err => {
                                                                        //console.log("[UserSetting] editing device status error:", err);
                                                                    })
                                                            }}
                                                        />
                                                        <p className="userSettingsDeviceActiveToggleLabel">
                                                            Notifiche push
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </IonCardContent>
                            </IonCard>
                        }

                        <IonCard className="feedback">
                            <IonCardHeader>
                                <IonCardTitle>Feedback e assistenza</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent className='moreFeedbackCardContent'>
                                <IonButton
                                    onClick={() => {
                                        window.open('https://wa.me/393661126799');
                                    }}
                                    className='moreFeedbackAndSupportButton'
                                >
                                    Voglio dare un feedback<br />o richiedere assistenza<br />al team Estro
                                </IonButton>
                            </IonCardContent>
                        </IonCard>

                        <IonGrid>
                            <IonRow
                                className='logoutButtonRow'
                            >
                                <IonButton
                                    color='medium'
                                    onClick={() => {
                                        if (isPlatform("capacitor") || isPlatform("cordova")) {
                                            userService.fetchDevices(this.state.userUuid)
                                                .then(async devices => {
                                                    const devicesToRemove = devices.filter(device => {
                                                        //console.log("Device id:", device.deviceId, "DeviceInfo.uuid", DeviceInfo.uuid);
                                                        return (device.deviceId === DeviceInfo.uuid)
                                                    });
                                                    // //console.log("Devices to remove:", devicesToRemove); // FOR DEBUGGING
                                                    if (devicesToRemove) {
                                                        for (let i = 0; i < devicesToRemove.length; i++) {
                                                            const deviceToRemove = devicesToRemove[i];
                                                            try {
                                                                await userService.deleteDevice(deviceToRemove.uuid);
                                                            }
                                                            catch (e) {
                                                                //console.log("[UserSettings] cannot remove device uuid:", deviceToRemove.uuid, "error:", e);
                                                            }
                                                        }
                                                    }
                                                    this.props.logout();
                                                    this.setState({ isLoading: true, loadingMessage: 'Sto uscendo da Maestro...' })

                                                    setTimeout(() => {
                                                        this.setState({ isLoading: false, loadingMessage: '' })
                                                        this.props.history.replace('/authentication/get-started');
                                                    }, 1000);
                                                })
                                                .catch(err => {
                                                    //console.log("Error getting user's devices:", err);
                                                    this.props.logout();
                                                    this.setState({ isLoading: true, loadingMessage: 'Sto uscendo da Maestro...' })

                                                    setTimeout(() => {
                                                        this.setState({ isLoading: false, loadingMessage: '' })
                                                        this.props.history.replace('/authentication/get-started');
                                                    }, 1000);
                                                });
                                        }
                                        else {
                                            this.props.logout();
                                            this.setState({ isLoading: true, loadingMessage: 'Sto uscendo da Maestro...' })
                                            setTimeout(() => {
                                                this.setState({ isLoading: false, loadingMessage: '' })
                                                this.props.history.replace('/authentication/get-started');
                                            }, 1000);
                                        }
                                    }}
                                    fill='outline'
                                    className='userSettingsLogoutButton'
                                >
                                    Logout
                                </IonButton>
                            </IonRow>

                            <IonRow class='licensesButtonRow'>
                                <IonButton
                                    color='medium'
                                    onClick={() => {
                                        window.open('https://estro.ai/legal/licenses', '_system');
                                    }}
                                    fill='clear'
                                    class='userSettingsLogoutButton'
                                >
                                    Licenze
                                </IonButton>
                            </IonRow>
                            <p className="userSettingsAppVersionNumberParagraph">
                                Versione {appVersion} {/* TO BE LOCALIZED */}
                            </p>
                        </IonGrid>
                    </IonGrid>
                </IonContent>
                
                <FamilyMenuTabBar />

                <IonLoading
                    isOpen={this.state.isLoading}
                    message={this.state.loadingMessage} /* TO BE LOCALIZED */
                />
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(authActions.logout())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);