import { IonLoading } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';

type LoadingProps = {
    showSpinner: boolean
}

type State = {
}
  
class LoadingSpinner extends React.Component<LoadingProps, State> {

    waitMessages: string[] = [
        'Ci posso mettere un po\'...', 
        'Attendi',
        'Sto comunicando con Maestro'
    ];

    render() {
        return (
            <IonLoading
                /* isOpen={true} */
                isOpen={this.props.showSpinner}
                /* onDidDismiss={() => setShowLoading(false)} */
                /* message={isPlatform('mobile') ? 'Attendi, può richiedere fino a qualche minuto' : this.waitMessages[(Math.random() * 3).toFixed(0)]} */
                message={this.waitMessages[(Math.random() * 3).toFixed(0)]}
                /* duration={5000} */
            />
        );
    }
}

const mapStateToProps = state => {
    return {
       showSpinner: (state.app.loading.length > 0),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        /* 
            E' il componente stesso a dispatchare dopo un timeout il nascondi del loading.
        */
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingSpinner);