import { Device } from '@ionic-native/device';
import { isPlatform } from '@ionic/react';
import { database } from '../../../firebase';
import { get, push, ref as dbRef, remove, update } from 'firebase/database';
import { Device as DeviceModel, UserProfile } from './reducers';
import { store } from 'common/store';

export const userService = {
    fetchDevices,
    addDevice,
    editDeviceStatus,
    editDeviceName,
    deleteDevice,

    fetchUserProfile,
    updateUserFirstName,
    updateUserLastName,
    updateUserRole,
};

function fetchDevices(userUuid: string) {
    return new Promise<DeviceModel[]>((resolve, reject) => {
        const devicesArrayToReturn: DeviceModel[] = [];
        get(dbRef(database, `d/${userUuid}`))
        .then(snapshot => {
            if (snapshot.exists()) {
                const devicesKeys = Object.keys(snapshot.val());
                for (let i = 0; i < devicesKeys.length; i++) {
                    devicesArrayToReturn.push({
                        uuid: devicesKeys[i],
                        creationDate: snapshot.val()[devicesKeys[i]].c,
                        deviceId: snapshot.val()[devicesKeys[i]].d,
                        isActive: snapshot.val()[devicesKeys[i]].a,
                        name: snapshot.val()[devicesKeys[i]].n,
                        registrationToken: snapshot.val()[devicesKeys[i]].r,
                        type: snapshot.val()[devicesKeys[i]].t,
                    });
                }
            }
            resolve(devicesArrayToReturn);
        })
        .catch(err => {
            console.error('[fetchDevices] error:', err);
            resolve([]);
        });
    })
}

function addDevice(fcmToken: string) {
    return new Promise<DeviceModel>((resolve, reject) => {

        const deviceModelToReturn: DeviceModel = {
            uuid: "temp",
            creationDate: Math.floor(new Date().getTime() / 1000),
            isActive: true,
            name: Device.model,
            registrationToken: fcmToken,
            type: 0,
        };

        if (isPlatform('ios')) {
            deviceModelToReturn.type = 1;
            deviceModelToReturn.deviceId = Device.uuid;
        }
        else if (isPlatform('android')) {
            deviceModelToReturn.type = 2;
            deviceModelToReturn.deviceId = Device.uuid;
        }

        const deviceDbObj = {
            a: true,
            c: deviceModelToReturn.creationDate,
            n: deviceModelToReturn.name,
            r: fcmToken,
            t: deviceModelToReturn.type,
        };

        if (deviceModelToReturn.deviceId) {
            Object.assign(deviceDbObj, { d: deviceModelToReturn.deviceId });
        }

        push(dbRef(database, `d/${store.getState().auth.userData.uid}`), deviceDbObj)
        .then(response => {
            //console.log('[addDevice] response', JSON.stringify(response.data));
            deviceModelToReturn.uuid = response.key;

            resolve(deviceModelToReturn);
        })
        .catch(err => {
            console.error('[addDevice] error:', JSON.stringify(err));
            reject(err);
        })
    });
}

function editDeviceStatus(deviceUuid: string, active: boolean) {
    return new Promise<DeviceModel | null>((resolve, reject) => {
        const deviceDbRef = dbRef(database, `d/${store.getState().auth.userData.uid}/${deviceUuid}`);
        get(deviceDbRef)
        .then(snapshot => {
            if (snapshot.exists()) {
                const deviceToReturn: DeviceModel = {
                    uuid: deviceUuid,
                    creationDate: snapshot.val().c,
                    deviceId: snapshot.val().d,
                    isActive: snapshot.val().a,
                    name: snapshot.val().n,
                    registrationToken: snapshot.val().r,
                    type: snapshot.val().t,
                };

                update(deviceDbRef, {
                    a: active,
                })
                    .then(() => {
                        deviceToReturn.isActive = active;
                        resolve(deviceToReturn);
                    })
                    .catch(err => {
                        //console.log('[editDeviceStatus] error updating device status:', err);
                        resolve(deviceToReturn);
                    })
            }
            else {
                resolve(null);
            }
        })
        .catch(err => {
            console.error('[editDeviceStatus] error getting device from db:', err);
            resolve(null);
        });
    })
}

function editDeviceName(deviceUuid: string, name: string) {
    return new Promise<DeviceModel | null>((resolve, reject) => {
        const deviceDbRef = dbRef(database, `d/${store.getState().auth.userData.uid}/${deviceUuid}`);
        get(deviceDbRef)
        .then(snapshot => {
            if (snapshot.exists()) {
                const deviceToReturn: DeviceModel = {
                    uuid: deviceUuid,
                    creationDate: snapshot.val().c,
                    deviceId: snapshot.val().d,
                    isActive: snapshot.val().a,
                    name: snapshot.val().n,
                    registrationToken: snapshot.val().r,
                    type: snapshot.val().t,
                };

                update(deviceDbRef, {
                    n: name,
                })
                .then(() => {
                    deviceToReturn.name = name;
                    resolve(deviceToReturn);
                })
                .catch(err => {
                    console.error("[editDeviceName] error updating device on db")
                    resolve(deviceToReturn);
                })
            }
            else {
                resolve(null);
            }
        })
        .catch(err => {
            console.error('[editDeviceName] error getting device from db:', err);
            resolve(null);
        });
    })
}

function deleteDevice(deviceUuid: string) {
    return new Promise((resolve, reject) => {
        const deviceDbRef = dbRef(database, `d/${store.getState().auth.userData.uid}/${deviceUuid}`);
        remove(deviceDbRef)
            .then(() => {
                resolve(true);
            })
            .catch(err => {
                console.error('[deleteDevice] error deleting device from db:', err);
                reject(err);
            });
    })
}

function fetchUserProfile(userUuid: string) {
    return new Promise<UserProfile>((resolve, reject) => {
        get(dbRef(database, `p/${userUuid}`))
            .then(snapshot => {
                const userToReturn: UserProfile = {};
                if (snapshot.exists()) {
                    userToReturn.uuid = store.getState().auth.userData.uid;
                    userToReturn.email = snapshot.val().e;
                    userToReturn.firstName = snapshot.val().f;
                    userToReturn.lastName = snapshot.val().l;
                    userToReturn.role = snapshot.val().r;
                }
                resolve(userToReturn);
            })
            .catch(err => {
                //console.log('[fetchUserData] error getting profile data:', err);
                reject(err);
            });
    })
}

function updateUserFirstName(firstName: string) {
    return new Promise<UserProfile>((resolve, reject) => {
        const userProfileDbRef = dbRef(database, `p/${store.getState().auth.userData.uid}`);
        update(userProfileDbRef, {
            f: firstName.trim(),
        })
            .then(() => {
                get(userProfileDbRef)
                    .then(snapshot => {
                        const userToReturn: UserProfile = {};
                        if (snapshot.exists()) {
                            userToReturn.firstName = snapshot.val().f;
                            userToReturn.lastName = snapshot.val().l;
                            userToReturn.role = snapshot.val().r;
                        }
                        resolve(userToReturn);
                    })
                    .catch(err => {
                        //console.log('[updateUserFirstName] error getting profile data:', err);
                        reject(err);
                    });
            })
            .catch(err => {
                //console.log("[updateUserFirstName] error updating user profile first name:", err);
                reject(err);
            });
    })
}

function updateUserLastName(lastName: string) {
    return new Promise<UserProfile>((resolve, reject) => {
        const userProfileDbRef = dbRef(database, `p/${store.getState().auth.userData.uid}`);
        update(userProfileDbRef, {
            l: lastName.trim(),
        })
            .then(() => {
                get(userProfileDbRef)
                    .then(snapshot => {
                        const userToReturn: UserProfile = {};
                        if (snapshot.exists()) {
                            userToReturn.firstName = snapshot.val().f;
                            userToReturn.lastName = snapshot.val().l;
                            userToReturn.role = snapshot.val().r;
                        }
                        resolve(userToReturn);
                    })
                    .catch(err => {
                        //console.log('[updateUserLastName] error getting profile data:', err);
                        reject(err);
                    });
            })
            .catch(err => {
                //console.log("[updateUserLastName] error updating user profile last name:", err);
                reject(err);
            });
    })
}

function updateUserRole(role: string) {
    return new Promise<UserProfile>((resolve, reject) => {
        const userProfileDbRef = dbRef(database, `p/${store.getState().auth.userData.uid}`);
        update(userProfileDbRef, {
            r: role.trim(),
        })
            .then(() => {
                get(userProfileDbRef)
                    .then(snapshot => {
                        const userToReturn: UserProfile = {};
                        if (snapshot.exists()) {
                            userToReturn.firstName = snapshot.val().f;
                            userToReturn.lastName = snapshot.val().l;
                            userToReturn.role = snapshot.val().r;
                        }
                        resolve(userToReturn);
                    })
                    .catch(err => {
                        //console.log('[updateUserRole] error getting profile data:', err);
                        reject(err);
                    });
            })
            .catch(err => {
                //console.log("[updateUserRole] error updating user profile role:", err);
                reject(err);
            });
    })
}