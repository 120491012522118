import { IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2';
import { isPlatform } from '@ionic/core';
import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonButtons, IonBackButton, IonButton, IonSlides, IonLoading, IonIcon, IonGrid,
} from '@ionic/react';
import { store } from 'common/storeHandler';
import { functions } from 'firebase';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import PurchaseExport from '../components/PurchaseExport';
import PurchaseModeling from '../components/PurchaseModeling';
import PurchaseSessions from '../components/PurchaseSessions';
import PurchaseShare from '../components/PurchaseShare';
import discountIcon from "../../../assets/icons/discountIcon.svg";
import { purchasesActions } from '../store/actions';
import { arrowForward, pricetag } from "ionicons/icons";
import './Purchase.css';

type Props = {
    history: any,

    purchaseError: () => void,
    paymentIsLoading: (isLoading: boolean) => void,
};

type State = {
    showModal: boolean,
    isLoadingStripe: boolean,

    couponId: string | undefined,

    maestroMonthlyIapProduct: IAPProduct | null,

    showCouponAppliedOnWeb: boolean,
    showHowToApplyDiscountCodeOnAndroid: boolean,
    showHowToApplyDiscountCodeOnIos: boolean,
};

declare class Stripe {
    constructor(...args: any[]);
    redirectToCheckout(...args: any[]): any;
}

/* const stripe = new Stripe("pk_test_51Jb6VuJasdj1ggLrhB8O7LCkfa6ivtnkjxXHKarMJgW3YtwiS5ROrOJOMx7yK69XIp4RlNaPzTxgXEFJwpyHeffO00tNIqqz9t"); // TESTING ENV */
const stripe = new Stripe("pk_live_51Jb6VuJasdj1ggLr8hyLJtNE1a8fw8WF8AWDK8bvzo333BwBacQ250xwVg06lJPmkbnA4BOU0iIpAgLhV6lAJhJo00wWLJlG6v"); // PRODUCTION ENV



class Purchase extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
            isLoadingStripe: false,

            couponId: undefined,

            maestroMonthlyIapProduct: null,

            showCouponAppliedOnWeb: false,
            showHowToApplyDiscountCodeOnAndroid: false,
            showHowToApplyDiscountCodeOnIos: false,
        }

    }

    componentDidMount(): void {
        try {
            const url = new URL(window.location.href);
            console.log("[Purchase] coupon id:", url.searchParams.get("coupon"));
            this.setState({ 
                couponId: url.searchParams.get("coupon") ? url.searchParams.get("coupon") : undefined,
                showCouponAppliedOnWeb: url.searchParams.get("coupon") && !isPlatform("capacitor") && !isPlatform("cordova") ? true : false,
            });

            if ((isPlatform("capacitor") || isPlatform("cordova")) && isPlatform("android") && url.searchParams.get("coupon")) {
                window.open("https://estro.notion.site/Usufruire-dell-offerta-a-9-99-da-Android-f483fc431b41439697289c5ec3a132fa", '_system');
                this.setState({ showHowToApplyDiscountCodeOnAndroid: true });
            }
            else if ((isPlatform("capacitor") || isPlatform("cordova")) && isPlatform("ios") && url.searchParams.get("coupon")) {
                this.setState({showHowToApplyDiscountCodeOnIos: true });
            }
        }
        catch (e) {
            console.error("[Purchase] error creating url:", e);
        }

        if (isPlatform("capacitor") || isPlatform("cordova")) {
            const maestroMonthlyIapProduct = store.get("ai.estro.maestro.aba.monthly");
            this.setState({ maestroMonthlyIapProduct: maestroMonthlyIapProduct });
        }

    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/" />
                        </IonButtons>

                        <IonTitle>Acquista</IonTitle> {/* TO BE LOCALIZED */}

                        {
                            (isPlatform("capacitor") || isPlatform("cordova")) &&
                            isPlatform("ios") &&
                            <IonButtons slot='end'>
                                <IonButton
                                    className='purchaseIosDiscountHeaderButton'
                                    fill='clear'
                                    shape='round'
                                    onClick={() => {
                                        store.redeem();
                                        this.props.paymentIsLoading(true);
                                        this.props.history.push("/processing-payment");
                                    }}
                                >
                                    <IonIcon
                                        icon={discountIcon}
                                    />
                                </IonButton>
                            </IonButtons>
                        }
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonGrid className="purchaseMainGrid">
                        <div className="purchaseMainDiv">

                            <div className="purchaseTitleDiv">
                                <div className="purchaseTitleInnerDiv">
                                    <p className="purchaseTitleParagraph">
                                        Maestro premium
                                    </p>

                                    <div style={{display: "block"}}>
                                        {
                                            this.state.showCouponAppliedOnWeb &&
                                            <div className="purchaseDiscountCouponDiv">
                                                <IonIcon
                                                    icon={pricetag}
                                                />
                                                <p className='purchaseDiscountCouponName'>
                                                    Primo mese scontato a <b>€ 9,99</b>
                                                </p>
                                            </div>
                                        }
                                        {
                                            this.state.showCouponAppliedOnWeb &&
                                            <span>e poi&nbsp;</span>
                                        }
                                        <b>
                                            {this.state.maestroMonthlyIapProduct && this.state.maestroMonthlyIapProduct.price ? this.state.maestroMonthlyIapProduct.price : "€ 44,99"} al mese
                                        </b>
                                    </div>

                                    {
                                        this.state.showHowToApplyDiscountCodeOnAndroid &&
                                        <div
                                            className="purchaseDiscountCouponHintOnMobileDiv"
                                            onClick={() => {
                                                window.open("https://estro.notion.site/Usufruire-dell-offerta-a-9-99-da-Android-f483fc431b41439697289c5ec3a132fa", '_system');
                                            }}
                                        >
                                            <IonIcon
                                                icon={arrowForward}
                                            />
                                            <p className='purchaseDiscountCouponName'>
                                                Per applicare il codice sconto <b>fai tap qui</b>
                                            </p>
                                        </div>
                                    }

                                    {
                                        this.state.showHowToApplyDiscountCodeOnIos &&
                                        <div
                                            className="purchaseDiscountCouponHintOnMobileDiv"
                                            onClick={() => {
                                                window.open("https://estro.notion.site/Usufruire-dell-offerta-a-9-99-da-dispositivi-iOS-eaf4395d83a9433a8234d0e6e7313435", '_system');
                                            }}
                                        >
                                            <IonIcon
                                                icon={arrowForward}
                                            />
                                            <p className='purchaseDiscountCouponName'>
                                                Per applicare il codice sconto fai tap qui
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="purchaseBenefitsDiv">
                            <IonSlides
                                className='purchaseSlides'
                                options={{autoplay: { delay: 4000 }}}
                                pager={true}
                            >
                                <PurchaseModeling />

                                <PurchaseSessions />

                                <PurchaseExport />

                                <PurchaseShare />
                            </IonSlides>
                            </div>

                            <div className="purchaseButtonsAndTermsDiv">
                                <IonButton
                                    onClick={() => {
                                        if (isPlatform("capacitor") || isPlatform("cordova")) {
                                            store.order("ai.estro.maestro.aba.monthly")
                                                .then(e => {
                                                    console.log("[Purchase] purchase response:", e);
                                                    this.props.paymentIsLoading(true);
                                                    this.props.history.push("/processing-payment");
                                                })
                                                .error(e => {
                                                    console.error("[Purchase] purchase error:", e);
                                                    this.props.purchaseError();
                                                    this.props.history.push("/processing-payment");
                                                });
                                        }
                                        else {
                                            this.setState({ isLoadingStripe: true });
                                            /* const createTestCheckoutSession = httpsCallable(functions, 'createTestCheckoutSession-createTestCheckoutSession');
                                            createTestCheckoutSession({
                                                priceId: "price_1KNxeIJasdj1ggLrXha2V0XX",
                                                coupon: this.state.couponId,
                                            }) */
                                            const creatCheckoutSession = httpsCallable(functions, 'createCheckoutSession-createCheckoutSession');
                                            creatCheckoutSession({
                                                priceId: 'price_1KYD1TJasdj1ggLrX5C3SebB',
                                                coupon: this.state.couponId,
                                            })
                                                .then((response) => {
                                                    console.log("[PurchaseModal] response from createCheckoutSession:", response.data);
                                                    this.setState({ isLoadingStripe: false });
                                                    if ((response.data as any).sessionId) {
                                                        stripe.redirectToCheckout({
                                                            sessionId: (response.data as any).sessionId
                                                        })
                                                            .then((data: any) => {
                                                                console.log('[FeatureManagerPopovers] checkout success redirection', data);
                                                                /* this.setState({ isLoading: false }); */
                                                            })
                                                            .catch((err: any) => {
                                                                console.log('[FeatureManagerPopovers] Err redirecting to checkout', err);
                                                                /* this.setState({ isLoading: false }); */
                                                            });
                                                    }
                                                    else {
                                                        console.error("[PurchaseModal] no valid session id:", (response.data as any).error);
                                                    }
                                                })
                                                .catch(err => {
                                                    console.error("[PurchaseModal] error calling createCheckoutSession:", err);
                                                })
                                        }
                                    }}
                                    className='purchaseButton'
                                    size='large'
                                >
                                    Passa a Premium
                                </IonButton>

                                {
                                    (isPlatform("capacitor") || isPlatform("cordova")) &&
                                    <div
                                        className="purchaseRestorePurchasesDiv"
                                        onClick={() => {
                                            store.refresh();
                                        }}
                                    >
                                        Ripristina acquisti {/* TO BE LOCALIZED */}
                                    </div>
                                }


                                <div className="purchaseTermsDiv">
                                    <p>
                                        L'abbonamento viene rinnovato automaticamente alla fine del periodo di fatturazione.<br/>Per maggiori dettagli, leggere la nostra <a href="https://estro.notion.site/Payment-policy-b84701b95083445e99cfed4ad0a12f5f" target="_blank" rel="noreferrer">Payment Policy</a> e la <a href="https://ma.estro.ai/legal/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>.
                                    </p>
                                    {
                                        (isPlatform("capacitor") || isPlatform("cordova")) &&
                                        isPlatform("ios") &&
                                        <p>
                                            You can modify or cancel our subscription by going to the user's iTunes Account Settings after purchase.<br />
                                            Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication.<br />
                                            Payment will be charged to iTunes Account at confirmation of purchase.
                                        </p>
                                    }

                                    {
                                        (isPlatform("capacitor") || isPlatform("cordova")) &&
                                        isPlatform("android") &&
                                        <p>
                                            You can modify or cancel your subscription by going to the user's Play Service Account Settings after purchase.<br />
                                            Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication.<br />
                                            Payment will be charged to Play Service Account at confirmation of purchase.
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </IonGrid>
                </IonContent>

                <IonLoading
                    message={'Sarai reindirizzato/a alla pagina di pagamento'} /* TO LOCALIZE THIS */
                    isOpen={this.state.isLoadingStripe}
                />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        purchaseError: () => {
            dispatch(purchasesActions.error());
        },

        paymentIsLoading: (isLoading: boolean) => {
            dispatch(purchasesActions.loading(isLoading));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);