import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
//import logger from 'redux-logger'; // NOT FOR PRODUCTION

import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import Children from 'modules/children/store/reducers';
import tutorials from '../modules/tutorials/store/reducers';
import Tasks from 'modules/tasks/store/reducers';
import Application from 'modules/core/store/reducers';
import pb from 'modules/problem-behaviour/store/reducers';
import Authentication from 'modules/authentication/store/reducers';
import Purchases from 'modules/purchase/store/reducers';

/* const middlewares = [thunk, logger]; */
const middlewares = [thunk];

const rootReducer = combineReducers({
    auth: Authentication,
    app: Application,
    tutorials: tutorials,
    children: Children,
    autonomies: Tasks,
    pb: pb,
    purchases: Purchases,
});

const migrations = {
    0: (state) => {
        return {
            ...state,
            auth: {
                access: undefined,
                refresh: undefined,
                userData: null,
            },
            user: null,
            children: {
                list: [],
                currentChild: null,        
            }
        }
    },
    1: (state) => {
        return {
            ...state,
            purchases: {
                isLoadingPayment: false,
                isProcessingPayment: false,
                paymentSuccess: false,
                paymentCanceled: false,
                paymentError: false,
            }
        }
    }
}  

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ["app", "purchases"],
    version: 1,
    migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer, applyMiddleware(...middlewares));
let persistor = persistStore(store);

export { store, persistor }