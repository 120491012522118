import { functions } from 'firebase';
import { httpsCallable } from 'firebase/functions';
import { PBInteraction, ProblemBehavior } from './reducers';

export const pbServices = {
    fetchPbList,
    createPb,
    changePbActive,

    pbInteraction,
    getPbInteractions,
};

function fetchPbList(childUuid: string) {
    return new Promise<ProblemBehavior[]>((resolve, reject) => {
        const fetchPbList = httpsCallable(functions, "fetchPbList-fetchPbList");
        fetchPbList({
            childUuid: childUuid,
        })
            .then(data => {
                //console.log("[pbs services] received data getting pbList:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[pbs services] fetch Pb List returned error:", (data.data as any).error);
                    resolve(null);
                }
                else {
                    resolve((data.data as any).pbList);
                }
            })
            .catch(err => {
                //console.log("[pbs services] error calling fetchPbList cloud function:", err);
                reject(err);
            });
        /* api.get(`problem-behaviours/?child_uuid=${childUuid}/`)
        .then(response => {
            //console.log("[Problem behaviors services] fetched pb list:", response.data);
            resolve(response.data);
        })
        .catch(err => {
            //console.log("[Problem behaviors services] error fetching list:", err);
            reject(err.response.data)
        }); */
    })
}

function createPb(name: string, childUuid: string, isActive: boolean,) {
    return new Promise<any>((resolve, reject) => {
        const createPb = httpsCallable(functions, "createPb-createPb");
        createPb({
            name: name,
            childUuid: childUuid,
            isActive: isActive,
        })
            .then(data => {
                //console.log("[pbs services] received data creating pb:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[pbs services] createPb returned error:", (data.data as any).error);
                    resolve(null);
                }
                else {
                    resolve((data.data as any).pbList);
                }
            })
            .catch(err => {
                //console.log("[pbs services] error calling createPb cloud function:", err);
                reject(err);
            });
        // let payload = new FormData();
        // payload.append('name', name);
        // payload.append('child__uuid', child);
        // const options = { headers: { 'content-type': 'multipart/form-data' } };


        /* api.post('problem-behaviours/', payload, options)
        .then(response => {
            let returnPb: ProblemBehavior = response.data;
            resolve(returnPb)
        })
        .catch(error => {
            reject(error.response.data)
        }); */
    })
}


function changePbActive(pbUuid: string, isActive: boolean) {
    return new Promise((resolve, reject) => {
        const changePbActive = httpsCallable(functions, "changePbActive-changePbActive");
        changePbActive({
            pbUuid: pbUuid,
            isActive: isActive,
        })
            .then(data => {
                //console.log("[pbs services] received data changePbActive:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[pbs services] changePbActive returned error:", (data.data as any).error);
                    resolve(null);
                }
                else {
                    resolve((data.data as any).pbList);
                }
            })
            .catch(err => {
                //console.log("[pbs services] error calling changePbActive cloud function:", err);
                reject(err);
            });
    })
}

function pbInteraction(pb_uuid: string, notes?: string, video_notes?: File | Blob, audio_notes?: File | Blob,) {
    return new Promise((resolve, reject) => {
        let payload = new FormData();
        payload.append('problem_behaviour__uuid', pb_uuid);
        if (notes)
            payload.append('text_notes', notes);
        if (video_notes) {
            payload.append('video_notes', video_notes);
        }
        if (audio_notes) {
            payload.append('audio_notes', audio_notes);
        }

        /* api.post('problem-behaviours-interactions/', payload)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error.response.data)); */
    })
}

function getPbInteractions() {
    return new Promise<PBInteraction[]>((resolve, reject) => {
        /* api.get('problem-behaviours-interactions/')
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            reject(error.response.data)
        }); */
    })
}