import React from "react";
import {
    IonTitle, IonContent, IonHeader, IonToolbar,
    IonBackButton, IonButtons, IonPage, IonGrid,
    IonList, IonButton, IonItem, IonIcon, IonToast,
} from "@ionic/react";
import { connect } from 'react-redux';
import { Child } from "modules/children/store/reducers";
import { ProblemBehavior } from "../store/reducers";
import { pbServices } from "../store/services";
import { add } from "ionicons/icons";
import PBCreation from "./PBCreation";
import PBReport from "./PBReport";
import './PBList.css';

type PBProps = {
    history: any,
    currentChild: Child | null,
}

type State = {
    problemBehaviorList: ProblemBehavior[],
    showPbCreationPopover: boolean,
    selectedPbUuid: string | null,
    showPBReportAddDetailsPopover: boolean,
    showPbReportedToast: boolean,
}

class PBList extends React.Component<PBProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            problemBehaviorList: [],
            showPbCreationPopover: false,
            showPBReportAddDetailsPopover: false,
            selectedPbUuid: null,
            showPbReportedToast: false,
        }
    }

    componentDidMount() {
        if (this.props.currentChild) {
            this.fetchPbList(this.props.currentChild.uuid);
        }
    }

    fetchPbList(childUuid: string) {
        pbServices.fetchPbList(childUuid)
            .then(pbList => {
                this.setState({ problemBehaviorList: pbList });
            })
            .catch(err => {
                //console.log("[PBList] error fetching pb list:", err)
            })
    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/autonomies/list" />
                        </IonButtons>

                        <IonTitle>Comportamenti problema</IonTitle> {/* TO LOCALIZE THIS */}

                        <IonButtons slot="end">
                            <IonButton
                                onClick={() => {
                                    this.setState({ showPbCreationPopover: true });
                                }}
                            >
                                <IonIcon
                                    icon={add}
                                />
                            </IonButton>
                        </IonButtons>

                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonGrid className='pbListGrid'>

                        {
                            this.state.problemBehaviorList.length === 0 &&
                            <p className="pbListCreateNewPbHint">
                                Ai fini della presa dati in questa sezione puoi gestire e segnalare i comportamenti problema.<br /><br /> {/* TO LOCALIZE THIS */}
                                Inizia creando un comportamento problema premendo sul + in alto a destra. {/* TO LOCALIZE THIS */}
                            </p>
                        }

                        <IonList>
                            {
                                this.state.problemBehaviorList.map((problem) => {
                                    return (
                                        <IonItem
                                            key={problem.uuid}
                                            onClick={() => {
                                                this.setState({
                                                    selectedPbUuid: problem.uuid,
                                                    showPBReportAddDetailsPopover: true
                                                });
                                            }}
                                        >
                                            <p>
                                                {problem.name}
                                            </p>
                                        </IonItem>
                                    )
                                })
                            }
                        </IonList>
                    </IonGrid>
                </IonContent>

                <PBCreation
                    isOpen={this.state.showPbCreationPopover}
                    onDidDismiss={() => this.setState({ showPbCreationPopover: false })}
                    onSubmit={() => { /* clicked button */ }}
                    onPbCreated={() => {
                        if (this.props.currentChild) {
                            this.fetchPbList(this.props.currentChild.uuid);
                        }
                        this.setState({ showPbCreationPopover: false });
                    }}
                />

                <PBReport
                    isOpen={this.state.showPBReportAddDetailsPopover}
                    pbUuid={this.state.selectedPbUuid}
                    onPbReported={() => {
                        this.setState({
                            showPBReportAddDetailsPopover: false,
                            showPbReportedToast: true,
                            selectedPbUuid: null,
                        })
                    }}
                    onDidDismiss={() => {
                        this.setState({
                            selectedPbUuid: null,
                            showPBReportAddDetailsPopover: false,
                        })
                    }}
                />

                <IonToast
                    isOpen={this.state.showPbReportedToast}
                    onDidDismiss={() => this.setState({ showPbReportedToast: false })}
                    message="Comportamento problema registrato. ✅" /* TO LOCALIZE THIS */
                    duration={1000}
                />
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentChild: state.children.currentChild,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PBList);