import { Chat, Message } from "./reducers";

export function fromDbChatToChat(chatUuid: string, data: any): Chat {
    const chatToReturn: Chat = {
        uuid: chatUuid,
        messages: [],
    }

    if (data && data["m"]) {
        const messagesKeys = Object.keys(data["m"]);
        for (let i = 0; i < messagesKeys.length; i++) {
            chatToReturn.messages.push(fromDbChatMessageToMessage(messagesKeys[i], data["m"][messagesKeys[i]]));
        }
        chatToReturn.messages = chatToReturn.messages.reverse();
    }

    return chatToReturn
}

export function fromDbChatMessageToMessage(messageUuid: string, messageData: any): Message {
    return {
        uuid: messageUuid,
        mediaGsUrl: messageData["m"] ? messageData["m"] : undefined,
        mediaPublicUrl: messageData["p"] ? messageData["p"] : undefined,
        sentAt: messageData["c"],
        sentBy: messageData["u"],
        text: messageData["t"] ? messageData["t"] : undefined,
        format: messageData["f"] ? messageData["f"] : undefined,
    }
}