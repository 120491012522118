import React from 'react';
import {
    IonTabBar, IonTabButton, IonIcon, IonLabel,
} from '@ionic/react';
import { fileTrayFullOutline, analytics, cog, list } from 'ionicons/icons'
import { connect } from 'react-redux';
import { Child } from "../../children/store/reducers";

type Props = {
    history?: any,
    currentChild: Child | null,
}

class MenuTabBar extends React.Component<Props> {

    render() {
        return (
            <IonTabBar className='bottomTabBar' slot="bottom">
                {/* {
                    this.props.currentChild &&
                    <> */}


                <IonTabButton tab="data-collection" href="/data-collection/home">
                    <IonIcon icon={analytics} />
                    <IonLabel>Analisi dati</IonLabel> {/* TO LOCALIZE THIS */}
                </IonTabButton>

                <IonTabButton className='title' tab="tasks" href="/tasks/list">
                    <IonIcon icon={list} />
                    <IonLabel>Tasks</IonLabel> {/* TO LOCALIZE THIS */}
                </IonTabButton>
                {/* </>
                } */}

                <IonTabButton tab="child" href="/children">
                    <IonIcon icon={fileTrayFullOutline} />
                    <IonLabel>Gestione schede</IonLabel>
                </IonTabButton>

                <IonTabButton tab="settings" href="/settings">
                    <IonIcon icon={cog} />
                    <IonLabel>Impostazioni</IonLabel> {/* TO LOCALIZE THIS */}
                </IonTabButton>

            </IonTabBar>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentChild: state.children.currentChild,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MenuTabBar);
