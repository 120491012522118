import {
    IonAvatar, IonList, IonRow, IonSkeletonText,
} from "@ionic/react";
import React from "react";
import "../pages/DataAnalysisHome.css"


type Props = {
    skeletonNumber: number,
    buttonNumber: number
}

type State = {
}

class TaskSkeleton extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            notes: '',
            buttonPressed: false
        }
    }

    render() {
        return (
            <IonList style={{ width: '100%' }}>
                {
                    Array.from({ length: this.props.skeletonNumber }, (k: any) => (
                        // <IonRow>
                        //     <IonSkeletonText key={k} animated style={{ width: "100%" }}></IonSkeletonText>
                        // </IonRow>
                        <div
                            key={k}
                            className='dataCollectionHomeItem'
                        >
                            <IonSkeletonText key={k} animated style={{ width: "100%", height: '15px' }}></IonSkeletonText>

                            <div key={k} className="dataCollectionHomeButtonsDiv">
                                {
                                    Array.from({ length: this.props.buttonNumber }, (k: any) => (
                                        <IonRow
                                            key={k}
                                            className="dataCollectionGraphButtonRow"
                                            style={{ margin: '15px' }}

                                            slot="end"
                                        >
                                            <IonAvatar key={k} style={{ width: '35px', height: '35px' }}>
                                                <IonSkeletonText key={k} animated style={{ width: "100%" }}></IonSkeletonText>
                                            </IonAvatar>
                                            <p key={k} className="dataCollectionButtonLabelParagraph">
                                                <IonSkeletonText key={k} animated style={{ width: "100%" }}></IonSkeletonText>
                                            </p>
                                        </IonRow>
                                    )
                                    )
                                }

                            </div>

                        </div>

                    ))
                }
            </IonList>

        );

    }
}

export default TaskSkeleton;