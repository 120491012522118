import {
    IonButton, IonContent, IonGrid, IonIcon, IonLabel,
    IonPopover, IonRow, IonTextarea, IonTitle,
} from "@ionic/react";
import { database } from "firebase";
import { ref, update } from "firebase/database";
import { checkmark, closeOutline } from "ionicons/icons";
import { Child } from "modules/children/store/reducers";
import React from "react";
import { isMobile } from "react-device-detect";
import { connect } from 'react-redux';
import "./NotesCreation.css";


type Props = {
    isOpen: boolean,
    currentSessionUuid: string,
    currentChild: Child,

    onDidDismiss?: () => void,
}

type State = {
    notesBefore: string
    notesDuring: string
    notesAfter: string
    buttonPressed: boolean
}

class NotesCreation extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            notesBefore: "",
            notesDuring: "",
            notesAfter: "",
            buttonPressed: false
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <IonPopover
                isOpen={this.props.isOpen}
                onDidDismiss={() => {
                    if (this.props.onDidDismiss) {
                        this.props.onDidDismiss();
                    }
                }}

                className="notesCreationPopover"
                mode="md"
            >
                <IonIcon onClick={() => this.props.onDidDismiss()} icon={closeOutline} size='large' style={{ right: '0', position: 'absolute', cursor: 'pointer' }} />

                <IonTitle
                    className="notesCreationPopoverTitle"
                >
                    Aggiungi delle note {/* TO LOCALIZE THIS */}
                </IonTitle>
                <IonContent>
                    <IonGrid className='notesCreationGrid'>
                        <div>
                            <IonLabel>Prima della sessione:</IonLabel>
                            <IonTextarea
                                cols={isMobile ? 20 : 50}
                                className='inputField'
                                placeholder='Cosa è successo prima della sessione'
                                onIonChange={(e) => this.setState({ notesBefore: e.detail.value })}
                                disabled={this.state.buttonPressed}
                                inputMode='text'
                                maxlength={150}
                            />
                            <IonLabel>Durante la sessione:</IonLabel>
                            <IonTextarea
                                cols={isMobile ? 20 : 50}
                                className='inputField'
                                placeholder='Cosa è successo durante la sessione'
                                onIonChange={(e) => this.setState({ notesDuring: e.detail.value })}
                                disabled={this.state.buttonPressed}
                                inputMode='text'
                                maxlength={150}
                            />
                            <IonLabel>Dopo la sessione:</IonLabel>
                            <IonTextarea
                                cols={isMobile ? 20 : 50}
                                className='inputField'
                                placeholder='Cosa è successo dopo la sessione'
                                onIonChange={(e) => this.setState({ notesAfter: e.detail.value })}
                                disabled={this.state.buttonPressed}
                                inputMode='text'
                                maxlength={150}
                            />
                            <IonRow className='buttonRow'>
                                <IonButton
                                    disabled={(this.state.notesBefore === '') && (this.state.notesDuring === '') && (this.state.notesDuring === '') || this.state.buttonPressed}
                                    onClick={async () => {
                                        await update(ref(database, `s/${this.props.currentChild.uuid}/${this.props.currentSessionUuid}/n/`), {
                                            b: this.state.notesBefore,
                                            d: this.state.notesDuring,
                                            a: this.state.notesAfter,
                                        });
                                        setTimeout(() => {
                                            this.props.onDidDismiss();
                                        }, 1000);
                                    }}
                                    color={this.state.buttonPressed ? 'success' : 'primary'}
                                    shape='round'
                                >
                                    Aggiungi
                                    {
                                        this.state.buttonPressed &&
                                        <IonIcon
                                            icon={checkmark}
                                        />
                                    }
                                </IonButton>
                            </IonRow>
                        </div>
                    </IonGrid>
                </IonContent>

            </IonPopover>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesCreation);