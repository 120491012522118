import {
    IonBackButton, IonButtons, IonContent, IonGrid, IonHeader,
    IonRow, IonSpinner, IonTitle, IonToolbar
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { auth } from 'firebase';
import { Player } from '@lottiefiles/react-lottie-player';
import waitAnimation from "../../../assets/animations/waitingHand.json";
import './MagicLink.css';

type MagicLinkProps = {
    history: any,
    match: any,

    isAuthenticated: Boolean,
}

type State = {
}

class MagicLink extends React.Component<MagicLinkProps, State> {

    componentDidMount(): void {

        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }

            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    //console.log("[MagicLink] signin result:", result);
                    window.localStorage.removeItem('emailForSignIn');

                    setTimeout(() => {
                        this.props.history.replace("/tasks/list");
                    }, 3000);
                })
                .catch((error) => {
                    //console.log("[MagicLink] error signin in wih email link", error.code, error.message);
                });
        }

    }

    render() {
        return (
            <IonContent>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonButtons slot='start'>
                            <IonBackButton defaultHref="/" />
                        </IonButtons>
                        <IonTitle>Accesso</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid className='magicLinkAccessGrid'>
                    <IonRow class='magicLinkAnimationPlayerRow'>
                        <Player
                            autoplay
                            loop
                            src={waitAnimation}
                        />
                    </IonRow>

                    <div className="magicLinkWelcomeTextDiv">
                        <IonSpinner />
                        <p className="magicLinkWelcomeTextParagraph">
                            Ti diamo il benvenuto in Maestro. {/* TO LOCALIZE THIS */}
                            <br />
                            Stiamo preparando il tuo ambiente di lavoro.
                        </p>
                    </div>
                </IonGrid>
            </IonContent>
        );
    }
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.access ? true : false
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MagicLink);