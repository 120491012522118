import {
    IonAlert,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { applicationActions } from '../store/actions';

type MessageOverlayProps = {
    history?: any,
    show?: boolean,
    title?: string,
    text?: string,

    hideMessage: () => void,
}

type State = {
}

class MessageOverlay extends React.Component<MessageOverlayProps, State> {


    render() {
        return (
            <IonAlert
                isOpen={this.props.show}
                onDidDismiss={() => this.props.hideMessage()}
                header={this.props.title}
                message={this.props.text}
                buttons={[
                    {
                      text: 'Ok',
                      handler: () => {
                        this.props.hideMessage();
                      }
                    }
                ]}
            />
        )
    }
}


const mapStateToProps = state => {
    return {
        show: state.app.message.show,
        title: state.app.message.title,
        text: state.app.message.text,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideMessage: () => {
            dispatch(applicationActions.hideMessage())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageOverlay);