import React from "react";
import {
    IonButton,
} from "@ionic/react";
import { connect } from 'react-redux';
import { Child } from "../store/reducers";
import "./ChildrenSwitchButton.css";

type Props = {
    currentChild: Child,

    slot?: string,
    onClick: () => void,
}

type State = {
    /* isLoading: boolean, */
}

class ChildrenSwitchButton extends React.Component<Props, State> {

    render() {
        return (
            <>
                {
                    this.props.currentChild &&
                    <IonButton
                        onClick={() => {
                            this.props.onClick()
                        }}
                        slot={this.props.slot ? this.props.slot : ""}
                        shape="round"
                        fill="outline"
                        className="childrenSwitchButton"
                    >
                        {
                            this.props.currentChild ? (this.props.currentChild.firstName ? this.props.currentChild.firstName : "Aggiungere nome") : ""
                        }
                    </IonButton>
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentChild: state.children.currentChild,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenSwitchButton);