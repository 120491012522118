import { childrenConstant } from "./constants";
import { Child } from "./reducers";
import { database } from "firebase";
import { ref as dbRef, update } from "firebase/database";

export const childrenActions = {
	setCurrentChild,
	setChildren,
	updateChildInList,
}

const actionCreator = {
	setCurrentChild: (child: Child | null) => { return { type: childrenConstant.SWITCH_CURRENT, child } },
	setChildren: (children: Child[]) => { return { type: childrenConstant.SET_CHILDREN, children } },
	updateChildInList: (child: Child) => { return { type: childrenConstant.UPDATE_CHILD_IN_LIST, child } },
}

function setCurrentChild(child: Child | null, userUuid: string) {
	return dispatch => {
		update(dbRef(database, `u/${userUuid}`), {
			c: child ? child.uuid : null,
		})
			.then(() => {
				dispatch(actionCreator.setCurrentChild(child));
			})
			.catch(err => {
				//console.log("[children actions] error getting last user child:", err); // FOR DEBUGGING
				dispatch(actionCreator.setCurrentChild(child));
			});
	};
}

function setChildren(children: Child[]) {
	return dispatch => {
		dispatch(actionCreator.setChildren(children));
	}
}

function updateChildInList(child: Child) {
	return dispatch => {
		dispatch(actionCreator.updateChildInList(child));
	}
}