import React from "react";
import {
    IonContent, IonGrid, IonPage, IonHeader, IonToolbar,
    IonTitle, IonInput, IonButton, IonButtons, IonBackButton,
    IonAlert,
} from "@ionic/react";
import { connect } from 'react-redux';
import { authActions } from "modules/authentication/store/actions";
import { signInWithEmailAndPassword, updateEmail, User } from "firebase/auth";
import { auth, database } from "firebase";
import { ref, update } from "firebase/database";
import "./UpdateEmail.css";

type Props = {
    history: any,
}

type State = {
    oldEmail: string | null,
    password: string | null,
    userData: User | null,
    newEmail: string | null,

    stepCount: number,
    isPasswordWrong: boolean,
    isNewEmailWrong: boolean,
    showTooManyRequestsAlert: boolean,
}

class UpdateEmail extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            oldEmail: null,
            password: null,
            userData: null,
            newEmail: null,

            stepCount: 0,
            isPasswordWrong: false,
            isNewEmailWrong: false,
            showTooManyRequestsAlert: false,
        }
    }
    componentDidMount() {
        auth.onAuthStateChanged(user => {
            if(user) {
                this.setState({
                    oldEmail: user.email,
                })
            }
            else {
                this.setState({
                    oldEmail: null,
                    userData: null,
                })
            }
        });
    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonTitle>
                            Aggiorna email {/* TO BE LOCALIZED */}
                        </IonTitle>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/settings" />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonGrid className="updateEmailMainGrid">
                        {
                            this.state.stepCount !== 2 &&
                            <h2>
                                Aggiornamento della email
                            </h2>
                        }

                        {
                            this.state.stepCount === 0 &&
                            <div>
                                <p className="updateEmailInsertPasswordParagraph">
                                    Per prima cosa, inserisci la tua <b>password</b> per verificare il tuo account. {/* TO BE LOCALIZED */}
                                </p>

                                {
                                    this.state.isPasswordWrong &&
                                    <p className="updateEmailWrongPassword">
                                        Password errata. Ritenta o <a href="/authentication/reset-password">cambia password</a>. {/* TO BE LOCALIZED */}
                                    </p>
                                }
                                
                                <IonInput
                                    className="updateEmailInputField"
                                    placeholder="La tua password" /* TO BE LOCALIZED */
                                    autocomplete="current-password"
                                    type="password"
                                    onIonChange={(e) => {
                                        this.setState({ password: e.detail.value ? e.detail.value : null });
                                    }}
                                />

                                <div className="updateEmailButtonDiv">
                                    <IonButton
                                        className="updateEmailButton"
                                        disabled={
                                            !this.state.oldEmail ||
                                            !this.state.password
                                        }
                                        onClick={() => {
                                            if(this.state.oldEmail && this.state.password) {
                                                signInWithEmailAndPassword(auth, this.state.oldEmail, this.state.password)
                                                .then(userData => {
                                                    this.setState({
                                                        userData: userData.user,
                                                        stepCount: 1,
                                                    });
                                                })
                                                .catch(err => {
                                                    console.error("[UpdateEmail] signing user:", err);
                                                    if(err.code === "auth/wrong-password") {
                                                        this.setState({ isPasswordWrong: true });
                                                    }
                                                    else if(err.code === "auth/too-many-requests") {
                                                        this.setState({ showTooManyRequestsAlert: true });
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        Confermo la password {/* TO BE LOCALIZED */}
                                    </IonButton>
                                </div>
                            </div>
                        }

                        {
                            this.state.stepCount === 1 &&
                            <div className="updateEmailStepDiv">
                                <p>
                                    Inserisci la email che vorrai utilizzare da ora in poi per accedere a Maestro. {/* TO BE LOCALIZED */}
                                </p>

                                {
                                    this.state.isNewEmailWrong &&
                                    <p className="updateEmailWrongPassword">
                                        Email non valida, verificare il formato. {/* TO BE LOCALIZED */}
                                    </p>
                                }

                                <IonInput
                                    className="updateEmailInputField"
                                    placeholder="La tua nuova email" /* TO BE LOCALIZED */
                                    autocomplete="email"
                                    type="email"
                                    onIonChange={(e) => {
                                        this.setState({ newEmail: e.detail.value ? e.detail.value.trim() : null });
                                    }}
                                />

                                <div className="updateEmailButtonDiv">
                                    <IonButton
                                        className="updateEmailButton"
                                        disabled={
                                            !this.state.newEmail
                                        }
                                        onClick={() => {
                                            updateEmail(this.state.userData, this.state.newEmail)
                                            .then(() => {
                                                update(ref(database, `p/${this.state.userData.uid}`), {
                                                    e: this.state.newEmail.trim(),
                                                })
                                                .then(() => {
                                                    this.setState({ stepCount: 2 });
                                                })
                                                .catch(err => {
                                                    console.error("[UpdateEmail] updating email on db - public profile section:", err);
                                                });
                                            })
                                            .catch(err => {
                                                console.error("[UpdateEmail] error updating email:", err);
                                                if(err.code === "auth/invalid-email") {
                                                    this.setState({
                                                        isNewEmailWrong: true,
                                                    });
                                                }
                                                else if(err.code === "auth/too-many-requests") {
                                                    this.setState({ showTooManyRequestsAlert: true });
                                                }
                                            })

                                            
                                        }}
                                    >
                                        Aggiorna la tua email
                                    </IonButton>
                                </div>
                            </div>
                        }

                        {
                            this.state.stepCount === 2 &&
                            <div className="updateEmailSuccessStepDiv">
                                <div className="updateEmailSuccessInnerDiv" />

                                <div className="updateEmailSuccessInnerDiv">
                                    <h3>
                                        Email aggiornata con successo ✅{/* TO BE LOCALIZED */}
                                    </h3>
                                    <p>
                                        Da ora in poi per accedere a Maestro dovrai utilizzare la seguente email: <i>{this.state.newEmail}</i>. {/* TO BE LOCALIZED */}
                                    </p>

                                    <div className="updateEmailSuccessButtonDiv">
                                        <IonButton
                                            className="updateEmailSuccessButton"
                                            onClick={() => {
                                                this.props.history.replace(`/data-collection/home`);
                                            }}
                                        >
                                            Continua a lavorare con Maestro
                                        </IonButton>
                                    </div>
                                </div>

                                <div className="updateEmailSuccessInnerDiv" />
                            </div>
                        }
                    </IonGrid>
                </IonContent>

                <IonAlert
                    isOpen={this.state.showTooManyRequestsAlert}
                    onDidDismiss={() => {
                        this.setState({
                            showTooManyRequestsAlert: false,
                        });
                    }}
                    keyboardClose={true}
                    header={"Troppe richieste"} /* TO BE LOCALIZED */
                    subHeader={"Contattare il supporto o ritentare tra un po' di tempo."} /* TO BE LOCALIZED */
                    buttons={["Ok"]}
                />
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(authActions.logout())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmail);