import axios from "axios";
import { Category, Post } from "./reducers";

const blogApi = axios.create({
    baseURL: "https://estro.ai/wp-json/wp/v2/",
});

export const blogService = {
    getCategories,
    getPosts,
    getPost,
};

function getCategories() {
    return new Promise<Category[]>((resolve, reject) => {
        blogApi.get(`categories/`)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                //console.log('[Blog services] error getting categories:', err)
                reject(err);
            })
    })
}

function getPosts(categories?: number[]) {
    return new Promise<Post[]>((resolve, reject) => {

        let categoriesParam = '';
        if (categories && (categories.length > 0)) {
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i];
                categoriesParam += category + ',';
            }
        }

        const postsToReturn: Post[] = [];

        blogApi.get(`posts/${(categoriesParam !== '') ? `?categories=${categoriesParam}&_embed` : '?_embed'}`)
            .then(response => {
                for (let j = 0; j < (response.data as Post[]).length; j++) {
                    const post: Post = response.data[j];
                    if (!(
                        post.title.rendered.toLowerCase().includes('covid') ||
                        post.excerpt.rendered.toLowerCase().includes('covid') ||
                        post.content.rendered.toLowerCase().includes('covid'))
                    ) {
                        post.title.rendered = decodeHtmlString(post.title.rendered);
                        postsToReturn.push(post);
                    }
                }
                resolve(postsToReturn);
            })
            .catch(err => {
                //console.log('[Blog services] error getting posts:', err)
                reject(err);
            })
    })
}

function getPost(postUuid: number) {
    return new Promise<Post>((resolve, reject) => {
        blogApi.get(`posts/${postUuid}/?_embed`)
            .then(response => {
                //console.log('[getPost] response:', response.data); // for debugging
                const postToReturn: Post = response.data;
                postToReturn.content.rendered = postToReturn.content.rendered.replace(/(width="+[0-9]*")|(height="+[0-9]*")/g, '');
                postToReturn.content.rendered = postToReturn.content.rendered.replace(/(class="+[\s\w-0-9]+")|(style="[\s\w0-9:-]+")/g, '');
                postToReturn.title.rendered = decodeHtmlString(response.data.title.rendered);
                resolve(postToReturn);
            })
            .catch(error => {
                //console.log('[getPost] error:', error);
                reject(error.response.data)
            });
    })
}


// solution found here: https://stackoverflow.com/questions/17678694/replace-html-entities-e-g-8217-with-character-equivalents-when-parsing-an
const escapeChars = { lt: '<', gt: '>', quot: '"', apos: "'", amp: '&' };
function decodeHtmlString(text: string) {
    return text.replace(/\&([^;]+);/g, function (entity, entityCode) {
        var match;

        if (entityCode in escapeChars) {
            return escapeChars[entityCode];
        } else if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
            return String.fromCharCode(parseInt(match[1], 16));
        } else if (match = entityCode.match(/^#(\d+)$/)) {
            return String.fromCharCode(~~match[1]);
        } else {
            return entity;
        }
    });
}