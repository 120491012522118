import { IonButton, IonIcon } from "@ionic/react";
import { auth, database } from "firebase";
import { push, ref as dbRef } from 'firebase/database';
import { helpCircleOutline } from "ionicons/icons";
import React, { useReducer } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";

// Define the Tutorial component
const Tutorial = (props) => {

  // Define the steps
  let TOUR_STEPS = props.steps

  // Define our state
  const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS,
  };

  // Set up the reducer function
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  /*   useEffect(() => {
      if (!localStorage.getItem("tour")) {
        //dispatch({ type: "START" });
      }
    }, []); 
  */
  const isComponentMounted = () => {
    let isComponentMounted = false;
    props.steps.forEach(step => {
      if (document.getElementsByClassName(step.target.replace('.', ''))[0] !== undefined)
        isComponentMounted = true
    });
    return isComponentMounted
  }

  const updateDB = (action: number, timestamp: number, step: string) => {
    push(dbRef(database, `tu/${auth.currentUser.uid}/${props.steps[0].id}/`), {
      a: action,
      t: timestamp,
      s: step,
    })
      .then(response => {
        //console.log('added tutorial log', JSON.stringify(response));
      })
      .catch(err => {
        //console.log('tutorial log error:', JSON.stringify(err));
      })
  }

  const callback = (data) => {
    const { action, index, type, status } = data;
    // if (action === ACTIONS.NEXT)
    //   console.log('next, index: ', action, status, type, index);
    if (type === EVENTS.TOUR_START) {
      if (!isComponentMounted()) { //Checks if tutorial elements are not mounted
        alert('non ce nulla')
        return
      }
      const nowTimestamp = Math.floor(new Date().getTime() / 1000);
      updateDB(0, nowTimestamp, props.steps[index].code);
      // console.log('Tutorial Started ' + props.steps[0].id + ", step: " + props.steps[index].code);
    }

    if (type === EVENTS.STEP_BEFORE && action !== ACTIONS.START && action === ACTIONS.NEXT) {
      const nowTimestamp = Math.floor(new Date().getTime() / 1000);
      updateDB(1, nowTimestamp, props.steps[index].code);
    }

    if (type === EVENTS.STEP_BEFORE && action !== ACTIONS.START && action === ACTIONS.PREV) {
      const nowTimestamp = Math.floor(new Date().getTime() / 1000);
      updateDB(2, nowTimestamp, props.steps[index].code);
    }

    if (action === ACTIONS.CLOSE) {
      const nowTimestamp = Math.floor(new Date().getTime() / 1000);
      updateDB(3, nowTimestamp, props.steps[index].code);
    }

    if (action === ACTIONS.SKIP) {
      const nowTimestamp = Math.floor(new Date().getTime() / 1000);
      updateDB(4, nowTimestamp, props.steps[index].code);
    }

    if (status === STATUS.FINISHED) {
      const nowTimestamp = Math.floor(new Date().getTime() / 1000);
      updateDB(5, nowTimestamp, props.steps[index].code);
    }

    if (type === EVENTS.TARGET_NOT_FOUND) {
      const nowTimestamp = Math.floor(new Date().getTime() / 1000);
      updateDB(99, nowTimestamp, props.steps[index].code);
    }


    if (action === ACTIONS.CLOSE || (status === STATUS.SKIPPED && tourState.run) || status === STATUS.FINISHED) {
      dispatch({ type: "STOP" });
    }
    else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };
  const startTour = () => {
    dispatch({ type: "RESTART" });
  };
  return (
    <>
      <IonButton hidden={props.hidden} slot="end" fill="clear" onClick={startTour}>
        <IonIcon icon={helpCircleOutline} size='large' />
      </IonButton>
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={true}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },

          buttonBack: {
            marginRight: 10,
          },
        }}
        locale={{  //TO LOCALIZE THIS
          last: 'Fine',
          back: 'Indietro',
          next: 'Avanti',
          close: 'Chiudi',
          open: 'Apri',
          skip: 'Salta'
        }}
      />
    </>
  );
};
export default Tutorial;
