import { PBConstants } from './constants';

export interface PBStore {
  list: ProblemBehavior[],
}

export interface ProblemBehavior {
  uuid: string,
  name: string,
  child: string,
  created_at: number,
  active: boolean,
}

export interface PBInteraction {
  uuid: string,
  value: number,
  mode: number,
  created_at: Date
}


const initialState: PBStore = { list: [] };

const pb = (state = initialState, action) => {
  switch (action.type) {
    case PBConstants.FETCH_PB_LIST:
      return { ...state, list: action.pblist }

    case PBConstants.CREATE_PB:
      return { ...state, list: [...state.list, action.pb] }

    case PBConstants.PB_INTERACTION:
      return { ...state, list: action.pblist }

    default:
      return state

  }
}

export default pb;