//import { isPlatform } from '@ionic/react';
import { functions } from 'firebase';
import { httpsCallable } from 'firebase/functions';
//import { getMedia, getMediaNameFromS3Url } from 'common/cache';
import { Task } from './reducers';

export const tasksServices = {

    fetchAutonomiesList,
    fetchTask,

    createTask,
    updateTaskTitle,
    // updateAutonomyData,
    updateTaskDataCollectionMode,
    // updateAutonomySettings,
    // updateAutonomyCover,
    updateStepTitle,
    // updateStepVideo,
    deleteTask,

    // fetchStepsList,
    createStep,
    swapStepsOrder,
    deleteStep,

    // startSession,
    // endSession,
    // getSessions,
    // createStepInteraction,
    // patchStepInteraction,
    // getStepInteractions,

    // addNotes,

};

function updateTaskDataCollectionMode(taskUuid: string, dataCollectionMode: number) {
    return new Promise<Task>((resolve, reject) => {

        const editTask = httpsCallable(functions, "editTask-editTask");
        editTask({
            taskUuid: taskUuid,
            dataCollectionMode: dataCollectionMode,
        })
            .then(data => {
                //console.log("[task services] received data editing data collection mode:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[task services] editing data collection mode returned error:", (data.data as any).error);
                    reject((data.data as any).error)
                }
                else {
                    //console.log("[task services] data collection mode edit success");
                    resolve((data.data as any).task);
                }
            })
            .catch(err => {
                //console.log("[task services] error calling edit data collection mode cloud function:", err);
                reject(err);
            });
    })
}

function fetchAutonomiesList(childUuid: string) {
    return new Promise<Task[]>((resolve, reject) => {
        const getTasks = httpsCallable(functions, "getTasks-getTasks");
        getTasks({
            childUuid: childUuid,
        })
            .then(data => {
                //console.log("[tasks services] received data getting tasks:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[tasks services] get tasks returned error:", (data.data as any).error);
                    resolve([]);
                }
                else {
                    //console.log("[tasks services] get tasks success");
                    resolve((data.data as any).tasks);
                }
            })
            .catch(err => {
                //console.log("[tasks services] error calling get tasks cloud function:", err);
                reject(err);
            });
    })
}

function fetchTask(taskUuid: string) {
    return new Promise<Task | null>((resolve, reject) => {

        const getTask = httpsCallable(functions, "getTask-getTask");
        getTask({
            taskUuid: taskUuid,
        })
            .then(data => {
                //console.log("[tasks services] received data getting task:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[tasks services] get task returned error:", (data.data as any).error);
                    resolve(null);
                }
                else {
                    resolve((data.data as any).task);
                }
            })
            .catch(err => {
                //console.log("[tasks services] error calling get task cloud function:", err);
                reject(err);
            });
    })
}

function updateTaskTitle(taskUuid: string, title: string) {
    return new Promise<Task | null>((resolve, reject) => {

        const editTask = httpsCallable(functions, "editTask-editTask");
        editTask({
            taskUuid: taskUuid,
            taskTitle: title,
        })
            .then(data => {
                //console.log("[task services] received data updating task title:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[task services] updating task title returned error:", (data.data as any).error);
                    reject((data.data as any).error)
                }
                else {
                    //console.log("[task services] editing task title success");
                    resolve((data.data as any).task);
                }
            })
            .catch(err => {
                //console.log("[task services] error calling editing task title function:", err);
                reject(err);
            });
    })
}

/* function fetchAutonomiesList(childUuid: string) {
    return new Promise((resolve, reject) => {
        api.get(`autonomies/autonomies/?child=${childUuid}`)
            .then(async response => {
                //console.log("FETCH LIST:", JSON.stringify(response.data));
                
                if((isPlatform('ios') || isPlatform('android')) && (isPlatform('cordova') || isPlatform('capacitor'))) {
                    const autonomiesListFetched: Autonomy[] = response.data;
                    const autonomiesListToReturn: Autonomy[] = [];

                    for (let i = 0; i < autonomiesListFetched.length; i++) {
                        const autonomy = autonomiesListFetched[i];
                        // autonomy.image = await getMedia(getMediaNameFromS3Url(autonomy.image), autonomy.image) as string;
                        for (let j = 0; j < autonomy.steps.length; j++) {
                            autonomy.steps[j].video = await getMedia(getMediaNameFromS3Url(autonomy.steps[j].video), autonomy.steps[j].video) as string;
                        }
                        autonomiesListToReturn.push(autonomy)
                    }

                    //console.log(`I'm going to return this autonomy list:`, JSON.stringify(autonomiesListToReturn));
                    resolve(autonomiesListToReturn);
                }
                else {
                    resolve(response.data);
                }
            })
            .catch(error => reject(error.response.data))
    })
} */

/* function fetchStepsList(autonomyUuid: string = null) {
    return new Promise<Step[]>((resolve, reject) => {
        api.get(`tasks-steps/?task__uuid=${autonomyUuid}`)
            .then(response => {
                //console.log('[fetchStepsList] response:', response.data);
                resolve(response.data);
            })
            .catch(error => reject(error.response.data))
    })
} */

function createTask(childUuid: string, title: string) {
    return new Promise<Task>((resolve, reject) => {
        const createTask = httpsCallable(functions, "createTask-createTask");
        createTask({
            childUuid: childUuid,
            title: title,
        })
            .then(data => {
                //console.log("[tasks services] received data creating task:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[tasks services] create task returned error:", (data.data as any).error);
                    resolve(null);
                }
                else {
                    //console.log("[tasks services] create task success");
                    resolve((data.data as any).task);
                }
            })
            .catch(err => {
                //console.log("[tasks services] error calling create task cloud function:", err);
                reject(err);
            });
    })
}

/* function updateAutonomyData(taskUuid: string, autonomyData: Object) {
    return new Promise((resolve, reject) => {
        api.patch(`tasks/${taskUuid}/`, autonomyData)
            .then(response => {
                //console.log("[updateAutonomyData] response: ", response.data);
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[updateAutonomyData] error:', error.response.data);
                reject(error.response.data);
            })
    })
} */

/* function updateAutonomySettings(taskUuid: string, autonomySettings: Object) {
    return new Promise<Task>((resolve, reject) => {
        api.patch(`tasks/${taskUuid}/settings/`, autonomySettings)
            .then(response => {
                //console.log("[updateAutonomySettings] response: ", response.data);
                api.get(`tasks/${taskUuid}/`)
                    .then(response2 => {
                        //console.log("[updateAutonomySettings] get full autonomy: ", response2.data);
                        resolve(response2.data);
                    })
                    .catch(error2 => reject(error2.response.data));
            })
            .catch(error => {
                //console.log('[updateAutonomySettings] error:', error.response.data);
                reject(error.response.data);
            })
    })
} */

/* function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
} */

/* function updateAutonomyCover(autonomyUuid: string, image: File | Blob) {
    return new Promise<Task>((resolve, reject) => {
        let payload = new FormData();

        payload.append('image', image, 'new_task.jpg');

        const options = { headers: { 'content-type': 'multipart/form-data' } };
        api.patch(`tasks/${autonomyUuid}/`, payload, options)
            .then(response => {
                //console.log("[updateAutonomyCover] response:", response.data); // for debugging
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[updateAutonomyCover] error:', error.response.data);
                reject(error.response.data);
            });
    })
} */

function deleteTask(taskUuid: string) {
    return new Promise((resolve, reject) => {
        const deleteTask = httpsCallable(functions, "deleteTask-deleteTask");
        deleteTask({
            taskUuid: taskUuid,
        })
            .then(data => {
                //console.log("[task services] received data deleting task:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[task services] delete task returned error:", (data.data as any).error);
                    resolve(false);
                }
                else {
                    //console.log("[task services] delete task success");
                    resolve((data.data as any).success);
                }
            })
            .catch(err => {
                //console.log("[task services] error calling delete task cloud function:", err);
                reject(err);
            });
    })
}

function createStep(taskUuid: string, title: string, video?: File | Blob) {
    return new Promise<Task>((resolve, reject) => {
        const editTask = httpsCallable(functions, "editTask-editTask");
        editTask({
            taskUuid: taskUuid,
            stepTitle: title,
        })
            .then(data => {
                //console.log("[task services] received data creating a new step to task:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[task services] create a new step returned error:", (data.data as any).error);
                    reject((data.data as any).error)
                }
                else {
                    //console.log("[task services] create a new task step success");
                    resolve((data.data as any).task);
                }
            })
            .catch(err => {
                //console.log("[task services] error calling delete task cloud function:", err);
                reject(err);
            });
    })
}

function swapStepsOrder(taskUuid: string, from: number, to: number) {
    return new Promise((resolve, reject) => {

        const editTask = httpsCallable(functions, "editTask-editTask");
        editTask({
            taskUuid: taskUuid,
            from: from,
            to: to,
        })
            .then(data => {
                //console.log("[task services] received data editing steps order:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[task services] edit steps order returned error:", (data.data as any).error);
                    reject((data.data as any).error)
                }
                else {
                    //console.log("[task services] editing steps order success");
                    resolve((data.data as any).task);
                }
            })
            .catch(err => {
                //console.log("[task services] error calling editing steps order cloud function:", err);
                reject(err);
            });
    })
}

function updateStepTitle(taskUuid: string, stepUuid: string, stepTitle: string) {
    return new Promise<Task>((resolve, reject) => {
        const editTask = httpsCallable(functions, "editTask-editTask");
        editTask({
            taskUuid: taskUuid,
            stepUuid: stepUuid,
            stepTitle: stepTitle,
        })
            .then(data => {
                //console.log("[task services] received data updating step title:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[task services] updating step title returned error:", (data.data as any).error);
                    reject((data.data as any).error)
                }
                else {
                    //console.log("[task services] editing step title success");
                    resolve((data.data as any).task);
                }
            })
            .catch(err => {
                //console.log("[task services] error calling editing step title function:", err);
                reject(err);
            });
    })
}

/* function updateStepVideo(stepUuid: string, video: File | Blob) {
    return new Promise<Step>((resolve, reject) => {
        let payload = new FormData();
        payload.append('video', video, 'new_step.mp4');
        const options = { headers: { 'content-type': 'multipart/form-data' } };
        api.patch(`tasks-steps/${stepUuid}/`, payload, options)
            .then(response => {
                //console.log('[updateStepVideo] response:', response.data);
                resolve(response.data);
            })
            .catch(error => reject(error.response.data))
    })
} */

function deleteStep(taskUuid: string, stepUuid: string) {
    return new Promise<Task>((resolve, reject) => {

        const editTask = httpsCallable(functions, "editTask-editTask");
        editTask({
            taskUuid: taskUuid,
            stepUuid: stepUuid,
            deleteStep: true,
        })
            .then(data => {
                //console.log("[task services] received data deleting step:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("[task services] step delete returned error:", (data.data as any).error);
                    reject((data.data as any).error)
                }
                else {
                    //console.log("[task services] step delete success");
                    resolve((data.data as any).task);
                }
            })
            .catch(err => {
                //console.log("[task services] error calling delete step cloud function:", err);
                reject(err);
            });
    })
}

/* function startSession(autonomyUuid: string, childUuid: string, endAt: Date, notes: string) {
    return new Promise<Session>((resolve, reject) => {
        api.post(`tasks-sessions/`, {
            task__uuid: autonomyUuid,
            child__uuid: childUuid,
            end_at: endAt,
            notes: notes,
        })
            .then(response => {
                //console.log("[startSession] response: ", response.data);
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[startSession] error:', error);
                reject(error.response.data);
            })
    })
} */

/* function endSession(sessionUuid: string) {
    return new Promise((resolve, reject) => {
        let timestampNow = Date.now()
        let dateNow = new Date(timestampNow)
        api.patch(`tasks-sessions/${sessionUuid}/`, {
            end_at: dateNow,
        })
            .then(response => {
                //console.log("[startSession] response: ", response.data);
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[startSession] error:', error);
                reject(error.response.data);
            })
    })
} */

/* function getSessions(taskUuid: string) {
    return new Promise<Session[]>((resolve, reject) => {
        api.get(`tasks-sessions/?ordering=-end_at&task__uuid=${taskUuid}`)
            .then(response => {
                //console.log("[getSessions] response: ", response.data.slice(0, 10)); //last 10 sessions created
                resolve(response.data.slice(0, 10));
            })
            .catch(error => {
                //console.log('[getSessions] error:', error);
                reject(error.response.data);
            })
    })
} */

/* function createStepInteraction(stepUuid: string, sessionUuid: string, value: number) {
    return new Promise((resolve, reject) => {
        api.post(`tasks-steps-interactions/`, {
            step__uuid: stepUuid,
            session__uuid: sessionUuid,
            value: value,
        })
            .then(response => {
                //console.log("[stepInteraction] response: ", response.data);
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[stepInteraction] error:', error);
                // reject(error.response.data);
            })
    })
} */

/* function patchStepInteraction(stepUuid: string, sessionUuid: string, value: number) {
    return new Promise((resolve, reject) => {
        api.patch(`tasks-steps-interactions/?session__uuid=${sessionUuid}&step__uuid=${stepUuid}/`, {
            step__uuid: stepUuid,
            session__uuid: sessionUuid,
            value: value,
        })
            .then(response => {
                //console.log("[patched step interaction] response: ", response.data)
            })
            .catch(error => {
                //console.log("[patch step interaction] error: ", error);
            })

    })
} */

/* function getStepInteractions(stepUuid?: string, sessionUuid?: string, taskUuid?: string, mode?: number | string) {
    return new Promise<StepInteraction[]>((resolve, reject) => {
        api.get(`tasks-steps-interactions/?session__uuid=${sessionUuid}&step__uuid=${stepUuid}&task__uuid=${taskUuid}&mode=${mode ? mode : ''}`)
            .then(response => {
                //console.log("[getStepInteractions] response: ", response.data);
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[getStepInteractions] error:', error);
                reject(error.response.data);
            })
    })
} */

/* function addNotes(sessionUuid: string, notes: string) {
    return new Promise((resolve, reject) => {
        let TimestampNow = Date.now()
        let DateNow = new Date(TimestampNow)
        let Date1MinFromNow = new Date(DateNow.setTime(DateNow.getTime() + 1000 * 60));

        api.patch(`tasks-sessions/${sessionUuid}/`, { notes: notes, end_at: Date1MinFromNow })
            .then(response => {
                //console.log("ADDED NOTES:", notes);
                resolve(response.data);
            })
            .catch(error => reject(error.response.data))
    })
} */