import { appConstant } from "./constants";

export const applicationActions = {
  showLoading,
  hideLoading,
  showMessage,
  hideMessage,
};

const actionCreator = {
  showLoading: (key) => { return { type: appConstant.SHOW_LOADING, key } },
  hideLoading: (key) => { return { type: appConstant.HIDE_LOADING, key } },
  showMessage: (title: string, text: string) => { return { type: appConstant.SHOW_MESSAGE, title, text } },
  hideMessage: () => { return { type: appConstant.HIDE_MESSAGE } },
}


function showLoading(key: string) {
  return dispatch => {
    dispatch(actionCreator.showLoading(key));
  };
}

function hideLoading(key: string) {
  return dispatch => {
    dispatch(actionCreator.hideLoading(key));
  };
}

function showMessage(title: string, text: string) {
  return dispatch => {
    dispatch(actionCreator.showMessage(title, text));
  };
}

function hideMessage() {
    return dispatch => {
      dispatch(actionCreator.hideMessage());
    };
}