export const cookieManager = {
    getCookies,
    getCookie,
    setCookie,
};

export interface Cookie {
    name: string,
    value: string,
}

function getCookies() {
    const cookiesToReturn: Cookie[] = [];
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        const cookie = {
            name: c.split('=')[0],
            value: c.substring(c.split('=')[0].length+1, c.length),
        };
        cookiesToReturn.push(cookie);
    }

    return cookiesToReturn;
}

function getCookie(name: string) {
    var completeName = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(completeName) === 0) {
            const returnCookie: Cookie = {
                name: name,
                value: c.substring(completeName.length, c.length),
            };
            return returnCookie;
        }
    }
    return null;
}

function setCookie(name: string, value: any, expiryDate: Date | null = null) {
    var d = new Date();
    if(expiryDate) {
        d = expiryDate;
    }
    else {
        d.setTime(d.getTime() + (180 *24*60*60*1000));
    }
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";      
}