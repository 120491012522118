import { functions } from 'firebase';
import { ref as dbRef, get, child } from 'firebase/database';
import { database } from "../../../firebase";
import { httpsCallable } from 'firebase/functions';
import { Invitation, UserProfile } from 'modules/user/store/reducers';
import { Child } from './reducers';

export const childrenService = {
	fetchChildren,

	fetchChild,
	deleteChild,
	getChildAccessList,
	getChildInvitations,

	updateChildFirstName,
	updateChildLastName,

	shareChildByUserEmail,
	childInvitationResponse,
};

function fetchChild(childUuid: string) {
	return new Promise<Child | null>((resolve, reject) => {
		const getChild = httpsCallable(functions, "getChild-getChild");
		getChild({
			childUuid: childUuid,
		})
			.then(data => {
				//console.log("[children services] received data getting child:", data.data);
				if ((data.data as any).error) {
					/* this.setState({ isLoading: false }); */
					//console.log("[children services] get child returned error:", (data.data as any).error);
					resolve(null);
				}
				else {
					//console.log("[children services] child get successfully.");
					resolve((data.data as any).child)
				}
			})
			.catch(err => {
				//console.log("[children services] error calling get child cloud function:", err);
				reject(err);
			})
	})
}

function fetchChildren() {
	return new Promise<Child[]>((resolve, reject) => {
		const fetchChildren = httpsCallable(functions, "fetchChildren-fetchChildren");
		fetchChildren()
			.then(data => {
				//console.log("[children actions] received data fetching children:", data.data);
				if ((data.data as any).error) {
					/* this.setState({ isLoading: false }); */
					//console.log("[children actions] returned error:", (data.data as any).error);
					resolve([]);
				}
				else {
					//console.log("[children actions] children get successfully.");
					resolve((data.data as any).children);
				}
			})
			.catch(err => {
				//console.log("[children actions] error calling get children cloud function:", err);
				reject(err);
			})
	})
}

function deleteChild(childUuid) {
	return new Promise((resolve, reject) => {

		const deleteChild = httpsCallable(functions, "deleteChild-deleteChild");
		deleteChild({
			childUuid: childUuid,
		})
			.then(data => {
				//console.log("[children services] received data deleting children:", data.data);
				if ((data.data as any).error) {
					/* this.setState({ isLoading: false }); */
					//console.log("[children services] delete child returned error:", (data.data as any).error);
					resolve(false);
				}
				else {
					//console.log("[children services] delete child success");
					resolve(true);
				}
			})
			.catch(err => {
				//console.log("[children services] error calling delete child cloud function:", err);
				reject(err);
			});
	})
}

function getChildAccessList(childUuid: string) {
	return new Promise<UserProfile[]>((resolve, reject) => {
		const getChildAccessListIds = httpsCallable(functions, "getChildAccessListIds-getChildAccessListIds");
		getChildAccessListIds({
			childUuid: childUuid,
		})
			.then(async data => {
				//console.log("[children services] received data getting child access list:", data.data);
				if ((data.data as any).error) {
					/* this.setState({ isLoading: false }); */
					//console.log("[children services] get child accessi list returned error:", (data.data as any).error);
					reject((data.data as any).error);
				}
				else {
					//console.log("[children services] getChildAccessList success");
					const accessListIds: string[] = (data.data as any).accessListIds as string[];
					const accessListToReturn: UserProfile[] = [];
					const usersProfilesDbRef = dbRef(database, `p`)
					for (let i = 0; i < accessListIds.length; i++) {
						try {
							const snapshot = await get(child(usersProfilesDbRef, accessListIds[i]));
							if (snapshot.exists()) {
								accessListToReturn.push({
									uuid: accessListIds[i],
									email: snapshot.val()["e"],
									firstName: snapshot.val()["f"],
									lastName: snapshot.val()["l"],
									role: snapshot.val()["r"],
								});
							}
						}
						catch (e) {
							//console.log("[children services] error getting user profile for profile id:", accessListIds[i], "error:", e);
						}
					}
					resolve(accessListToReturn);
				}
			})
			.catch(err => {
				//console.log("[children services] error calling get child access list cloud function:", err);
				reject(err);
			});
	})
}

function shareChildByUserEmail(childUuid: string, email: string) {
	return new Promise<Invitation[]>((resolve, reject) => {
		const childInvitePerson = httpsCallable(functions, "childInvitePerson-childInvitePerson");
		childInvitePerson({
			childUuid: childUuid,
			userToInviteEmail: email,
		})
			.then(data => {
				//console.log("[children services] received data sharing child:", data.data);
				if ((data.data as any).error) {
					/* this.setState({ isLoading: false }); */
					//console.log("[children services] share child returned error:", (data.data as any).error);
					if ((data.data as any).error.code) {
						switch ((data.data as any).error.code) {
							case 760:
								reject(760)
								break;

							case 400:
								reject(400)
								break;

							default:
								resolve([]);
								break;
						}
					}
					else {
						resolve([]);
					}
				}
				else {
					//console.log("[children services] share child successfully.");
					resolve((data.data as any).invitations)
				}
			})
			.catch(err => {
				//console.log("[children services] error calling share child cloud function:", err);
				reject(err);
			})
	})
}

function getChildInvitations(childUuid: string) {
	return new Promise<Invitation[]>((resolve, reject) => {
		const childGetInvitations = httpsCallable(functions, "childGetInvitations-childGetInvitations");
		childGetInvitations({
			childUuid: childUuid,
		})
			.then(data => {
				//console.log("[children services] received data getting child invitations:", data.data);
				if ((data.data as any).error) {
					/* this.setState({ isLoading: false }); */
					//console.log("[children services] get child invitations returned error:", (data.data as any).error);
					resolve(null);
				}
				else {
					//console.log("[children services] get child invitations successfully.");
					resolve((data.data as any).invitations)
				}
			})
			.catch(err => {
				//console.log("[children services] error calling get child invitations cloud function:", err);
				reject(err);
			});
	})
}

function childInvitationResponse(inviteUuid: string, accept: boolean) {
	return new Promise<string>((resolve, reject) => {
		const childInvitationResponse = httpsCallable(functions, "childInvitationResponse-childInvitationResponse");
		childInvitationResponse({
			inviteUuid: inviteUuid,
		})
			.then(data => {
				//console.log("[children services] received data getting child invitations:", data.data);
				if ((data.data as any).error) {
					/* this.setState({ isLoading: false }); */
					//console.log("[children services] get child invitations returned error:", (data.data as any).error);
					reject((data.data as any).error);
				}
				else {
					//console.log("[children services] get child invitations successfully.");
					resolve((data.data as any).childUuid)
				}
			})
			.catch(err => {
				//console.log("[children services] error calling get child invitations cloud function:", err);
				reject(err);
			});
	})
}

function updateChildFirstName(childUuid: string, firstName: string) {
	return new Promise<Child>((resolve, reject) => {
		const editChild = httpsCallable(functions, "editChild-editChild");
		editChild({
			childUuid: childUuid,
			firstName: firstName,
		})
			.then(async data => {
				//console.log("[children services] received data updating child first name:", data.data);
				if ((data.data as any).error) {
					/* this.setState({ isLoading: false }); */
					//console.log("[children services] updating child first name returned error:", (data.data as any).error);
					reject((data.data as any).error);
				}
				else {
					//console.log("[children services] updating child first name success");
					resolve((data.data as any).child);
				}
			})
			.catch(err => {
				//console.log("[children services] error calling update child first name cloud function:", err);
				reject(err);
			});
	})
}

function updateChildLastName(childUuid: string, lastName: string) {
	return new Promise<Child>((resolve, reject) => {
		const editChild = httpsCallable(functions, "editChild-editChild");
		editChild({
			childUuid: childUuid,
			lastName: lastName,
		})
			.then(async data => {
				//console.log("[children services] received data updating child last name:", data.data);
				if ((data.data as any).error) {
					/* this.setState({ isLoading: false }); */
					//console.log("[children services] updating child last name returned error:", (data.data as any).error);
					reject((data.data as any).error);
				}
				else {
					//console.log("[children services] updating child last name success");
					resolve((data.data as any).child);
				}
			})
			.catch(err => {
				//console.log("[children services] error calling update child last name cloud function:", err);
				reject(err);
			});
	})
}