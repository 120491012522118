import { IonButton, IonIcon } from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";
import React from "react";


type Props = {
    onInteraction?: (value: number) => void,
}

type State = {
    selectedButton: number | null,
}

class BooleanStep extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            selectedButton: null,
        }
    }

    /* componentDidMount() {
    } */

    render() {
        return (
            <div className="stepValueDiv">
                <IonButton
                    fill='clear'
                    color='tertiary'
                    class='button'

                    onClick={() => {
                        if (this.props.onInteraction) {
                            this.props.onInteraction(100);
                        }
                        this.setState({ selectedButton: 0 });
                    }}
                >
                    <IonIcon
                        style={this.state.selectedButton === 0 ? { color: 'red' } : {}}
                        size='large' icon={addOutline}
                    />
                </IonButton>

                <IonButton
                    fill='clear'
                    color='tertiary'
                    class='button'

                    onClick={() => {
                        if (this.props.onInteraction) {
                            this.props.onInteraction(0);
                        }
                        this.setState({ selectedButton: 1 });
                    }}
                >
                    <IonIcon
                        style={this.state.selectedButton === 1 ? { color: 'red' } : {}}
                        size='large'
                        icon={removeOutline}
                    />
                </IonButton>
            </div>
        );
    }
}

export default BooleanStep;