import React from 'react';
import {
	IonAlert,
	IonBackButton, IonButton, IonButtons, IonContent, IonGrid,
	IonHeader, IonIcon, IonInput, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import { auth } from "../../../firebase";
import { sendPasswordResetEmail } from 'firebase/auth';
import supportPersonIcon from "../../../assets/icons/supportPerson.svg";
import { connect } from 'react-redux';
import "./ResetPassword.css";


type Props = {
	history: any,
}

type State = {
	email: string | null,

	showErrorAlert: boolean,
	errorAlertHeaderMessage: string,
	errorAlertSubheaderMessage: string,

	showSupportButton: boolean,
}

class ResetPassword extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			email: null,

			showErrorAlert: false,
			errorAlertHeaderMessage: "",
			errorAlertSubheaderMessage: "",

			showSupportButton: false,
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot='start'>
							<IonBackButton defaultHref="/authentication/login" />
						</IonButtons>
						<IonTitle>
							Richiedi nuova password {/* TO BE LOCALIZED */}
						</IonTitle>
						{
							this.state.showSupportButton &&
							<IonButtons slot='end'>
								<IonButton
									fill='clear'
									shape='round'
									onClick={() => {
										window.open('https://wa.me/393661126799');
									}}
								>
									<IonIcon
										size='large'
										icon={supportPersonIcon}
									/>
								</IonButton>
							</IonButtons>
						}
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonGrid className="resetPasswordMainGrid">

						<div className="resetPasswordInputDiv">
							<div className="resetPasswordInputInnerDiv">
								<IonInput
									className="resetPasswordInputField"
									placeholder="La tua email" /* TO BE LOCALIZED */
									type="email"
									autocomplete="email"
									inputMode="email"
									value={this.state.email}
									onIonChange={(e) => {
										this.setState({ email: e.detail.value ? e.detail.value : null });
									}}
								/>
							</div>
						</div>

						<div className="resetPasswordButtonDiv">
							<IonButton
								className="resetPasswordButton"
								onClick={() => {
									sendPasswordResetEmail(auth, this.state.email)
										.then(() => {
											//console.log("[ResetPassword] email sent.");
											this.props.history.push(`/authentication/password-reset-email-sent`)

										})
										.catch((err) => {
											console.error("[ResetPassword] error sending password reset email:", err);
											if (err.code === "auth/user-not-found") {
												this.setState({
													errorAlertHeaderMessage: "Sembra che tu non faccia parte di Maestro", /* TO BE LOCALIZED */
													errorAlertSubheaderMessage: "Registrati da web o contatta il supporto.", /* TO BE LOCALIZED */
													showSupportButton: true,
												});
											}
											else if (err.code === "auth/missing-email") {
												this.setState({
													errorAlertHeaderMessage: "Email non inserita", /* TO BE LOCALIZED */
													errorAlertSubheaderMessage: "Completa il campo email.", /* TO BE LOCALIZED */
													showSupportButton: true,
												});
											}
											this.setState({ showErrorAlert: true });
										})
								}}
							>
								Richiedi nuova password
							</IonButton>
						</div>
					</IonGrid>
				</IonContent>

				<IonAlert
					isOpen={this.state.showErrorAlert}
					onDidDismiss={() => {
						this.setState({
							showErrorAlert: false,
							errorAlertHeaderMessage: "",
							errorAlertSubheaderMessage: "",
						});
					}}
					keyboardClose={true}
					header={this.state.errorAlertHeaderMessage} /* TO BE LOCALIZED */
					subHeader={this.state.errorAlertSubheaderMessage} /* TO BE LOCALIZED */
					buttons={["Ok"]}
				/>
			</IonPage>
		);
	}

}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.userData ? true : false,
	}
}


const mapDispatchToProps = dispatch => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);