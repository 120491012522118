import { childrenConstant } from './constants';
import { authConstants } from '../../authentication/store/constants';
import { User } from '../../user/store/reducers';

export interface ChildAccessUser {
	uuid: string,
	child: string,
	added_at: string,
	joined_at: string,
	is_owner: boolean,
	user: User,
}

/* export interface ChildSettings {
	custom_reinforcement_sound: string | null,
	favourite_color: string,
	allow_data_collection: boolean,
} */

export interface Child {
	uuid: string,
	firstName: string | null,
	lastName: string | null,
	ownerUuid: string,
	/* access_list: ChildAccessUser [], */
	/* settings: ChildSettings, */
	/* avatar: Avatar | null, */
}

interface ChildrenStore {
	currentChild: Child | null,
	list: Child[],
}

const initialState = {
	currentChild: null,
	list: [],
}

const children = (state : ChildrenStore = initialState, action) => {

	switch (action.type) {

		case childrenConstant.SWITCH_CURRENT:
			return {
				...state,
				currentChild: action.child,
			}

		case childrenConstant.UPDATE_CHILD_IN_LIST:
			const child = (action.child as Child);
			const childListTemp = state.list.slice();
			const childToUpdateIndex = state.list.findIndex(childEval => childEval.uuid === child.uuid);
			if(childToUpdateIndex !== -1 ) {
				childListTemp[childToUpdateIndex] = child;
			}
			return {
				...state,
				list: childListTemp,
			}

		case childrenConstant.SET_CHILDREN:
			return {
				...state,
				list: action.children,
			}

		case authConstants.LOGOUT:
			return initialState

		default:
			return state

	}
}

export default children;
