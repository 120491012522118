export const dataCollectionHomeSteps = [
    {
        id: 'Data collection - Home',
        title: "Lista delle task",
        code: 1,
        target: ".dataAnalysisHomeCard",
        content: "Qui troverai tutte le task che hai creato.",
        disableBeacon: true,
    },
    {
        title: "Pulsante delle sessioni",
        code: 2,
        target: ".viewSessionsRow",
        content: "Visualizza le sessioni legate alla task",
    },
    {
        title: "Pulsante del grafico",
        code: 3,
        target: ".viewChartRow",
        content: "Visualizza il grafico delle sessioni",
    },
    {
        title: "Pulsante presa dati",
        code: 4,
        target: ".dataCollectionButtonRow",
        content: "Inizia a registrare una sessione di lavoro",
    },
    {
        title: "Scheda di lavoro",
        code: 5,
        target: ".childrenSwitchButton",
        content:
            "Cambia la scheda di lavoro",
    },
    {
        title: "Barra di navigazione",
        code: 6,
        target: ".bottomTabBar",
        content: "Naviga facilmente tra le pagine",
    },

];

export const dataCollectionSessionsSteps = [
    {
        id: 'Data collection - Sessions List',
        code: 7,
        title: "Lista delle sessioni",
        target: ".sessionList",
        content: "Qui troverai tutte le sessioni registrate.",
        disableBeacon: true,
    },
    {
        title: "Pulsante del grafico",
        code: 8,
        target: ".viewChartRow",
        content: "Visualizza il grafico delle sessioni",
    },
];

export const dataCollectionChartSteps = [
    {
        id: 'Data collection - Chart',
        code: 9,
        title: "Filtro per sessione",
        target: ".sessionSelector",
        content: "Scegli quale sessione visualizzare nel grafico.",
        disableBeacon: true,
    },
    {
        title: "Lista degli utenti",
        code: 10,
        target: ".userSelector",
        content: "Filtra le sessioni per utente",
    },
    {
        title: "Strumenti del grafico",
        code: 11,
        target: ".apexcharts-toolbar",
        content: "Usa strumenti come lo zoom o il pan",
    },
    {
        title: "Esporta immagine del grafico",
        code: 12,
        target: ".pngExport",
        content: "Esporta il grafico come immagine PNG",
    },
    {
        title: "Esporta dati del grafico",
        code: 13,
        target: ".xlsxExport",
        content: "Esporta il grafico come documento XLSX",
    },
    {
        title: "Grafico dei dati",
        code: 14,
        target: ".chartDiv",
        content: "Qui vedrai i dati della sessione selezionata",
    },

];

export const dataCollectionDataCollectionSteps = [
    {
        id: 'Data collection - Data collection',
        title: "Lista degli step",
        code: 15,
        target: ".stepsList",
        content: "Qui troverai tutti gli step della task selezionata. Clicca su uno step per registrare un punteggio",
        disableBeacon: true,
    },
    {
        title: "Valore dello step",
        code: 16,
        target: ".stepValueDiv",
        content: "Scegli che punteggio dare ad ogni step",
    },
    {
        title: "Contenuto media",
        code: 17,
        target: ".modelingPlayerMedia",
        content: "Immagine o video legato allo step",
    },
    {
        title: "Fine sessione",
        code: 18,
        target: ".endSessionButton",
        content: "Clicca qui per terminare la sessione e tornare alla schermata precedente",
    },
    {
        title: "Comportamenti problema",
        code: 19,
        target: ".pbFabButton",
        content: "Clicca qui per segnalare un comportamento problema",
    },
];
export const taskListSteps = [
    {
        id: 'Tasks List',
        title: "Le task della scheda",
        code: 20,
        target: ".taskList",
        content: "Qui troverai tutte le task che hai creato",
        disableBeacon: true,
    },
    {
        title: "Video modeling",
        code: 21,
        target: ".playButton",
        content: "Riproduci la task con il video modeling",
    },
    {
        title: "Modifica la task",
        code: 22,
        target: ".editButton",
        content: "Clicca qui per modificare la task",
    },
    {
        title: "Aggiungi task",
        code: 23,
        target: ".addTask",
        content: "Clicca qui per creare una nuova task",
    },
];
export const taskEditorSteps = [
    {
        id: 'Task Editor',
        title: "Titolo della task",
        code: 24,
        target: ".taskTitle",
        content: "Qui potrai scegliere il titolo della task",
        disableBeacon: true,
    },
    {
        title: "Modalità di raccolta dati",
        code: 25,
        target: ".taskModeSelect",
        content: "Qui potrai selezionare la modalità con cui fare la presa dati",
    },
    {
        title: "Lista degli step",
        code: 26,
        target: ".stepsList",
        content: "Qui potrai vedere gli step aggiunti",
    },
    {
        title: "Riordina gli step",
        code: 27,
        target: ".reorderButton",
        content: "Clicca qui per cambiare l'ordine degli step",
    },
];
export const childrenSteps = [
    {
        id: 'Children list',
        title: "Lista delle schede",
        code: 28,
        target: ".childrenCard",
        content: "Qui troverai tutte le schede di lavoro create",
        disableBeacon: true,
    },
    {
        title: "Aggiungi scheda",
        code: 29,
        target: ".addChild",
        content: "Crea una nuova scheda",
    },
];

export const childDashboardSteps = [
    {
        id: 'Child Dashboard',
        title: "Informazioni generali",
        code: 30,
        target: ".dashboardInfo",
        content: "Puoi modificare le informazioni generali della scheda",
        disableBeacon: true,
    },
    {
        title: "Lista accessi",
        code: 31,
        target: ".dashboardAccessList",
        content: "Qui verranno mostrate le informazioni di chi ha accesso alla scheda, puoi condividere la scheda con l'apposito pulsante",
    },
    {
        title: "Comportamenti problema",
        code: 32,
        target: ".pbList",
        content: "Qui potrai gestire i comportamenti problema legati alla scheda",
    },
];
export const settingsSteps = [
    {
        id: 'Settings',
        title: "Informazioni profilo",
        code: 33,
        target: ".profileInfo",
        content: "Puoi modificare le informazioni del tuo profilo",
        disableBeacon: true,
    },
    {
        title: "Gestisci abbonamento",
        code: 34,
        target: ".manageProduct",
        content: "Qui potrai gestire l'abbonamento a Maestro",
    },
    {
        title: "I tuoi dispositivi",
        code: 35,
        target: ".manageDevices",
        content: "Scegli su quali dispositivi ricevere le notifiche",
    },
    {
        title: "Feedback e assistenza",
        code: 36,
        target: ".feedback",
        content: "Hai un dubbio o un problema, oppure vorresti condividere la tua esperienza su Maestro con noi? Contattaci con questo pulsante",
    },
];