import React from "react";
import { IonButton, IonButtons, IonContent, IonIcon, IonPage, } from "@ionic/react";
import { refresh, playBack, playForward, arrowBack } from "ionicons/icons";
import { connect } from 'react-redux';
import { Task } from "../store/reducers";
import { fromQueryToObjOfParams } from "common/queryUtils";
import { tasksServices } from "../store/services";
import './TaskPlayer.css';

type Props = {
	history: any,
}

type State = {
	currentStep: number,
	selectedTask: Task | null,

	isLoadingTask: boolean,
}

class TaskPlayer extends React.Component<Props, State> {

	constructor(props) {
		super(props);
		this.state = {
			currentStep: 0,
			selectedTask: null,

			isLoadingTask: false,
		}
	}

	componentDidMount() {
		const taskUuid = fromQueryToObjOfParams(this.props.history.location.search as string)["task"];
		//console.log("[TaskPlayer] Task uuid:", taskUuid);
		if (taskUuid) {
			this.setState({ isLoadingTask: true });
			tasksServices.fetchTask(taskUuid)
				.then(task => {
					this.setState({
						selectedTask: this.sanitizeTask(task),
						isLoadingTask: false,
					});
				})
				.catch(err => {
					console.error("[TaskPlayer] error fetching task:", err);
					this.setState({ isLoadingTask: false });
				})
		}
	}

	sanitizeTask(task: Task): Task {
		const taskToReturn = task;
		let steps = task.steps;
		steps = steps.filter(step => (step.videoGsUrl || step.imageGsUrl));
		taskToReturn.steps = steps;
		return taskToReturn
	}

	render() {
		return (

			<IonPage className='playerPage'>
				{
					this.state.selectedTask &&
					<IonContent class='content'>
						{
							this.state.selectedTask.steps[this.state.currentStep].imageGsUrl &&
							<img
								className="taskPlayerImg"
								src={this.state.selectedTask.steps[this.state.currentStep].imageGsUrl}
								alt={this.state.selectedTask.steps[this.state.currentStep].title}
							/>
						}

						{
							this.state.selectedTask.steps[this.state.currentStep].videoGsUrl &&
							<video
								id='video-player'
								preload="auto"
								autoPlay={true}
								webkit-playsinline="webkit-playsinline"
								className='videoPlayer'
								/* poster={this.state.selectedTask.steps[this.state.currentStep].preview} */
								playsInline={true}
							//muted={this.props.selectedAutonomy.settings.use_sound}
							>
								<source src={`${this.state.selectedTask.steps[this.state.currentStep].videoGsUrl}#t=0.1`} />
							</video>
						}

						<IonButton
							class='autonomyPlayerBackButton'
							expand='block' fill='solid'
							onClick={() => {
								this.props.history.goBack();
							}}
						>
							<IonIcon icon={arrowBack} className='autonomyPlayerBackButtonIcon' />
						</IonButton>

						<IonButtons class='controls'>
							<IonButton
								class={'controlButton'}
								expand='block'
								fill='solid'
								color="light"
								onClick={() => {
									this.setState({
										currentStep: this.state.currentStep - 1,
									});
								}}
								disabled={this.state.currentStep <= 0}
							>
								<IonIcon
									icon={playBack}
									color="medium"
								/>
							</IonButton>

							<IonButton
								class='controlButton'
								expand='block'
								fill='solid'
								color="light"
								disabled={!this.state.selectedTask.steps[this.state.currentStep].videoGsUrl}
								onClick={() => {
									if (this.state.selectedTask.steps[this.state.currentStep].videoGsUrl) {
										var video = document.getElementById('video-player') as HTMLVideoElement;
										video.currentTime = 0;
										video.play();
									}
								}}
							>
								<IonIcon
									icon={refresh}
									color="medium"
								/>
							</IonButton>

							<IonButton
								class={'controlButton'}
								expand='block'
								fill='solid'
								color="light"
								onClick={() => {
									this.setState({
										currentStep: this.state.currentStep + 1,
									});
								}}
								disabled={this.state.currentStep >= (this.state.selectedTask.steps.length - 1)}
							>
								<IonIcon
									icon={playForward}
									color="medium"
								/>
							</IonButton>

						</IonButtons>
					</IonContent>
				}

				{
					!this.state.selectedTask &&
					!this.state.isLoadingTask &&
					<div>
						<p>
							Nessuna task selezionata
						</p>
					</div>
				}
			</IonPage>
		)
	}
}

const mapStateToProps = state => {
	return {
	}
}

const mapDispatchToProps = dispatch => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskPlayer);