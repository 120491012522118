import React from "react";
import {
    IonContent, IonPage,
} from "@ionic/react";
import { connect } from 'react-redux';

type Props = {
    history: any,
}

type State = {
}

class HowTo extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <IonPage>
                <IonContent>
                    <iframe
                        title="Maestro tutorial"
                        src={`https://docs.google.com/viewer?url=${"https://estro.ai/wp-content/uploads/2021/10/maestro-tutorial-v1.pdf"}&embedded=true`}
                        style={{border: "none"}}
                        height="100%"
                        width="100%">
                    </iframe>
                </IonContent>
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HowTo);