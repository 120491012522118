import React from "react";
import {
    IonPage, IonTitle, IonContent, IonIcon, IonHeader,
    IonToolbar, IonButton, IonGrid, IonRow, IonImg, IonBackButton, IonButtons,
} from "@ionic/react";
import { happy } from "ionicons/icons";
import { connect } from 'react-redux';
import FamilyMenuTabBar from "modules/user/pages/FamilyMenuTabBar";
import { blogService } from "../store/services";
import { Post } from "../store/reducers";
import './BlogPostViewer.css';

type Props = {
    history: any,
    match: any
}

type State = {
    post: Post | null,
}

class BlogPostViewer extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            post: null,
        }
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params && this.props.match.params.blogPostId) {
            blogService.getPost(this.props.match.params.blogPostId)
                .then(post => {
                    this.setState({ post: post });
                })
                .catch((err) => {
                    //console.log('[BlogPostViewer] getting categories error:', err);
                })
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonButtons slot='start'>
                            <IonBackButton defaultHref="/" />
                        </IonButtons>
                        <IonTitle>{this.state.post ? this.state.post.title.rendered : 'Articolo'}</IonTitle>
                        <IonButton
                            shape='round'
                            slot='end'
                        >
                            <IonIcon
                                icon={happy}
                                size='large'
                            />
                        </IonButton>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className='blogPostViewerContentGrid'>
                        {
                            this.state.post &&
                            <IonRow>
                                <h2>
                                    {this.state.post ? this.state.post.title.rendered : 'Articolo'}
                                </h2>
                            </IonRow>
                        }

                        {
                            this.state.post &&
                            <IonRow
                                style={{
                                    marginBottom: '20px',
                                }}
                            >
                                <IonImg
                                    src={this.state.post._embedded["wp:featuredmedia"].length > 0 ? this.state.post._embedded["wp:featuredmedia"][0].source_url : null}
                                />
                            </IonRow>
                        }

                        {
                            this.state.post &&
                            <IonRow>
                                <div
                                    className='blogPostViewerPostContentDiv'
                                    dangerouslySetInnerHTML={{ __html: this.state.post.content.rendered }}
                                >
                                </div>
                            </IonRow>
                        }
                    </IonGrid>
                </IonContent>
                <FamilyMenuTabBar />
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogPostViewer);