import React from "react";
import {
    IonTitle, IonContent, IonHeader, IonToolbar,
    IonBackButton, IonButtons, IonCard, IonPage, IonInput, IonRow,
    IonGrid, IonList, IonButton, IonTextarea, IonIcon,
} from "@ionic/react";
import { connect } from 'react-redux';
import { Task } from "../store/reducers";
import { Child } from "modules/children/store/reducers";
import './AutonomySessionNotes.css';
import './TaskEditor.css';
import { micOutline } from "ionicons/icons";

type AutonomyProps = {
    history: any,
    currentChild: Child,
    currentAutonomy: Task,
}

type State = {
    autonomyTitle: string | null,
}

class AutonomySessionNotes extends React.Component<AutonomyProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            autonomyTitle: this.props.currentAutonomy ? this.props.currentAutonomy.title : null,
        }
    }

    componentDidMount() {
        //this.props.fetchStepsList(this.props.currentChild.id);
    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/autonomies/list" />
                        </IonButtons>

                        <IonTitle>Dashboard Autonomia</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonGrid className='AutonomySessionNotesGrid'>

                        <IonCard style={{ boxShadow: 'none' }}>
                            <IonGrid>
                                <IonRow>
                                    <IonInput
                                        //placeholder="Inserire il titolo dell'autonomia"
                                        class='autonomyTitleInput'
                                        value={this.state.autonomyTitle}
                                        readonly={true}
                                    //title cannot be changed from this page
                                    //onIonChange={(e) => this.setState({autonomyTitle: e.detail.value})}
                                    />
                                </IonRow>
                            </IonGrid>
                        </IonCard>
                        <IonList>
                            {/* {this.props.currentAutonomy.problem.map((problem)=>{
                            return(
                                <IonItem>{problem.title} {problem.frequency}</IonItem>
                            )
                        })} */}
                        </IonList>
                        <IonTextarea placeholder='Inserisci note testuali' />
                        <IonButtons className='AutonomySessionNotesButtons'>
                            <IonButton color='primary'>
                                {/* INSERT VOICE NOTE */}
                            Nota vocale
                            <IonIcon icon={micOutline} slot='end' />
                            </IonButton>
                        </IonButtons>
                        <IonButtons className='AutonomySessionNotesButtons'>
                            <IonButton onClick={() => {
                                //this.props.addNotes(sessionUuid,notes)
                            }}>
                                Aggiungi note
                        </IonButton>

                        </IonButtons>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        autonomiesList: state.autonomies.list,
        currentAutonomy: state.autonomies.currentAutonomy,
        currentChild: state.children.currentChild,
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutonomySessionNotes);