import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { fromQueryToObjOfParams } from "common/queryUtils";
import { barChartOutline, clipboardOutline } from "ionicons/icons";
import { Task, Session } from "modules/tasks/store/reducers";
import { tasksServices } from "modules/tasks/store/services";
import { Child } from "modules/children/store/reducers";
import FamilyMenuTabBar from "modules/user/pages/FamilyMenuTabBar";
import { User } from "modules/user/store/reducers";
import React from "react";
import { connect } from 'react-redux';
import { auth, database } from "firebase";
import { equalTo, get, orderByChild, query, ref } from "firebase/database";
import { getLocaleDateAndTime } from "../components/dataCollectionUtils";
import { isMobile } from "react-device-detect";
import './SessionList.css';
import TaskSkeleton from "../components/TaskSkeleton";
import Tutorial from "../../../common/In-App Tutorial/Tutorial";
import { dataCollectionSessionsSteps } from "common/tutorialSteps";

type Props = {
    history: any,
    currentChild: Child | null,
    userData: User,
}

type State = {
    child: Child | null,
    sessionList: Session[],
    task: Task | null,
    isLoadingSessions: boolean,
    showNotes: string,
}

class SessionList extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            child: null,
            sessionList: [],
            task: null,
            isLoadingSessions: false,
            showNotes: '',
        }
    }

    componentDidMount() {
        this.setState({ isLoadingSessions: true })
        const taskId = fromQueryToObjOfParams(this.props.history.location.search as string)["task"];
        if (taskId) {
            tasksServices.fetchTask(taskId)
                .then(task => {
                    this.setState({ task: task })
                    this.getSessionsForTask(task.uuid);
                    this.setState({ sessionList: this.state.sessionList })
                })
        }
    }

    async getSessionsForTask(taskUuid: string) {
        const dbRef = ref(database, `s/${this.props.currentChild.uuid}`);
        await get(query(dbRef, orderByChild("t"), equalTo(taskUuid)))
            .then(snapshot => {
                if (snapshot.exists()) {
                    let sessionIds = Object.keys(snapshot.val());
                    let tempSessionList: Session[] = []
                    for (let i = 0; i < sessionIds.length; i++) {
                        tempSessionList.push({
                            uuid: sessionIds[i],
                            data: snapshot.val()[sessionIds[i]].data,
                            notes: snapshot.val()[sessionIds[i]].n,
                            endedAt: snapshot.val()[sessionIds[i]].e,
                            startedAt: snapshot.val()[sessionIds[i]].s,
                            taskUuid: snapshot.val()[sessionIds[i]].t,
                        })
                    }
                    console.log(tempSessionList);

                    this.setState({ sessionList: tempSessionList, isLoadingSessions: false });
                }
                else {
                    this.setState({ isLoadingSessions: false })
                    return
                }
            })
    }

    getLocaleDateAndTime(date: string) { /* TO LOCALIZE THIS */
        const dateToFormat = new Date(date);
        const formattedDate = dateToFormat.toLocaleDateString("it-IT");
        let formattedTime = dateToFormat.toLocaleTimeString("it-IT");
        const timeArray = formattedTime.split(":");
        timeArray.pop()
        formattedTime = timeArray.join(":");
        return `${formattedDate} alle ${formattedTime}`; /* TO LOCALIZE THIS */
    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/data-collection/home" />
                        </IonButtons>
                        <Tutorial hidden={this.state.isLoadingSessions} steps={dataCollectionSessionsSteps} />

                        <IonTitle>Sessioni</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className='autonomySettingsContentGrid'>
                        <IonCard class='autonomySettingsAutonomiesCard sessionList'>
                            <IonCardHeader>
                                <IonCardTitle className='sessionListCardTitle'>
                                    {`Sessioni${this.state.task ? ` della task "${this.state.task.title}"` : ''}`} {/* TO LOCALIZE THIS */}
                                </IonCardTitle>
                                <IonCardSubtitle className="sessionListCardSubtitle">
                                    Qui è presente l’elenco di tutte le sessioni di lavoro. {/* TO LOCALIZE THIS */}
                                </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                                {
                                    this.state.isLoadingSessions &&
                                    <TaskSkeleton skeletonNumber={4} buttonNumber={1} />
                                }
                                {!this.state.isLoadingSessions &&
                                    this.state.sessionList.length > 0 &&
                                    (
                                        <IonList className='sessionListSessionList'>
                                            {this.state.sessionList.map((session, i) => {
                                                return (
                                                    <div
                                                        key={session.uuid}
                                                    >
                                                        <IonItem
                                                            key={session.uuid}
                                                            className='sessionListItem'
                                                        >
                                                            <IonGrid class='sessionListGrid'>
                                                                <IonRow>
                                                                    {getLocaleDateAndTime(session.endedAt * 1000)}
                                                                </IonRow>
                                                                {/* <IonRow
                                                                        class='sessionListNotes'
                                                                    >
                                                                        {session.notes}
                                                                    </IonRow> */}
                                                                <IonRow
                                                                    hidden={session.notes ? false : true}
                                                                    className="dataCollectionGraphButtonRow viewChartRow"
                                                                    onClick={() => {
                                                                        window.analytics.track({
                                                                            "event": "View Session Notes",
                                                                            "properties": {
                                                                                "category": "Notes",
                                                                                "label": auth.currentUser.uid,
                                                                            }
                                                                        })
                                                                        this.setState({ showNotes: this.state.showNotes === session.uuid ? '' : session.uuid })
                                                                    }}
                                                                >
                                                                    <IonButton
                                                                        style={{ height: '40px' }}
                                                                        fill="clear"
                                                                        shape="round"
                                                                    >
                                                                        <IonIcon
                                                                            size='large'
                                                                            icon={clipboardOutline}
                                                                        />

                                                                    </IonButton>
                                                                    <p className="sessionListButtonLabelParagraph">
                                                                        {this.state.showNotes === session.uuid ? 'Nascondi' : 'Visualizza'} <br />note  {/* TO LOCALIZE THIS */}
                                                                    </p>
                                                                </IonRow>
                                                                <IonRow
                                                                    className="dataCollectionGraphButtonRow viewChartRow"
                                                                    onClick={() => {
                                                                        window.analytics.track({
                                                                            "event": "View Chart",
                                                                            "properties": {
                                                                                "category": "Chart",
                                                                                "label": auth.currentUser.uid,
                                                                            }
                                                                        })
                                                                        this.props.history.push({
                                                                            pathname: `/data-collection/chart`,
                                                                            state: {
                                                                                taskUuid: this.state.task.uuid,
                                                                                sessionUuid: session.uuid,
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    <IonButton
                                                                        fill="clear"
                                                                        shape="round"
                                                                        style={{ height: '40px' }}

                                                                    >
                                                                        <IonIcon
                                                                            size='large'
                                                                            icon={barChartOutline}
                                                                        />

                                                                    </IonButton>
                                                                    <p className="sessionListButtonLabelParagraph">
                                                                        Visualizza <br />grafico  {/* TO LOCALIZE THIS */}
                                                                    </p>
                                                                </IonRow>

                                                            </IonGrid>
                                                        </IonItem>
                                                        {session.notes && this.state.showNotes === session.uuid &&
                                                            <div>
                                                                <IonLabel>Prima della sessione: <b>{session.notes.b}</b></IonLabel> <br />
                                                                <IonLabel>Durante la sessione: <b>{session.notes.d}</b></IonLabel><br />
                                                                <IonLabel>Dopo la sessione: <b>{session.notes.a}</b></IonLabel><br />
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </IonList>
                                    )
                                }
                                {!this.state.isLoadingSessions && this.state.sessionList.length === 0 &&
                                    (
                                        <IonGrid>
                                            {/* <IonRow class='autonomySettingsHintText'>
                                                    <IonText>Qui puoi creare o modificare una task.</IonText>
                                                </IonRow> */}

                                            <IonRow class='autonomySettingsHintText'>
                                                <IonText>
                                                    <p>Al momento non sono presenti sessioni di lavoro.</p>
                                                </IonText>
                                            </IonRow>
                                            <IonRow class='autonomySettingsHintText'>
                                                <IonText>
                                                    {isMobile ? 'Tocca il' : 'Clicca sul'} pulsante qui sotto per registrare una sessione.
                                                </IonText>
                                            </IonRow>
                                            <IonRow class='sessionListDataCollectionButton'>
                                                <IonButton className='sessionListDataCollectionButton' onClick={() => this.props.history.push(`/data-collection/collect?task=${this.state.task.uuid}`)}>
                                                    Vai alla presa dati
                                                </IonButton>
                                            </IonRow>

                                        </IonGrid>
                                    )
                                }
                            </IonCardContent>
                        </IonCard>

                    </IonGrid>
                </IonContent>

                <FamilyMenuTabBar />
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentChild: state.children.currentChild,
        userData: state.auth.userData
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionList);