import React from "react";
import {
    IonTitle, IonContent, IonHeader, IonToolbar, IonPage,
    IonFooter, IonTextarea, IonButton, IonIcon, IonGrid,
    IonButtons, IonBackButton, IonLoading, IonSpinner, IonImg,
    IonPopover,
} from "@ionic/react";
import { connect } from 'react-redux';
import { auth, functions, storage } from "firebase";
import { fromQueryToObjOfParams } from "common/queryUtils";
import { httpsCallable } from "firebase/functions";
import { attachOutline, closeOutline, playOutline, send } from "ionicons/icons";
import { fromDbChatToChat } from "../store/services";
import { Chat as ChatRoom, Message } from "../store/reducers";
import { childrenService } from "modules/children/store/services";
import { UserProfile } from "modules/user/store/reducers";
import { uploadBytes, ref as storageRef, getDownloadURL } from "firebase/storage";
import "./Chat.css";

type Props = {
    history: any,
}

type State = {
    userUuid: string | null,
    childUuid: string | null,
    childAccessList: UserProfile[],
    chat: ChatRoom | null,

    messageText: string | undefined,

    isLoadingChatRoom: boolean,
    isSendingMessage: boolean,
    showVideoPopover: boolean,
    videoUrl: string,
    imageUrl: string,
    isPlayingVideo: boolean,
    showImagePopover: boolean,
}

class Chat extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            userUuid: null,
            childUuid: null,
            childAccessList: [],
            chat: null,

            messageText: undefined,

            isLoadingChatRoom: false,
            isSendingMessage: false,
            showVideoPopover: false,
            videoUrl: '',
            isPlayingVideo: false,
            showImagePopover: false,
            imageUrl: '',
        };
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ userUuid: userData.uid });
                if (this.props.history.location && this.props.history.location.search) {
                    const childUuid = fromQueryToObjOfParams(this.props.history.location.search as string)["child"];
                    if (childUuid) {
                        this.setState({ childUuid: childUuid });
                        childrenService.getChildAccessList(childUuid)
                            .then(accessList => {
                                this.setState({ childAccessList: accessList });
                            })
                            .catch(err => {
                                console.error("[Chat] error getting child access list:", err);
                            });

                        this.setState({ isLoadingChatRoom: true });
                        const getChildChat = httpsCallable(functions, "getChildChat-getChildChat");
                        getChildChat({
                            childUuid: childUuid,
                        })
                        .then(res => {
                            if ((res.data as any).chat) {
                                this.setState({ chat: fromDbChatToChat(childUuid, (res.data as any).chat) });
                            }
                            else if ((res.data as any).error) {
                                console.error("[Chat] error returned by getChildChat function:", res.data);
                            }
                            else {
                                this.setState({ chat: fromDbChatToChat(childUuid, null) });
                            }

                            this.state.chat.messages.forEach(message => {
                                if (message.mediaGsUrl) {
                                    this.fromGSUrltoUrl(message.mediaGsUrl)
                                        .then(url => {
                                            message.mediaGsUrl = url
                                        })
                                }
                            });

                            this.setState({ isLoadingChatRoom: false });
                        })
                        .catch(err => {
                            console.error("[Chat] error calling getChildChat cloud function:", err);
                            this.setState({ isLoadingChatRoom: false });
                        });
                    }
                }
            }
            else {
                this.setState({ userUuid: null })
            }
        });
    }

    getSenderNameFromSenderUuid(senderUuid: string, accessesList: UserProfile[]): string {
        const filteredUsers = accessesList.filter(user => user.uuid === senderUuid);
        if (filteredUsers.length !== 1) {
            return "";
        }
        else {
            return filteredUsers[0].firstName ? filteredUsers[0].firstName : filteredUsers[0].email;
        }
    }

    fromGSUrltoUrl(gsUrl: string) {
        return new Promise<string>((resolve, reject) => {
            getDownloadURL(storageRef(storage, gsUrl))
                .then(url => {
                    //console.log('[fromGSUrltoUrl] service - url:', url);
                    resolve(url);
                })
                .catch(err => {
                    //console.log('[fromGSUrltoUrl] service error:', err);
                    reject(err);
                })
        });
    }

    uploadMedia(media: File | null | undefined, alreadyExists: boolean) {
        //console.log("USER DATA", auth.currentUser.uid);
        //console.log("UPLOAD MEDIA", this.state.currentStep);
        if (media && auth.currentUser.uid) {
            if (media.type.includes('image/')) {
                this.setState({ isSendingMessage: true });
                uploadBytes(storageRef(storage, `user-media/${auth.currentUser.uid}/chats/${media.name}`), media)
                .then(data => {
                    const postChildChatMessage = httpsCallable(functions, "postChildChatMessage-postChildChatMessage");
                    postChildChatMessage({
                        childUuid: this.state.childUuid,
                        messageText: undefined,
                        mediaGsUrl: data.ref.fullPath,
                    })
                    .then(res => {
                        this.setState({ isSendingMessage: false });
                        console.log("[Chat] child chat message:", res.data);
                        if ((res.data as any).message) {
                            const tempChat = Object.assign({}, this.state.chat);
                            tempChat.messages = [((res.data as any).message as Message), ...tempChat.messages];
                            this.setState({
                                chat: tempChat,
                                messageText: undefined,
                            });
                        }
                        else if ((res.data as any).error) {
                            console.error("[Chat] error returned by postChildChatMessage function:", res.data);
                        }
                        this.setState({ isSendingMessage: false });
                    })
                    .catch(err => {
                        console.error("[Chat] error calling postChildChatMessage cloud function:", err);
                        this.setState({ isSendingMessage: false });
                    });
                })
                .catch(err => {
                    console.error("[Chat] error uploading file", err);
                    this.setState({ isSendingMessage: false });
                });
            }
            else if (media.type.includes('video/') && media.type.includes('mp4')) {
                this.setState({ isSendingMessage: true });
                uploadBytes(storageRef(storage, `user-media/${auth.currentUser.uid}/chats/${media.name}`), media)
                .then(data => {
                    const postChildChatMessage = httpsCallable(functions, "postChildChatMessage-postChildChatMessage");
                    postChildChatMessage({
                        childUuid: this.state.childUuid,
                        messageText: undefined,
                        mediaGsUrl: data.ref.fullPath,
                    })
                    .then(res => {
                        this.setState({ isSendingMessage: false });
                        console.log("[Chat] child chat message:", res.data);
                        if ((res.data as any).message) {
                            const tempChat = Object.assign({}, this.state.chat);
                            tempChat.messages = [((res.data as any).message as Message), ...tempChat.messages];
                            this.setState({
                                chat: tempChat,
                                messageText: undefined,
                            });
                        }
                        else if ((res.data as any).error) {
                            console.error("[Chat] error returned by postChildChatMessage function:", res.data);
                        }
                        this.setState({ isSendingMessage: false });
                    })
                    .catch(err => {
                        console.error("[Chat] error calling postChildChatMessage cloud function:", err);
                        this.setState({ isSendingMessage: false });
                    });
                })
                .catch(err => {
                    console.error("[Chat] error uploading file", err);
                    this.setState({ isSendingMessage: false });
                })
            }
            else {
                alert('Puoi caricare solo immagini e video - Formati supportati: PNG, JPG, GIF, MP4') /* TO BE LOCALIZED */
            }
        }
    }


    render() {
        return (

            <IonPage>

                <IonHeader mode="md">
                    <IonToolbar>
                        <IonButtons slot='start'>
                            <IonBackButton defaultHref="/" />
                        </IonButtons>
                        <IonTitle>
                            Chat {/* TO LOCALIZE THIS */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonGrid className="chatMainGrid">
                        {
                            this.state.isLoadingChatRoom &&
                            <div className="chatMessagesLoadingDiv">
                                <IonSpinner />
                            </div>
                        }

                        {
                            !this.state.isLoadingChatRoom &&
                            <div
                                className="chatMessagesDiv"
                            >
                                {
                                    this.state.chat &&
                                    this.state.chat.messages.map(message => {
                                        return (
                                            <div
                                                key={message.uuid}
                                                className="chatMessageContainer"
                                                style={{ justifyContent: message.sentBy === this.state.userUuid ? "flex-end" : "flex-start" }}
                                            >
                                                <div
                                                    className="chatMessageSenderAndBubbleDiv"
                                                    style={{ alignItems: message.sentBy === this.state.userUuid ? "flex-end" : "flex-start" }}
                                                >
                                                    {
                                                        this.state.userUuid !== message.sentBy &&
                                                        <p className="chatMessageBubbleSenderName">
                                                            {this.getSenderNameFromSenderUuid(message.sentBy, this.state.childAccessList)}
                                                        </p>
                                                    }

                                                    {
                                                        message.text &&
                                                        <div className="chatMessageBubble">
                                                            <p className="chatMessageBubbleParagraph">
                                                                {message.text}
                                                            </p>
                                                        </div>
                                                    }

                                                    {
                                                        message.mediaPublicUrl && message.format === 'mp4' &&
                                                        <div className="chatMessageBubble">

                                                            <video
                                                                onClick={(e) => {
                                                                    this.setState({ showVideoPopover: true, videoUrl: message.mediaPublicUrl })
                                                                }}
                                                                src={`${message.mediaPublicUrl}#t=0.001`} className="chatMessageBubbleVideo"
                                                            />
                                                            <IonIcon
                                                                icon={playOutline}
                                                                onClick={(e) => {
                                                                    this.setState({ showVideoPopover: true, videoUrl: message.mediaPublicUrl })
                                                                }}
                                                                size="large"
                                                                className='chatPopoverIcon'
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        message.mediaPublicUrl && message.format !== 'mp4' &&
                                                        <div className="chatMessageBubble">
                                                            <IonImg onClick={(e) => {
                                                                this.setState({ showImagePopover: true, imageUrl: message.mediaPublicUrl })
                                                            }} src={message.mediaPublicUrl} className="chatMessageBubbleVideo">
                                                            </IonImg>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </IonGrid>

                    <IonPopover
                        translucent={true}
                        mode="md"
                        className="chatPopover"
                        backdropDismiss
                        isOpen={ this.state.showVideoPopover }
                        onDidDismiss={() => {
                            this.setState({
                                videoUrl: '',
                                showVideoPopover: false
                            });
                        }}
                    >
                        <div
                            className="chatPopoverVideoDiv"
                            onClick={() => {
                                this.setState({ showVideoPopover: false });
                            }}
                        >
                            <video
                                controls
                                className="chatPopoverVideo"
                                onEnded={() => {
                                    this.setState({ isPlayingVideo: false })
                                }}
                                onClick={(e) => {
                                    if (this.state.isPlayingVideo) {
                                        e.currentTarget.pause();
                                    }
                                    else {
                                        e.currentTarget.play();
                                    }
                                    this.setState({ isPlayingVideo: !this.state.isPlayingVideo })
                                }}
                                src={`${this.state.videoUrl}#t=0.001`}
                                
                            />
                        </div>

                        <IonIcon
                            className="chatMediaPopoverCloseIcon"
                            onClick={() => {
                                this.setState({ showVideoPopover: false });
                            }}
                            icon={closeOutline}
                            size='large'
                        />

                    </IonPopover>

                    <IonPopover
                        translucent
                        className="chatPopover"
                        mode="md"
                        backdropDismiss
                        isOpen={this.state.showImagePopover}
                        onDidDismiss={() => {
                            this.setState({
                                imageUrl: '',
                                showImagePopover: false
                            });
                        }}
                    >
                        <div
                            className="chatPopoverImageDiv"
                            onClick={() => {
                                this.setState({ showImagePopover: false });
                            }}
                        >
                            <img
                                className="chatPopoverImage"
                                id='imageFullscreen'
                                src={this.state.imageUrl}
                            />
                        </div>

                        <IonIcon
                            className="chatMediaPopoverCloseIcon"
                            onClick={() => {
                                this.setState({ showImagePopover: false });
                            }}
                            icon={closeOutline}
                            size='large'
                        />

                    </IonPopover>

                </IonContent>

                <IonFooter>
                    <IonToolbar>
                        <div className="chatFooterToolbarDiv">
                            <IonTextarea
                                placeholder="Messaggio" /* TO LOCALIZE THIS */
                                value={this.state.messageText}
                                onIonChange={e => {
                                    this.setState({ messageText: e.detail.value ? e.detail.value : undefined });
                                }}
                            />
                            <IonButton
                                onClick={() => {
                                    this.setState({}, () => {
                                        (document.getElementById("chatFileInput") as HTMLInputElement).click();
                                    });
                                }}
                            >
                                <IonIcon icon={attachOutline} />
                                Media {/* TO BE LOCALIZED */}
                            </IonButton>

                            <IonButton
                                disabled={
                                    !this.state.messageText ||
                                    this.state.messageText.trim().length < 1
                                }
                                onClick={() => {
                                    this.setState({ isSendingMessage: true });
                                    const postChildChatMessage = httpsCallable(functions, "postChildChatMessage-postChildChatMessage");
                                    postChildChatMessage({
                                        childUuid: this.state.childUuid,
                                        messageText: this.state.messageText ? (this.state.messageText.trim().length === 0 ? undefined : this.state.messageText.trim()) : undefined,

                                    })
                                        .then(res => {
                                            this.setState({ isSendingMessage: false });
                                            console.log("[Chat] child chat message:", res.data);
                                            if ((res.data as any).message) {
                                                const tempChat = Object.assign({}, this.state.chat);
                                                tempChat.messages = [((res.data as any).message as Message), ...tempChat.messages];
                                                this.setState({
                                                    chat: tempChat,
                                                    messageText: undefined,
                                                });
                                            }
                                            else if ((res.data as any).error) {
                                                console.error("[Chat] error returned by postChildChatMessage function:", res.data);
                                            }
                                        })
                                        .catch(err => {
                                            console.error("[Chat] error calling postChildChatMessage cloud function:", err);
                                            this.setState({ isSendingMessage: false });
                                        });
                                }}
                            >
                                <IonIcon
                                    icon={send}
                                />
                            </IonButton>
                        </div>
                    </IonToolbar>
                </IonFooter>

                <input
                    style={{ position: "absolute", top: "-100px" }}
                    id="chatFileInput"
                    type={"file"}
                    accept="image/png,image/gif,image/jpeg,video/mp4"
                    onChange={e => {
                        console.log("[Chat] on media input change:", e);

                        this.uploadMedia((e.nativeEvent.target as HTMLInputElement).files?.item(0), false);
                    }}
                />

                <IonLoading
                    isOpen={this.state.isSendingMessage}
                />
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);