import { appConstant } from './constants';

export interface LoadingElem {
    key: string,
}

export interface ApplicationStore {
    message: {
        show: boolean,
        title: string,
        text: string,
    },
    loading: LoadingElem[],
}

const initialApplicationState: ApplicationStore = { 
    /* loading: [],  */
    message: {
        show: false,
        title: '',
        text: '',
    },
    loading: [],
};

const Application = (state = initialApplicationState, action) => {
  switch (action.type) {

    case appConstant.SHOW_LOADING:
      return {
        ...state,
        loading: [...state.loading, action.key]
      };

    case appConstant.HIDE_LOADING:
      return {
        ...state,
        loading: state.loading.filter(key => key !== action.key)
      };


    case appConstant.SHOW_MESSAGE:
      return {
        ...state,
        message: {show: true, title: action.title, text: action.text}
      };

    case appConstant.HIDE_MESSAGE:
      return {
        ...state,
        message: { show: false, title: '', text: ''}
      };

    default:
      return state
  }
}

export default Application;
