import { IonIcon } from "@ionic/react";
import { star, starOutline } from "ionicons/icons";
import React from "react";


type Props = {
    onInteraction: (value: number) => void,
}

type State = {
    vote: number,
}

class VotesStep extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            vote: 0,
        }
    }

    render() {
        return (
            <div className="stepValueDiv" /* key={i} */ onMouseOver={() => {

                //this.setState({ stepKey: i })
            }}>
                <IonIcon
                    onClick={() => {
                        if (this.props.onInteraction) {
                            this.props.onInteraction(2);
                        }
                        this.setState({ vote: 2 });

                    }}
                    /* onMouseOver={() => this.setState({ stepVote: 2 })}
                    onMouseOut={() => this.setState({ stepVote: 0 })} */
                    icon={this.state.vote > 0 ? star : starOutline}
                    style={this.state.vote > 0 ? { color: '#ffe100' } : {}}
                    size='large'
                />
                <IonIcon
                    onClick={() => {
                        if (this.props.onInteraction) {
                            this.props.onInteraction(4);
                        }
                        this.setState({ vote: 4 });
                    }}
                    /* onMouseOver={() => this.setState({ stepVote: 4 })}
                    onMouseOut={() => this.setState({ stepVote: 0 })} */
                    icon={this.state.vote > 2 ? star : starOutline}
                    style={this.state.vote > 2 ? { color: '#ffe100' } : {}}
                    size='large'
                />
                <IonIcon
                    onClick={() => {
                        if (this.props.onInteraction) {
                            this.props.onInteraction(6);
                        }
                        this.setState({ vote: 6 });
                    }}
                    /* onMouseOver={() => this.setState({ stepVote: 6 })}
                    onMouseOut={() => this.setState({ stepVote: 0 })} */
                    icon={this.state.vote > 4 ? star : starOutline}
                    style={this.state.vote > 4 ? { color: '#ffe100' } : {}}
                    size='large'
                />

                <IonIcon
                    onClick={() => {
                        if (this.props.onInteraction) {
                            this.props.onInteraction(8);
                        }
                        this.setState({ vote: 8 });
                    }}
                    /* onMouseOver={() => this.setState({ stepVote: 8 })}
                    onMouseOut={() => this.setState({ stepVote: 0 })} */
                    icon={this.state.vote > 6 ? star : starOutline}
                    style={this.state.vote > 6 ? { color: '#ffe100' } : {}}
                    size='large'
                />
                <IonIcon
                    onClick={() => {
                        if (this.props.onInteraction) {
                            this.props.onInteraction(10);
                        }
                        this.setState({ vote: 10 });
                    }}
                    /* onMouseOver={() => this.setState({ stepVote: 10 })}
                    onMouseOut={() => this.setState({ stepVote: 0 })} */
                    icon={this.state.vote > 8 ? star : starOutline}
                    style={this.state.vote > 8 ? { color: '#ffe100' } : {}}
                    size='large'
                />
            </div>
        );
    }
}

export default VotesStep;