import { isPlatform } from "@ionic/core";
import { IonButton, IonPopover } from "@ionic/react";
import React from "react";
import { connect } from 'react-redux';
import "./GetPremiumPopover.css";

type Props = {
    history: any,

    isOpen: boolean,

    onGetPremiumButtonPressed?: () => void,
    onDidDismiss?: () => void,
}

type State = {
}

class GetPremiumPopover extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <IonPopover
                isOpen={this.props.isOpen}
                className="getPremiumPopover"
                onDidDismiss={() => {
                    if(this.props.onDidDismiss) {
                        this.props.onDidDismiss();
                    }
                }}
            >
                <div className="getPremiumPopoverMainDiv">
                    <p className="getPremiumPopoverTextParagraph">
                        Per completare questa azione è necessario avere una licenza premium attiva. {/* TO BE LOCALIZED */}
                    </p>

                    {
                        (!isPlatform("capacitor") && !isPlatform("cordova")) &&
                        <div className="getPremiumPopoverButtonDiv">
                            <IonButton
                                className="getPremiumPopoverButton"
                                onClick={() => {
                                    if(this.props.onGetPremiumButtonPressed) {
                                        this.props.onGetPremiumButtonPressed();
                                    }
                                }}
                            >
                                Dimmi di più {/* TO BE LOCALIZED */}
                            </IonButton>
                        </div>
                    }

                </div>
            </IonPopover>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetPremiumPopover);