export const autonomiesConstants = {
    FETCH_AUTONOMIES_SETTINGS: "FETCH_AUTONOMIES_SETTINGS",
    UPDATE_AUTONOMIES_SETTINGS: "UPDATE_AUTONOMIES_SETTINGS",
    FETCH_AUTONOMIES_LIST: "FETCH_AUTONOMIES_LIST",
    FETCH_STEPS_LIST: "FETCH_STEPS_LIST",
    CREATE_STEP: "CREATE_STEP",
    CREATE_AUTONOMY: "CREATE_AUTONOMY",
    DELETE_AUTONOMY: 'DELETE_AUTONOMY',
    SELECT_AUTONOMY: 'SELECT_AUTONOMY',
    CLEAR_CURRENT_AUTONOMY: 'CLEAR_CURRENT_AUTONOMY',
    UPDATE_CURRENT_AUTONOMY: 'UPDATE_CURRENT_AUTONOMY',
    UPDATE_AUTONOMY_COVER: 'UPDATE_AUTONOMY_COVER',
    UPDATE_STEP_TITLE: 'UPDATE_STEP_TITLE',
    UPDATE_STEP_VIDEO: 'UPDATE_STEP_VIDEO',
    START_SESSION:'START_SESSION',
    STEP_INTERACTION:'STEP_INTERACTION',



};