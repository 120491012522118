import {
    IonBackButton, IonButton, IonButtons, IonContent,
    IonGrid, IonHeader, IonLabel, IonLoading, IonPage, IonSelect,
    IonSelectOption, IonTitle, IonToast, IonToolbar
} from "@ionic/react";
import React from "react";
import { fromQueryToObjOfParams } from "common/queryUtils";
import { autonomiesActions } from "modules/tasks/store/actions";
import { Task, Session, Step, SessionData } from "modules/tasks/store/reducers";
import { tasksServices } from "modules/tasks/store/services";
import { Child } from "modules/children/store/reducers";
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { getLocaleDateAndTime } from "../components/dataCollectionUtils";
import { pbServices } from "modules/problem-behaviour/store/services";
import { PBInteraction, ProblemBehavior } from "modules/problem-behaviour/store/reducers";
import { get, ref as dbRef } from "@firebase/database";
import { equalTo, orderByChild, query } from "firebase/database";
import { auth, database, functions, storage } from "firebase";
import ApexCharts from 'apexcharts';
import { httpsCallable } from "firebase/functions";
import { getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage';
import { childrenService } from "modules/children/store/services";
import { UserProfile } from "modules/user/store/reducers";
import './TaskChart.css';
import GetPremiumPopover from "modules/purchase/components/GetPremiumPopover";
import { dataCollectionChartSteps } from "common/tutorialSteps";
import Tutorial from "../../../common/In-App Tutorial/Tutorial";

type Props = {
    history: any,
    currentChild: Child,
}

type State = {
    chartData: any | null,
    selectedTask: Task | null,
    stepsList: Step[],
    sessions: Session[],
    selectedSessionUuid: string | null,
    selectedSession: Session | null,
    interactionList: SessionData[],
    showExportChartActionSheet: boolean,
    pbInteractions: PBInteraction[],
    pbList: ProblemBehavior[],

    showExportDataRequestedToast: boolean,
    showDownloadCompleteToast: boolean,
    xlsxDownloadUrl: string | null,
    base64Chart: string,
    chartImgUrl: string | null,

    isLoadingData: boolean,
    accessList: UserProfile[],
    selectedUser: string,

    showPayPopover: boolean,
}

class TaskChart extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            selectedTask: null,
            stepsList: [],
            chartData: {
                labels: [],
                datasets: [{ data: [] }]
            },
            sessions: [],
            selectedSessionUuid: null,
            selectedSession: null,
            interactionList: [],
            showExportChartActionSheet: false,
            pbInteractions: [],
            pbList: [],

            showExportDataRequestedToast: false,
            showDownloadCompleteToast: false,
            xlsxDownloadUrl: null,
            base64Chart: '',
            chartImgUrl: null,

            isLoadingData: false,
            accessList: [],
            selectedUser: 'all_users',

            showPayPopover: false,
        }
    }

    componentDidMount() {
        if (this.props.history.location.state) {
            setTimeout(() => {
                this.setState({ selectedSessionUuid: this.props.history.location.state.sessionUuid })
            }, 500);
        }
        this.getChildAccessList(this.props.currentChild.uuid)
        this.setState({ isLoadingData: true })
        this.processData();
    }

    fetchPbList(childUuid: string) {
        pbServices.fetchPbList(childUuid)
            .then(pbList => {
                this.setState({ pbList: pbList });
                //console.log("PB LIST", pbList);

            })
            .catch(err => {
                //console.log("[PBList] error fetching pb list:", err)
            })
    }

    async getChildAccessList(childUuid: string) {
        childrenService.getChildAccessList(childUuid)
            .then(accessList => {
                this.setState({ accessList: accessList });
            })
            .catch(err => {
                //console.log("[ChildDashboard] error getting child access list:", err);
            });
    }


    async getSessions(childUuid: string, taskId: string) {
        //query to FIREBASE DB
        let tempSessions: Session[] = []
        const sessionsDbRef = dbRef(database, `s/${childUuid}/`)
        //pass the child uuid
        //get all sessions
        const snapshot = await get(query(sessionsDbRef, orderByChild("t"), equalTo(taskId)))
        if (snapshot.val() !== null) {
            const tempSessionsIds = Object.keys(snapshot.val());
            let sessions = []
            for (let i = 0; i < tempSessionsIds.length; i++) {
                // //console.log("test for id #", i, snapshot.val()[tempSessionsIds[i]]);
                let session = snapshot.val()[tempSessionsIds[i]];
                //console.log("Session #", i, session);
                session.uuid = tempSessionsIds[i];

                if (session.u === this.state.selectedUser || this.state.selectedUser === 'all_users') {
                    sessions.push(session)
                }
            }

            //console.log("filtered sessions", sessions)
            //console.log("SELECTED USER", this.state.selectedUser);

            for (let i = 0; i < sessions.length; i++) {
                //console.log("filtered session #", i, sessions[i]);
                tempSessions.push({
                    uuid: sessions[i].uuid,
                    data: sessions[i].d,
                    notes: sessions[i].d,
                    endedAt: sessions[i].e,
                    startedAt: sessions[i].s,
                    taskUuid: sessions[i].t,
                });
            }
        }

        this.setState({ sessions: tempSessions });
        //console.log("[TaskChart] session uuid", this.state.selectedSessionUuid);
        //console.log("session list ", this.state.sessions);

        if (!this.state.selectedSessionUuid && this.state.sessions.length > 0) {
            this.setState({ selectedSessionUuid: this.state.sessions[0].uuid });
            //console.log("ASSIGNING NEW SESSION UUID", this.state.sessions[0].uuid);

        }
    }

    setChartBackground(chart: HTMLCanvasElement) {
        const canvasWithBackground = document.createElement('canvas');
        canvasWithBackground.width = chart.width;
        canvasWithBackground.height = chart.height;

        const ctx = canvasWithBackground.getContext('2d')!;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.drawImage(chart, 0, 0);

        return canvasWithBackground;
    }


    _base64ToArrayBuffer(base64) {
        var binary_string = atob(base64.split(',')[1]);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    fromGSUrltoUrl(gsUrl: string) {
        return new Promise<string>((resolve, reject) => {
            getDownloadURL(storageRef(storage, gsUrl))
                .then(url => {
                    //console.log('[fromGSUrltoUrl] service - url:', url);
                    resolve(url);
                })
                .catch(err => {
                    //console.log('[fromGSUrltoUrl] service error:', err);
                    reject(err);
                })
        });
    }

    uploadMedia(media: any) {
        if (media) {
            //console.log("Media", JSON.stringify(media));

            uploadBytes(storageRef(storage, `users-media/${auth.currentUser.uid}/charts/sessions/${this.state.selectedSessionUuid}.png/`), media)
                .then(data => {
                    //console.log("[Utils] upload success:", data.ref);
                    this.fromGSUrltoUrl(data.ref.fullPath)
                        .then(url => {
                            this.setState({ chartImgUrl: url })
                            /* if (isMobile) {
                                window.open(url, '_system');
                            } */
                        })
                })
                .catch(err => {
                    //console.log("[Utils] error uploading file", err);
                })
        }
    }


    exportChart() {
        // let fileName = `${this.props.currentChild.firstName}-${this.state.selectedTask.title}.png`;
        // let input = window.document.getElementsByClassName("chartChart")[0] as HTMLCanvasElement;
        // let canvasWithBackground = this.setChartBackground(input);
        // //console.log("BASE64IMAGE:", this.state.base64Chart);
        // let file = this.dataURLtoFile(this.state.base64Chart, 'ciao.png');
        let file = this._base64ToArrayBuffer(this.state.base64Chart);
        // //console.log("FILEE: ", JSON.stringify(file));
        this.uploadMedia(file)

        if (isMobile) {
            try {
                this.uploadMedia(file);
            }
            catch (err) {
                //console.log("[TaskChart] error writing image:", err);
            }
        }
        else {
            const link = document.createElement('a');
            link.download = "grafico.png";
            link.href = this.state.base64Chart;
            link.click();
            link.remove();
        }
    }

    async processData() {
        let taskId;
        //then loop through sessions and get the data into the chart
        pbServices.fetchPbList(this.props.currentChild.uuid)
            .then(pbList => {
                this.setState({ pbList: pbList }, () => {
                    if (this.props.history.location.state) {
                        taskId = this.props.history.location.state.taskUuid
                    }
                    else {
                        taskId = fromQueryToObjOfParams(this.props.history.location.search as string)["task"];
                    }
                    //console.log("[TaskChart] PB list state:", this.state.pbList);
                    this.setState({
                        chartData: {
                            labels: [],
                            datasets: [
                                {
                                    label: 'Acquisizione target',
                                    data: [],
                                    borderColor: "#00000",
                                    borderWidth: "3",
                                    borderRadius: 30,
                                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                },
                                {
                                    label: 'Comportamenti problema',
                                    data: [],
                                    borderColor: "#00000",
                                    borderWidth: "3",
                                    borderRadius: 30,
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                            ]
                        },
                    }, () => {
                        //console.log('[TaskChart] INITIAL STATE', this.state.chartData.datasets[0].data);
                    })

                    if (taskId) {
                        // if (this.state.sessions.length !== 0 && this.state.selectedSessionUuid !== '') {
                        tasksServices.fetchTask(taskId)
                            .then(async task => {
                                this.setState({
                                    selectedTask: task,
                                    stepsList: task.steps,
                                });
                                await this.getSessions(this.props.currentChild.uuid, taskId);
                                if (this.state.sessions.length === 0) {
                                    this.setState({ isLoadingData: false })
                                    return
                                }
                                //console.log('SESSION LIST STATE', this.state.sessions);
                                //console.log("SELECTED SESSION uuid", this.state.selectedSessionUuid);
                                let currentSession = this.state.sessions.filter(session => session.uuid === this.state.selectedSessionUuid)[0]
                                //console.log("CURRENT SESSION", currentSession);

                                this.setState({ selectedSessionUuid: currentSession.uuid })
                                // //console.log("SESSIONDATA", currentSession);
                                let pbStepNames = [];
                                let sessionDataIds = Object.keys(currentSession.data)
                                for (let i = 0; i < sessionDataIds.length; i++) {
                                    if (!currentSession.data[sessionDataIds[i]].p)
                                        this.state.chartData.labels.push(this.state.stepsList.filter(step => step.uuid === currentSession.data[sessionDataIds[i]].s)[0].title)
                                    else {
                                        pbStepNames.push(
                                            {
                                                step: this.state.stepsList.filter(step => step.uuid === currentSession.data[sessionDataIds[i]].s)[0].title,
                                                title: this.state.pbList.filter(pb => pb.uuid === currentSession.data[sessionDataIds[i]].p)[0]?.name ? this.state.pbList.filter(pb => pb.uuid === currentSession.data[sessionDataIds[i]].p)[0]?.name : '',
                                                description: currentSession.data[sessionDataIds[i]].v,
                                                active: this.state.pbList.filter(pb => pb.uuid === currentSession.data[sessionDataIds[i]].p)[0]?.active ? this.state.pbList.filter(pb => pb.uuid === currentSession.data[sessionDataIds[i]].p)[0]?.active : true,
                                            }
                                        )
                                    }
                                    if (!currentSession.data[sessionDataIds[i]].p) {
                                        this.state.chartData.datasets[0].data.push(currentSession.data[sessionDataIds[i]].v);
                                    }
                                    //console.log("Data:", this.state.chartData.datasets[0].data);

                                }
                                //console.log("PB STEP NAMES", pbStepNames);
                                let voteMode: boolean = false;
                                this.state.chartData.datasets[0].data.forEach(stepValue => {
                                    if (stepValue !== 0 && (stepValue === 2 || stepValue === 4 || stepValue === 6 || stepValue === 8 || stepValue === 10))
                                        voteMode = true
                                })
                                let _this = this;
                                var options = {
                                    annotations: {
                                        xaxis: [
                                            {}
                                        ]
                                    },
                                    chart: {
                                        toolbar: {
                                            tools: {
                                                download: false,
                                                customIcons: [{
                                                    title: 'Esporta PNG',
                                                    icon: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? `<img class='pngExport' src='../assets/pngExportDarkMode.svg' width="30"></img>` : `<img src='../assets/pngExport.svg' width="30"></img>`,
                                                    click: function () {
                                                        _this.exportChart()
                                                    },
                                                    appendTo: 'right' // left / top means the button will be appended to the left most or right most position
                                                },
                                                {
                                                    title: 'Esporta XLSX',
                                                    icon: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? `<img class='xlsxExport' src='../assets/xlsxExportDarkMode.svg' width="30"></img>` : `<img src='../assets/xlsxExport.svg' width="30"></img>`,
                                                    click: function () {
                                                        _this.exportXlsx()
                                                    },
                                                    appendTo: 'right' // left / top means the button will be appended to the left most or right most position
                                                }]

                                            }
                                        },
                                        width: window.innerWidth,
                                        height: window.innerHeight,
                                        type: 'area',
                                        stroke: {
                                            curve: 'smooth',
                                        },
                                        class: 'chartToolbar'
                                    },
                                    series: [
                                        {
                                            name: 'Acquisizione target',
                                            data: this.state.chartData.datasets[0].data
                                        },
                                        /* {
                                            name: 'Comportamenti problema',
                                            data: this.state.chartData.datasets[1].data,
                                        } */
                                    ],
                                    xaxis: {
                                        categories: this.state.chartData.labels
                                    },

                                }
                                pbStepNames.forEach(pbStep => {
                                    if (pbStep.active) {
                                        options.annotations.xaxis.push({

                                            x: pbStep.step,
                                            borderColor: '#775DD0',
                                            label: {
                                                style: {
                                                    color: '#000',
                                                },
                                                text: pbStep.title + " : " + pbStep.description
                                            }

                                        })
                                    }
                                })

                                var chart = new ApexCharts(document.querySelector("#chart"), options);
                                chart.render();


                                chart.updateOptions({
                                    chart: {
                                        animations: {
                                            enabled: false
                                        },
                                        width: window.innerWidth,
                                        height: window.innerHeight - 120,
                                        type: 'bar',
                                    },
                                    series: [
                                        {
                                            name: 'Acquisizione target',
                                            data: this.state.chartData.datasets[0].data
                                        },
                                        /* {
                                            name: 'Comportamenti problema',
                                            data: this.state.chartData.datasets[1].data
                                        } */
                                    ],
                                    xaxis: {
                                        categories: this.state.chartData.labels
                                    },
                                    yaxis: {
                                        max: voteMode ? 10 : 100
                                    }
                                })
                                chart.dataURI()
                                    .then((response: any) => {
                                        this.setState({ base64Chart: response.imgURI, isLoadingData: false });
                                    })

                            })

                        // }
                    }
                });

            })
            .catch(err => {
                //console.log("[PBList] error fetching pb list:", err)
            })

    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/data-collection/home" />
                        </IonButtons>
                        <Tutorial hidden={this.state.isLoadingData} steps={dataCollectionChartSteps} />

                        {/* <IonTitle>{this.props.currentAutonomy ? this.props.currentAutonomy.title: 'Nuova autonomia'}</IonTitle> */}
                        <IonTitle>
                            Grafico della task "{this.state.selectedTask ? this.state.selectedTask.title : ''}"
                        </IonTitle>

                        {/* <IonButton
                            className='chartExportButton'
                            slot='end'
                            fill='clear'
                            color='dark'
                            onClick={() => {
                                this.exportXlsx();

                            }}
                        >
                            {
                                isMobile &&
                                <IonIcon
                                    style={{ fontSize: '30px' }}
                                    icon={downloadOutline}
                                />
                            }
                            {
                                !isMobile &&
                                <>Esporta dati</>
                            }
                        </IonButton> */}

                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    {
                        this.state.isLoadingData &&
                        <IonLoading
                            isOpen={true}
                            backdropDismiss={false}
                            message='Sto caricando i dati'
                        />

                    }

                    {
                        this.state.chartImgUrl &&
                        <IonGrid className="taskChartDocumentReadyGrid">
                            <div
                                className="taskChartDocumentReadyDiv"
                            >
                                <IonButton
                                    className="taskChartDocumentReadyButton"
                                    onClick={() => {
                                        window.open(this.state.chartImgUrl, "_system");
                                    }}
                                >
                                    Grafico pronto.<br />
                                    Clicca qui per il download.
                                </IonButton>
                            </div>
                        </IonGrid>
                    }

                    {
                        this.state.xlsxDownloadUrl &&
                        <IonGrid className="taskChartDocumentReadyGrid">
                            <div
                                className="taskChartDocumentReadyDiv"
                            >
                                <IonButton
                                    className="taskChartDocumentReadyButton"
                                    onClick={() => {
                                        window.open(this.state.xlsxDownloadUrl, "_system");
                                    }}
                                >
                                    Documento pronto.<br />
                                    Clicca qui per il download.
                                </IonButton>
                            </div>
                        </IonGrid>
                    }


                    {
                        this.state.selectedSessionUuid &&
                        !(this.state.xlsxDownloadUrl || this.state.chartImgUrl) &&
                        <>
                            <div className='chartSessionSelectionDiv sessionSelector'>
                                <IonLabel>Sessione del:</IonLabel>
                                <IonSelect
                                    value={this.state.selectedSessionUuid}
                                    // onLoad={() => { this.setState({ selectedSessionUuid: this.state.selectedSessionUuid, selectedSession: this.state.selectedSession }) }}
                                    onIonChange={(e) => {
                                        let selectedSession = this.state.sessions.filter(session => session.uuid === e.detail.value)
                                        this.setState({ selectedSessionUuid: e.detail.value, selectedSession: selectedSession[0], isLoadingData: true })
                                        this.processData();
                                    }}
                                    interface='popover'
                                >
                                    {
                                        this.state.sessions.map((session, key) => {
                                            return (
                                                <IonSelectOption key={key} value={session.uuid}>
                                                    {
                                                        getLocaleDateAndTime(session.endedAt * 1000)
                                                    }
                                                </IonSelectOption>
                                            )
                                        })}
                                </IonSelect>
                            </div>
                            <div className='chartSessionSelectionDiv userSelector'>
                                <IonLabel>Registrate da:</IonLabel>
                                <IonSelect
                                    value={this.state.selectedUser}
                                    onIonChange={(e) => {
                                        this.setState({ selectedUser: e.detail.value, selectedSession: null, selectedSessionUuid: '', isLoadingData: true })
                                        this.processData();
                                    }}
                                    interface='popover'
                                >
                                    <IonSelectOption value={"all_users"}>
                                        Chiunque {/* TO LOCALIZE THIS */}
                                    </IonSelectOption>
                                    {
                                        this.state.accessList.map((user, key) => {
                                            return (
                                                <IonSelectOption key={key} value={user.uuid}>
                                                    {
                                                        user.email
                                                    }
                                                </IonSelectOption>
                                            )
                                        })}
                                </IonSelect>
                            </div>


                            <div className='chartDiv'>
                                <div id="chart">
                                </div>
                            </div>
                        </>
                    }
                    {
                        this.state.showExportDataRequestedToast &&
                        <IonLoading
                            isOpen={true}
                            backdropDismiss={false}
                            message={"Sto preparando il download..."}
                        />
                    }

                    {
                        (!this.state.selectedSessionUuid || this.state.sessions.length === 0) && !this.state.isLoadingData &&
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <div className='chartSessionSelectionDiv'>
                                <IonLabel>Registrate da:</IonLabel>
                                <IonSelect
                                    value={this.state.selectedUser}
                                    onIonChange={(e) => {
                                        this.setState({ selectedUser: e.detail.value, selectedSession: null, selectedSessionUuid: '', isLoadingData: true })
                                        this.processData();
                                    }}
                                    interface='popover'
                                >
                                    <IonSelectOption value={"all_users"}>
                                        Chiunque {/* TO LOCALIZE THIS */}
                                    </IonSelectOption>
                                    {
                                        this.state.accessList.map((user, key) => {
                                            return (
                                                <IonSelectOption key={key} value={user.uuid}>
                                                    {
                                                        user.email
                                                    }
                                                </IonSelectOption>
                                            )
                                        })}
                                </IonSelect>
                            </div>
                            <IonTitle style={{ textAlign: 'center' }}>
                                Non sono state registrate sessioni per questa task
                            </IonTitle>
                            <IonButton className='chartNoSessionsButton' onClick={() => this.props.history.push(`/data-collection/collect?task=${this.state.selectedTask.uuid}`)}>
                                Vai alla presa dati
                            </IonButton>
                        </div>

                    }
                </IonContent>

                <IonToast
                    isOpen={this.state.showExportDataRequestedToast}
                    onDidDismiss={() => {
                        this.setState({ showExportDataRequestedToast: false });
                    }}
                    message="Esportazione dati richiesta ✅. Attendere" /* TO LOCALIZE THIS */
                    duration={2500}
                />

                <IonToast
                    isOpen={this.state.showDownloadCompleteToast}
                    onDidDismiss={() => this.setState({ showDownloadCompleteToast: false })}
                    message="Grafico scaricato. Guarda nella galleria." /* TO LOCALIZE THIS */
                    duration={2500}
                />

                <GetPremiumPopover
                    history={this.props.history}
                    isOpen={this.state.showPayPopover}
                    onGetPremiumButtonPressed={() => {
                        this.setState({ showPayPopover: false }, () => {
                            this.props.history.push(`/purchase`);
                        });
                    }}
                    onDidDismiss={() => {
                        this.setState({ showPayPopover: false });
                    }}
                />


            </IonPage >
        );
    }
    exportXlsx() {
        this.setState({ showExportDataRequestedToast: true });
        const generateDataXlsx = httpsCallable(functions, "generateDataXlsx-generateDataXlsx");
        generateDataXlsx({
            childUuid: this.props.currentChild.uuid,
        })
            .then(data => {
                this.setState({ showExportDataRequestedToast: false });
                if ((data.data as any).error) {
                    //console.log(`[TaskChart] cloud function "generateDataXlsx" returned error:`, (data.data as any).error);
                    if ((data.data as any).error.code) {
                        switch ((data.data as any).error.code) {
                            case 760:
                                this.setState({ showPayPopover: true });
                                break;

                            case 400:
                                break;

                            default:
                                break;
                        }
                    }
                }
                else {
                    this.setState({ xlsxDownloadUrl: (data.data as any).xlsxUrl as string });
                }
            })
            .catch(err => {
                //console.log("[TaskChart] error callling generateDataXlsx cloud function:", err);
                this.setState({ showExportDataRequestedToast: false });
            })
    }
}

const mapStateToProps = state => {
    return {
        autonomiesList: state.autonomies.list,
        currentAutonomy: state.autonomies.currentAutonomy,
        currentChild: state.children.currentChild,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchStepsList: (childUuid: string) => {
            dispatch(autonomiesActions.fetchStepsList(childUuid))
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskChart);