import {
    IonAlert,
    IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonContent, IonGrid,
    IonIcon, IonLoading, IonPage, IonRow, IonText
} from "@ionic/react";
import { fromQueryToObjOfParams } from "common/queryUtils";
import { auth, functions } from "../../../firebase";
import { childrenService } from "modules/children/store/services";
import React from "react";
import { connect } from "react-redux";
import "./Invitation.css";
import { httpsCallable } from "@firebase/functions";
import { Player } from "@lottiefiles/react-lottie-player";
import invitationLottie from "../../../assets/animations/invitationLottie.json";
import invitationNotFound from "../../../assets/animations/invitationNotFound.json";
import { closeOutline } from "ionicons/icons";


type Props = {
    history: any,
}

type State = {
    invitationUuid: string | null,
    invitingUserData: any | null,
    childData: any | null,

    userUuid: string | null,
    isLoading: boolean,

    showErrorAlert: boolean,
}

class Invitation extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            invitationUuid: null,
            invitingUserData: null,
            childData: null,

            userUuid: null,
            isLoading: false,

            showErrorAlert: false,
        }
    }

    async componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ userUuid: userData.uid });
            }
            else {
                this.setState({ userUuid: null });
            }
        });

        if (this.props.history.location && this.props.history.location.search) {
            const invitationUuid = fromQueryToObjOfParams(this.props.history.location.search as string)["invitationUuid"];
            if (invitationUuid) {
                await this.getInvitationData(invitationUuid)
            }
            //console.log("STATE", this.state);

        }
    }

    async getInvitationData(invitationUuid) {
        this.setState({ isLoading: true, })
        const getInvitationData = httpsCallable(functions, "getDataFromInvitation-getDataFromInvitation");
        await getInvitationData({
            invitationUuid: invitationUuid,
        })
            .then(data => {
                //console.log("data from invitation:", data.data);
                if ((data.data as any).error) {
                    /* this.setState({ isLoading: false }); */
                    //console.log("get invitation data returned error:", (data.data as any).error);
                }
                else {
                    let recievedData = data.data as any;
                    this.setState({ invitingUserData: recievedData.data.userData, childData: recievedData.data.childData, isLoading: false, })
                    this.setState({ invitationUuid });

                    //console.log("got invitation data successfully.", recievedData);
                }
            })
            .catch(err => {
                //console.log("error calling get invitation data cloud function:", err);
            })
    }



    render() {
        return (
            <IonPage>
                {
                    //this.state.childData &&
                    <IonContent className='invitationPage'>
                        <div className='invitationTitleDiv'>

                        </div>

                        <IonGrid className="invitationMainGrid">
                            {
                                this.state.invitationUuid &&
                                this.state.userUuid &&
                                <IonCard className='invitationCard'>
                                    <IonCardHeader>
                                        {/* https://lottiefiles.com/72309-website-qa */}
                                        <Player
                                            className='invitationLottie'
                                            autoplay
                                            loop
                                            src={invitationLottie}
                                        />
                                    </IonCardHeader>
                                    <IonIcon
                                        onClick={() => {
                                            this.setState({ isLoading: true });
                                            childrenService.childInvitationResponse(this.state.invitationUuid, false)
                                                .then((childUuid) => {
                                                    this.setState({ isLoading: false });
                                                    //console.log("[Invitation] declined invitation for child:", childUuid); // TO REMOVE
                                                    this.props.history.replace(`/`);
                                                })
                                                .catch(err => {
                                                    //console.log("[Invitation] Error sending invitation response:", err);
                                                    this.setState({
                                                        isLoading: false,
                                                        showErrorAlert: true,
                                                    });
                                                })
                                        }}
                                        icon={closeOutline}
                                        size='large'
                                        style={{ position: 'absolute', top: '0px', right: '0px', cursor: 'pointer' }}
                                    />
                                    <IonCardContent className='invitationCardContent'>
                                        <IonText className='invitationUserName'>
                                            {this.state.invitingUserData.f} {this.state.invitingUserData.l}
                                        </IonText>
                                        <IonText className='invitationUserRole'>
                                            {this.state.invitingUserData.r}
                                        </IonText>
                                        <IonText className='invitationUserRole'>
                                            Vuole collaborare con te sulla scheda di {this.state.childData.f} {this.state.childData.l}
                                        </IonText>
                                        <IonButtons className='invitationButtons'>
                                            <IonButton
                                                className='invitationAcceptButton'
                                                onClick={() => {
                                                    this.setState({ isLoading: true });
                                                    childrenService.childInvitationResponse(this.state.invitationUuid, true)
                                                        .then((childUuid) => {
                                                            this.setState({ isLoading: false });
                                                            //console.log("[Invitation] accepted invitation for child:", childUuid); // TO REMOVE
                                                            this.props.history.replace(`/dashboard?child=${childUuid}`);
                                                        })
                                                        .catch(err => {
                                                            //console.log("[Invitation] Error sending invitation response:", err);
                                                            this.setState({
                                                                isLoading: false,
                                                                showErrorAlert: true,
                                                            });
                                                        })
                                                }}
                                            >
                                                Accetta
                                            </IonButton>
                                            <IonButton
                                                className='invitationDeclineButton'
                                                onClick={() => {
                                                    this.setState({ isLoading: true });
                                                    childrenService.childInvitationResponse(this.state.invitationUuid, false)
                                                        .then((childUuid) => {
                                                            this.setState({ isLoading: false });
                                                            //console.log("[Invitation] declined invitation for child:", childUuid); // TO REMOVE
                                                            this.props.history.replace(`/`);
                                                        })
                                                        .catch(err => {
                                                            //console.log("[Invitation] Error sending invitation response:", err);
                                                            this.setState({
                                                                isLoading: false,
                                                                showErrorAlert: true,
                                                            });
                                                        })
                                                }}
                                            >
                                                Rifiuta
                                            </IonButton>
                                        </IonButtons>

                                    </IonCardContent>
                                </IonCard>
                            }

                            {
                                this.state.userUuid &&
                                !this.state.invitationUuid && !this.state.isLoading &&
                                <IonCard className='invitationCard'>
                                    <IonCardHeader>
                                        {/* https://lottiefiles.com/73061-search-not-found */}
                                        <Player
                                            className='invitationLottie'
                                            autoplay
                                            loop
                                            src={invitationNotFound}
                                        />
                                    </IonCardHeader>
                                    <IonCardContent className='invitationCardContent'>
                                        <IonText className='invitationUserRole'>
                                            L'invito non esiste o è già stato accettato {/* TO LOCALIZE */}
                                        </IonText>
                                        <IonButtons className='invitationButtons'>
                                            <IonButton
                                                className='invitationMissingContinueButton'
                                                onClick={() => {
                                                    this.props.history.replace(`/`);
                                                }}
                                            >
                                                Torna a Maestro
                                            </IonButton>
                                        </IonButtons>

                                    </IonCardContent>
                                </IonCard>
                            }

                            {
                                !this.state.userUuid &&
                                <IonRow>
                                    <p>
                                        Per accettare l'invito è necessario essere autenticati
                                    </p>
                                </IonRow>
                            }

                        </IonGrid>
                    </IonContent>
                }
                <IonLoading
                    isOpen={this.state.isLoading}
                    onDidDismiss={() => {
                        this.setState({ isLoading: false });
                    }}
                    message={"Attendere prego..."} /* TO LOCALIZE THIS */
                    duration={10000}
                />

                <IonAlert
                    isOpen={this.state.showErrorAlert}
                    onDidDismiss={() => {
                        this.setState({ showErrorAlert: false })
                    }}
                    header={`Errore`} /* TO LOCALIZE THIS */
                    message={`Si è già risposto all'invito o è scaduto.`} /* TO LOCALIZE THIS */
                    buttons={[
                        {
                            role: 'cancel',
                            text: 'Ok', /* TO LOCALIZE THIS */
                        }
                    ]}
                />
            </IonPage >
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invitation);