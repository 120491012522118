export function getLocaleDateAndTime(date: string | number) { /* TO LOCALIZE THIS */
    const dateToFormat = new Date(date);
    const formattedDate = dateToFormat.toLocaleDateString("it-IT", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
    let formattedTime = dateToFormat.toLocaleTimeString("it-IT", {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
    const timeArray = formattedTime.split(":");
    timeArray.pop()
    formattedTime = timeArray.join(":");
    return `${formattedDate} alle ${formattedTime}`; /* TO LOCALIZE THIS */
}