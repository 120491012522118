import { IonButton } from "@ionic/react";
import React from "react";


type Props = {
    onInteraction?: (value: number) => void,
}

type State = {
    selectedButton: number | null,
}

class PromptStep extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            selectedButton: null,
        }
    }

    render() {
        return (
            <div className="taskStepsDataCollectionPromptsButtonsDiv stepValueDiv">
                <IonButton
                    fill='clear'
                    color={this.state.selectedButton === 0 ? 'danger' : 'tertiary'}
                    class='button'
                    className="taskStepsDataCollectionPromptButton"


                    onClick={() => {
                        if (this.props.onInteraction) {
                            this.props.onInteraction(0);
                        }
                        this.setState({ selectedButton: 0 });
                    }}
                >
                    Prompt<br />totale {/* TO BE LOCALIZED */}
                </IonButton>

                <IonButton
                    fill='clear'
                    color={this.state.selectedButton === 1 ? 'danger' : 'tertiary'}
                    class='button'
                    className="taskStepsDataCollectionPromptButton"

                    onClick={() => {
                        if (this.props.onInteraction) {
                            this.props.onInteraction(50);
                        }
                        this.setState({ selectedButton: 1 });
                    }}
                >
                    Prompt<br />parziale {/* TO BE LOCALIZED */}
                </IonButton>

                <IonButton
                    fill='clear'
                    color={this.state.selectedButton === 2 ? 'danger' : 'tertiary'}
                    class='button'
                    className="taskStepsDataCollectionPromptButton"

                    onClick={() => {
                        if (this.props.onInteraction) {
                            this.props.onInteraction(100);
                        }
                        this.setState({ selectedButton: 2 });

                    }}
                >
                    Autonomo {/* TO BE LOCALIZED */}
                </IonButton>

            </div>
        );
    }
}

export default PromptStep;