import React from 'react';
import {
    IonAlert, IonBackButton, IonButton, IonButtons, IonContent,
    IonGrid, IonHeader, IonIcon, IonInput, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase';
import supportPersonIcon from "../../../assets/icons/supportPerson.svg";
import { connect } from 'react-redux';
import "./Login.css";

type Props = {
    history: any,
    isAuthenticated: boolean,
}

type State = {
    email: string | null,
    password: string | null,

    showErrorAlert: boolean,
    errorAlertHeaderMessage: string,
    errorAlertSubheaderMessage: string,

    showSupportButton: boolean,
}

class Login extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            password: null,

            showErrorAlert: false,
            errorAlertHeaderMessage: "",
            errorAlertSubheaderMessage: "",

            showSupportButton: false,
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot='start'>
                            <IonBackButton defaultHref="/" />
                        </IonButtons>
                        <IonTitle>
                            Accedi {/* TO BE LOCALIZED */}
                        </IonTitle>
                        {
                            this.state.showSupportButton &&
                            <IonButtons slot='end'>
                                <IonButton
                                    fill='clear'
                                    shape='round'
                                    onClick={() => {
                                        window.open('https://wa.me/393661126799');
                                    }}
                                >
                                    <IonIcon
                                        size='large'
                                        icon={supportPersonIcon}
                                    />
                                </IonButton>
                            </IonButtons>
                        }
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="loginMainGrid">

                        <div className="loginInputsDiv">
                            <div className="loginInputsInnerDiv">
                                <IonInput
                                    className="loginInputField"
                                    type='email'
                                    autocomplete='email'
                                    inputMode="email"
                                    placeholder="La tua email" /* TO BE LOCALIZED */
                                    value={this.state.email}
                                    onIonChange={e => {
                                        this.setState({ email: e.detail.value ? e.detail.value : null });
                                    }}
                                />

                                <IonInput
                                    className="loginInputField"
                                    type="password"
                                    autocomplete="current-password"
                                    inputMode="text"
                                    placeholder="Password" /* TO BE LOCALIZED */
                                    value={this.state.password}
                                    onIonChange={e => {
                                        this.setState({ password: e.detail.value ? e.detail.value : null });
                                    }}
                                />
                            </div>
                        </div>

                        <div className="loginButtonsDiv">
                            <IonButton
                                className="loginLoginButton"
                                onClick={() => {
                                    signInWithEmailAndPassword(auth, this.state.email, this.state.password)
                                        .then(userCredentials => {
                                            //console.log("[Login] user logged-in:", userCredentials.user.uid);
                                            setTimeout(() => {
                                                this.props.history.replace("/tasks/list");
                                            }, 1000);
                                        })
                                        .catch(err => {
                                            console.error("[Login] error logging-in:", err.code);

                                            if (err.code === "auth/user-not-found") {
                                                this.setState({
                                                    errorAlertHeaderMessage: "Sembra che tu non faccia parte di Maestro", /* TO BE LOCALIZED */
                                                    errorAlertSubheaderMessage: "Registrati da web o contatta il supporto.", /* TO BE LOCALIZED */
                                                    showSupportButton: true,
                                                });
                                            }
                                            else if (err.code === "auth/invalid-email") {
                                                this.setState({
                                                    errorAlertHeaderMessage: "Email non valida", /* TO BE LOCALIZED */
                                                    errorAlertSubheaderMessage: "Il formato della email non è corretto", /* TO BE LOCALIZED */
                                                });
                                            }
                                            else if (err.code === "auth/wrong-password") {
                                                this.setState({
                                                    errorAlertHeaderMessage: "Password non valida", /* TO BE LOCALIZED */
                                                    errorAlertSubheaderMessage: "La password è sbagliata. Riprova o resetta la password.", /* TO BE LOCALIZED */
                                                });
                                            }
                                            else if (err.code === "auth/user-disabled") {
                                                this.setState({
                                                    errorAlertHeaderMessage: "Utente disabilitato", /* TO BE LOCALIZED */
                                                    errorAlertSubheaderMessage: "Contatta il supporto cliccando l'icona in alto a destra.", /* TO BE LOCALIZED */
                                                    showSupportButton: true,
                                                });
                                            }
                                            else if (err.code === "auth/missing-email") {
                                                this.setState({
                                                    errorAlertHeaderMessage: "Email non inserita", /* TO BE LOCALIZED */
                                                    errorAlertSubheaderMessage: "Completa il campo email.", /* TO BE LOCALIZED */
                                                });
                                            }
                                            this.setState({ showErrorAlert: true });
                                        })

                                }}
                            >
                                Login
                            </IonButton>

                            <IonButton
                                className='loginResetPasswordButton'
                                fill="clear"
                                onClick={() => {
                                    this.props.history.push(`/authentication/reset-password`)
                                }}
                            >
                                Ho dimenticato la password
                            </IonButton>
                        </div>
                    </IonGrid>
                </IonContent>

                <IonAlert
                    isOpen={this.state.showErrorAlert}
                    onDidDismiss={() => {
                        this.setState({
                            showErrorAlert: false,
                            errorAlertHeaderMessage: "",
                            errorAlertSubheaderMessage: "",
                        });
                    }}
                    keyboardClose={true}
                    header={this.state.errorAlertHeaderMessage} /* TO BE LOCALIZED */
                    subHeader={this.state.errorAlertSubheaderMessage} /* TO BE LOCALIZED */
                    buttons={["Ok"]}
                />
            </IonPage>
        );
    }

}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.userData ? true : false,
    }
}


const mapDispatchToProps = dispatch => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);