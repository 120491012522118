import { isPlatform } from "@ionic/core";

export interface Product {
    name: string,
    productId: string,
    expiryDate: Date,
    from: "web" | "ios" | "android" | "free" | "transfer" | "pos" | "first-week-from-signup",
}

export function productsArray(productsDbData: any): Product[] {
    const productsToReturn: Product[] = [];
    if(productsDbData) {
        const licensesIds = Object.keys(productsDbData);
        for (let i = 0; i < licensesIds.length; i++) {
            switch (productsDbData[licensesIds[i]].f) {
                case -1:
                    productsToReturn.push({
                        productId: productsDbData[licensesIds[i]].p,
                        name: fromProductIdToProductName(productsDbData[licensesIds[i]].p as string),
                        expiryDate: new Date(productsDbData[licensesIds[i]].e*1000),
                        from: "first-week-from-signup",
                    });
                    break;


                case 0:
                    productsToReturn.push({
                        productId: productsDbData[licensesIds[i]].p,
                        name: fromProductIdToProductName(productsDbData[licensesIds[i]].p as string),
                        expiryDate: new Date(productsDbData[licensesIds[i]].e*1000),
                        from: "web",
                    });
                    break;


                case 1:
                    productsToReturn.push({
                        productId: productsDbData[licensesIds[i]].p,
                        name: fromProductIdToProductName(productsDbData[licensesIds[i]].p as string),
                        expiryDate: new Date(productsDbData[licensesIds[i]].e*1000),
                        from: "ios",
                    });
                    break;

                case 2:
                    productsToReturn.push({
                        productId: productsDbData[licensesIds[i]].p,
                        name: fromProductIdToProductName(productsDbData[licensesIds[i]].p as string),
                        expiryDate: new Date(productsDbData[licensesIds[i]].e*1000),
                        from: "android",
                    });
                    break;

                    
                case 3:
                    productsToReturn.push({
                        productId: productsDbData[licensesIds[i]].p,
                        name: fromProductIdToProductName(productsDbData[licensesIds[i]].p as string),
                        expiryDate: new Date(productsDbData[licensesIds[i]].e*1000),
                        from: "free",
                    });
                    break;

                case 4:
                    productsToReturn.push({
                        productId: productsDbData[licensesIds[i]].p,
                        name: fromProductIdToProductName(productsDbData[licensesIds[i]].p as string),
                        expiryDate: new Date(productsDbData[licensesIds[i]].e*1000),
                        from: "transfer",
                    });
                    break;

                case 5:
                    productsToReturn.push({
                        productId: productsDbData[licensesIds[i]].p,
                        name: fromProductIdToProductName(productsDbData[licensesIds[i]].p as string),
                        expiryDate: new Date(productsDbData[licensesIds[i]].e*1000),
                        from: "pos",
                    });
                    break;
            
                default:
                    break;
            }           
        }
    }
    return productsToReturn;
}

function fromProductIdToProductName(productId: string): string {
    switch (productId) {
        case "prod_L45hosDxiFwR6S":
            return "Maestro premium mensile"

        case "prod_L45pDR5P62kNYl":
            return "Maestro premium mensile"

        case "ai.estro.maestro.aba.firstweekfree":
            return "Maestro premium gratuito per la prima settimana";

        case "ai.estro.maestro.aba.custom":
            return "Maestro premium licenza personalizzata";

        case "ai.estro.maestro.aba.free":
            return "Maestro premium licenza gratuita assegnata dal team estro";

        case "ai.estro.maestro.aba.monthly":
            return "Maestro premium mensile";
    
        default:
            return "Maestro"
    }
}

export function showPurchaseOnWebButton(products: Product[]): boolean {
    if(!isPlatform("capacitor") && !isPlatform("cordova")) {
        if(products.length > 0) {
            const nowDate = new Date();
            let productsToEvaluate = products.filter(product => product.productId !== "ai.estro.maestro.aba.firstweekfree");
            productsToEvaluate = productsToEvaluate.filter(product => product.expiryDate > nowDate);
            return productsToEvaluate.length < 1;   
        }
        else {
            return true;
        }
    }
    return false
}

export function showPurchaseOnIosButton(products: Product[]): boolean {
    if(
        (isPlatform("capacitor") || isPlatform("cordova")) &&
        isPlatform("ios")
    ) {
        if(products.length > 0) {
            const nowDate = new Date();
            let productsToEvaluate = products.filter(product => product.productId !== "ai.estro.maestro.aba.firstweekfree");
            productsToEvaluate = productsToEvaluate.filter(product => product.expiryDate > nowDate);
            return productsToEvaluate.length < 1;
        }
        else {
            return true;
        }
    }
    return false;
}

export function showPurchaseOnAndroidButton(products: Product[]): boolean {
    if(
        (isPlatform("capacitor") || isPlatform("cordova")) &&
        isPlatform("android")
    ) {
        if(products.length > 0) {
            const nowDate = new Date();
            let productsToEvaluate = products.filter(product => product.productId !== "ai.estro.maestro.aba.firstweekfree");
            productsToEvaluate = productsToEvaluate.filter(product => product.expiryDate > nowDate);
            return productsToEvaluate.length < 1;
        }
        else {
            return true;
        }
    }
    return false;
}