import { applicationActions } from "modules/core/store/actions";
import { autonomiesConstants } from "./constants";
import { tasksServices } from './services';


export const autonomiesActions = {
  fetchAutonomiesList,
  createTask,
  selectAutonomy,
  updateAutonomyData,
  updateAutonomySettings,
  updateAutonomyCover,
  deleteTask,
  clearCurrentAutonomy,

  createStep,
  updateStepVideo,
  fetchStepsList,
  swapStepsOrder,
  deleteStep,
  startSession,
  createStepInteraction,
};

const actionCreator = {
  updateAutonomiesSettings: (childUuid, autonomiesSettings) => { return { type: autonomiesConstants.UPDATE_AUTONOMIES_SETTINGS, childUuid, autonomiesSettings } },
  fetchAutonomiesList: (autonomiesList) => { return { type: autonomiesConstants.FETCH_AUTONOMIES_LIST, autonomiesList } },
  fetchStepsList: (stepsList) => { return { type: autonomiesConstants.FETCH_STEPS_LIST, stepsList } },
  createStep: (autonomy) => { return { type: autonomiesConstants.CREATE_STEP, autonomy } },
  updateStepTitle: (stepUuid, stepTitle) => { return { type: autonomiesConstants.UPDATE_STEP_TITLE, stepUuid, stepTitle } },
  updateStepVideo: (stepUuid, video) => { return { type: autonomiesConstants.UPDATE_STEP_VIDEO, stepUuid, video } },
  createAutonomy: (autonomy) => { return { type: autonomiesConstants.CREATE_AUTONOMY, autonomy } },
  updateAutonomyCover: (autonomy) => { return { type: autonomiesConstants.UPDATE_AUTONOMY_COVER, autonomy } },
  deleteAutonomy: () => { return { type: autonomiesConstants.DELETE_AUTONOMY } },
  selectAutonomy: (autonomyUuid) => { return { type: autonomiesConstants.SELECT_AUTONOMY, autonomyUuid } },
  clearCurrentAutonomy: () => { return { type: autonomiesConstants.CLEAR_CURRENT_AUTONOMY } },
  updateCurrentAutonomy: (task) => { return { type: autonomiesConstants.UPDATE_CURRENT_AUTONOMY, task } },

  startSession: (session) => { return { type: autonomiesConstants.START_SESSION, session } },
  createStepInteraction: (stepInteraction) => { return { type: autonomiesConstants.STEP_INTERACTION, stepInteraction } },

}

function fetchAutonomiesList(childUuid) {
  return dispatch => {
    tasksServices.fetchAutonomiesList(childUuid)
      .then(childUuid => {
        dispatch(actionCreator.fetchAutonomiesList(childUuid));
      })
      .catch(error => {
        console.error(error);
      })
  };
}

function fetchStepsList(autonomyUuid) {
  return dispatch => {
    /* tasksServices.fetchStepsList(autonomyUuid)
      .then(autonomyUuid => {
        dispatch(actionCreator.fetchStepsList(autonomyUuid));
      })
      .catch(error => {
        console.error(error);
      }) */
  };
}

function createTask(childUuid: string, title: string) {
  return dispatch => {
    tasksServices.createTask(childUuid, title)
      .then(autonomy => {
        dispatch(actionCreator.createAutonomy(autonomy));
        dispatch(actionCreator.selectAutonomy(autonomy.uuid))
      })
      .catch(error => {
        console.error(error);
      })
  };
}

function startSession(autonomyUuid: string, childUuid: string, endAt: Date, notes: string) {
  return dispatch => {
    /* tasksServices.startSession(autonomyUuid, childUuid, endAt, notes)
      .then(session => {
        dispatch(actionCreator.startSession(session));
      })
      .catch(error => {
        console.error(error);
      }) */
  };
}


function updateAutonomyData(autonomyUuid: string, autonomyData: Object) {
  return dispatch => {
    if (autonomyUuid || autonomyData) {
      /* tasksServices.updateAutonomyData(autonomyUuid, autonomyData)
        .then(autonomy => {
          dispatch(actionCreator.updateCurrentAutonomy(autonomy));
        })
        .catch(error => {
          console.error(error);
        }) */
    }
  }
}


function updateAutonomySettings(autonomyUuid: string, autonomySettings: Object) {
  return dispatch => {
    if (autonomyUuid && autonomySettings) {
      /* tasksServices.updateAutonomySettings(autonomyUuid, autonomySettings)
        .then(autonomy => {
          dispatch(actionCreator.updateCurrentAutonomy(autonomy));
        })
        .catch(error => {
          console.error(error);
        }) */
    }
  }
}

function updateAutonomyCover(autonomyUuid: string, image: File | Blob) {
  return dispatch => {
    const key: string = 'updateAutonomyCover';
    dispatch(applicationActions.showLoading(key));
    /* tasksServices.updateAutonomyCover(autonomyUuid, image)
      .then(autonomy => {
        dispatch(actionCreator.updateAutonomyCover(autonomy));
        dispatch(applicationActions.hideLoading(key));
      })
      .catch(error => {
        dispatch(applicationActions.hideLoading(key));
        console.error(error);
      }) */
  }
}

function deleteTask(autonomyUuid: string) {
  return dispatch => {
    tasksServices.deleteTask(autonomyUuid)
      .then(response => {
        dispatch(actionCreator.deleteAutonomy())
      })
      .catch(error => {
        console.error(error);
      })
  };
}


function createStep(autonomyUuid: string, index: number, title: string, video?: File | Blob) {
  return dispatch => {
    const key: string = 'createStep';
    dispatch(applicationActions.showLoading(key));
    tasksServices.createStep(autonomyUuid, title, video)
      .then(autonomy => {
        dispatch(actionCreator.createStep(autonomy));
        dispatch(applicationActions.hideLoading(key));
      })
      .catch(error => {
        console.error('[createStep] action error', error);
        dispatch(applicationActions.hideLoading(key));
      })
  };
}

function updateStepVideo(stepUuid, video) {
  return dispatch => {
    /* tasksServices.updateStepVideo(stepUuid, video)
      .then(step => {
        dispatch(actionCreator.updateStepVideo(stepUuid, video));
        //console.log("Updated Step:" + JSON.stringify(step))
      })
      .catch(error => {
        console.error("Errore: " + error);
      }) */
  };
}

function selectAutonomy(autonomyUuid: string = null) {
  return dispatch => {
    dispatch(actionCreator.selectAutonomy(autonomyUuid))
  }
}

function clearCurrentAutonomy() {
  return dispatch => {
    dispatch(actionCreator.clearCurrentAutonomy())
  }
}

function swapStepsOrder(taskUuid: string, from: number, to: number) {
  return dispatch => {
    const key: string = 'swapStepsOrder';
    dispatch(applicationActions.showLoading(key));
    tasksServices.swapStepsOrder(taskUuid, from, to)
      .then(autonomy => {
        dispatch(actionCreator.updateCurrentAutonomy(autonomy));
        dispatch(applicationActions.hideLoading(key));
      })
      .catch(error => {
        dispatch(applicationActions.hideLoading(key));
        //console.log('[swapStepsOrder action] error:', error);
      })
  }
}

function deleteStep(taskUuid: string, stepUuid: string) {
  return dispatch => {
    const key: string = 'deleteStep';
    dispatch(applicationActions.showLoading(key));
    tasksServices.deleteStep(taskUuid, stepUuid)
      .then(autonomy => {
        dispatch(actionCreator.updateCurrentAutonomy(autonomy));
        dispatch(applicationActions.hideLoading(key));
      })
      .catch(error => {
        dispatch(applicationActions.hideLoading(key));
        //console.log('[deleteStep action] error:', error);
      })
  }
}

function createStepInteraction(stepUuid: string, sessionUuid: string, value: number) {
  return dispatch => {
    /* tasksServices.createStepInteraction(stepUuid, sessionUuid, value)
      .then(stepInteraction => {
        dispatch(actionCreator.createStepInteraction(stepInteraction));
      })
      .catch(error => {
        //console.log('[stepInteraction] error:', error);
      }) */
  }
}

