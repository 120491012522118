import { ItemReorderEventDetail } from '@ionic/core';
import {
    IonAlert, IonBackButton, IonButton, IonButtons, IonCard,
    IonCardContent, IonContent, IonGrid, IonHeader, IonIcon,
    IonInput, IonLabel, IonLoading, IonPage, IonPopover, IonReorder,
    IonReorderGroup, IonRow, IonSelect, IonSelectOption, IonSpinner,
    IonTextarea, IonTitle, IonToast, IonToolbar
} from "@ionic/react";
import React from "react";
import { fromQueryToObjOfParams } from "common/queryUtils";
import { auth, database, storage } from "firebase";
import { ref, set } from "firebase/database";
import { deleteObject, getDownloadURL, ref as storageRef, uploadBytes } from "firebase/storage";
import { add, checkmark, closeOutline, cloudUploadOutline, trash } from "ionicons/icons";
import { Child } from "modules/children/store/reducers";
import { User } from "modules/user/store/reducers";
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Step, Task } from "../store/reducers";
import { tasksServices } from "../store/services";
import './TaskEditor.css';
import Tutorial from 'common/In-App Tutorial/Tutorial';
import { taskEditorSteps } from 'common/tutorialSteps';

type AutonomyProps = {
    history: any,
    currentChild: Child,
    userData: User | null,
}

type State = {
    popoverEvent: Event | null,
    disableReordering: boolean,
    showPopover: boolean,
    didFinishUpdate: number[],
    currentStep: Step,
    autonomyTitle: string,
    taskUuid: string | null,
    task: Task | null,
    showTitleToast: boolean,
    showAddVideoHelp: boolean,
    isLoadingTaskData: boolean,
    isLoading: boolean,
    isCreatingTask: boolean,
    uploadedMediaIds: string[],
    showMediaPopover: boolean,
    didChangeMedia: boolean,
    showDiscardAlert: boolean,
    originalMedia: {
        type: 'video' | "image" | null,
        url: string
    },
}

class AutonomyEditor extends React.Component<AutonomyProps, State> {
    constructor(props) {
        super(props);
        this.state = {

            popoverEvent: null,
            disableReordering: true,
            showPopover: false,
            didFinishUpdate: [],
            currentStep: null,

            autonomyTitle: "",
            taskUuid: null,
            task: null,

            showTitleToast: false,
            showAddVideoHelp: false,

            isLoadingTaskData: false,
            isLoading: false,
            isCreatingTask: false,
            uploadedMediaIds: [],
            showMediaPopover: false,
            didChangeMedia: false,
            showDiscardAlert: false,
            originalMedia: {
                type: null,
                url: '',
            },
        }
    }


    componentDidMount() {
        const taskUuid = fromQueryToObjOfParams(this.props.history.location.search as string)["task"];
        if (taskUuid) {
            this.setState({ taskUuid: taskUuid });
            this.fetchTask(taskUuid);
        }
        setTimeout(() => {
            let video = document.getElementsByTagName('video')[0];
            video?.removeAttribute('controls')
        }, 1000);
    }

    fetchTask(taskUuid: string) {
        this.setState({ isLoadingTaskData: true });
        tasksServices.fetchTask(taskUuid)
            .then(task => {
                this.setState({
                    isLoadingTaskData: false,
                    task: task,
                    autonomyTitle: task.title,
                });
            })
            .catch(err => {
                //console.log("[TaskEditor] fetch task error:", err);
                this.setState({ isLoadingTaskData: false });
            });
    }

    doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        //console.log('[TaskEditor - doReorder] event:', event)
        //console.log('[TaskEditor - doReorder] Dragged from index', event.detail.from, 'to', event.detail.to);

        event.detail.complete();

        tasksServices.swapStepsOrder(
            this.state.task.uuid,
            event.detail.from,
            event.detail.to,
        );
    }

    editCoverImageFromBrowser(image: File) {
        if (image.type !== 'image/png' && image.type !== 'image/jpeg') {
            alert("Puoi caricare solo file PNG o JPG")
            return
        }
        //console.log('[editCoverImage]: File upload required from browser');
        /* tasksServices.updateAutonomyCover(this.state.task.uuid, image)
            .then(task => {
                //console.log("[TaskEditor] update cover success:", task);
                this.setState({ task: task });
            })
            .catch(err => {
                //console.log("[TaskEditor] error updating autonomy cover:", err);
            }) */
    }

    makeId(length: number) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    fromGSUrltoUrl(gsUrl: string) {
        return new Promise<string>((resolve, reject) => {
            getDownloadURL(storageRef(storage, gsUrl))
                .then(url => {
                    //console.log('[fromGSUrltoUrl] service - url:', url);
                    resolve(url);
                })
                .catch(err => {
                    //console.log('[fromGSUrltoUrl] service error:', err);
                    reject(err);
                })
        });
    }


    uploadMedia(media: File | null | undefined, alreadyExists: boolean) {
        //console.log("USER DATA", auth.currentUser.uid);
        //console.log("UPLOAD MEDIA", this.state.currentStep);
        if (media && auth.currentUser.uid) {
            if (media.type.includes('image/')) {
                uploadBytes(storageRef(storage, `users-media/${auth.currentUser.uid}/tasks/${this.state.taskUuid}/${this.makeId(16)}`), media)
                    .then(data => {
                        //console.log("[Utils] upload success:", data.ref);
                        let currentStep = this.state.currentStep;
                        let task = this.state.task;
                        this.fromGSUrltoUrl(data.ref.fullPath)
                            .then(url => {
                                //console.log('CURRENT STEP WHEN UPLOADING', currentStep);
                                if (!alreadyExists) {
                                    currentStep.imageGsUrl = url
                                    task.steps[task.steps.indexOf(this.state.currentStep)].imageGsUrl = url
                                    this.setState({ task: task, currentStep: currentStep }, () => {
                                        this.updateStepOnDb()
                                    })
                                }
                                else {
                                    if (this.state.originalMedia.url === '') {
                                        //console.log("setting original media to ", currentStep.imageGsUrl);
                                        if (this.state.currentStep.imageGsUrl !== null) {
                                            this.setState({
                                                originalMedia: {
                                                    type: 'image', url: currentStep.imageGsUrl
                                                }
                                            })
                                        }
                                        else {
                                            this.setState({
                                                originalMedia: {
                                                    type: 'video', url: currentStep.videoGsUrl
                                                }
                                            })
                                        }
                                    }
                                    currentStep.videoGsUrl = null;
                                    this.state.uploadedMediaIds.push(data.ref.name)
                                    currentStep.imageGsUrl = url
                                    task.steps[task.steps.indexOf(this.state.currentStep)].imageGsUrl = url
                                    this.setState({ task: task, currentStep: currentStep })
                                }

                            })
                    })
                    .catch(err => {
                        //console.log("[Utils] error uploading file", err);
                    })
            }
            else if (media.type.includes('video/') && media.type.includes('mp4')) {
                uploadBytes(storageRef(storage, `users-media/${auth.currentUser.uid}/tasks/${this.state.taskUuid}/${this.makeId(16)}`), media)
                    .then(data => {
                        //console.log("[Utils] upload success:", data.ref);
                        let task = this.state.task;
                        let currentStep = this.state.currentStep;
                        this.fromGSUrltoUrl(data.ref.fullPath)
                            .then(url => {
                                //console.log('CURRENT STEP WHEN UPLOADING', this.state.currentStep);

                                if (!alreadyExists) {
                                    currentStep.videoGsUrl = url
                                    task.steps[task.steps.indexOf(this.state.currentStep)].videoGsUrl = url
                                    this.setState({ task: task, currentStep: currentStep }, () => {
                                        this.updateStepOnDb()
                                    })
                                }
                                else {
                                    if (this.state.originalMedia.url === '') {
                                        //console.log("setting original media to ", currentStep.videoGsUrl);

                                        if (this.state.currentStep.imageGsUrl !== null) {
                                            this.setState({
                                                originalMedia: {
                                                    type: 'image', url: currentStep.imageGsUrl
                                                }
                                            })
                                        }
                                        else {
                                            this.setState({
                                                originalMedia: {
                                                    type: 'video', url: currentStep.videoGsUrl
                                                }
                                            })
                                        }
                                    }
                                    this.state.uploadedMediaIds.push(data.ref.name)
                                    currentStep.imageGsUrl = null;
                                    currentStep.videoGsUrl = url
                                    task.steps[task.steps.indexOf(this.state.currentStep)].videoGsUrl = url
                                    this.setState({ task: task, currentStep: currentStep })
                                }

                            })
                    })
                    .catch(err => {
                        //console.log("[Utils] error uploading file", err);
                    })
            }
            else
                alert('Puoi caricare solo immagini e video - Formati supportati: PNG, JPG, GIF, MP4') /* TO LOCALIZE THIS */
        }
    }


    deleteUnusedImages() {
        this.state.uploadedMediaIds.forEach(id => {
            if (this.state.task) {
                deleteObject(storageRef(storage, `users-media/${auth.currentUser.uid}/tasks/${this.state.task.uuid}/${id}`))
                    .then(response => {
                        //console.log("[CalendarEditor] Deleted media from storage");
                        this.state.uploadedMediaIds.splice(this.state.uploadedMediaIds.indexOf(id), 1);
                    })
                    .catch(e => {
                        //console.log('[CalendarEditor] Error deleting media', e);
                    })
            }
        })
    }

    updateStepOnDb() {
        if (this.state.task && this.state.currentStep) {
            //console.log("UPDATING STEP ON DB", this.state.currentStep);
            const tempContent = {
                o: this.state.currentStep.index,
                t: this.state.currentStep.title,
                v: this.state.currentStep.videoGsUrl ? this.state.currentStep.videoGsUrl : null,
                i: this.state.currentStep.imageGsUrl ? this.state.currentStep.imageGsUrl : null,
            }
            set(ref(database, `/t/${this.state.task?.uuid}/s/${this.state.currentStep.uuid}/`), tempContent)
        }
    }


    removeMedia(url: string) {
        //delete media from storage
        deleteObject(storageRef(storage, url))
        //update state
        let tempStep = this.state.currentStep;
        tempStep.imageGsUrl = null;
        tempStep.videoGsUrl = null;
        const tempContent = {
            o: this.state.currentStep.index,
            t: this.state.currentStep.title,
        }
        this.setState({ currentStep: tempStep }, () => {
            //delete media from db
            set(ref(database, `/t/${this.state.task?.uuid}/s/${this.state.currentStep.uuid}/`), tempContent)
        })

    }



    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/tasks/list" />
                        </IonButtons>

                        {/* <IonTitle>{this.props.currentAutonomy ? this.props.currentAutonomy.title: 'Nuova autonomia'}</IonTitle> */}
                        <IonTitle>Dettaglio</IonTitle>
                        <Tutorial steps={taskEditorSteps} />
                        <IonButtons slot="end" className='reorderButton'>
                            <IonButton
                                onClick={() => this.setState({ disableReordering: !this.state.disableReordering })}
                            >
                                Riordina
                            </IonButton>
                        </IonButtons>

                    </IonToolbar>
                </IonHeader>

                {/* VIDEO ACTION SHEET */}
                <IonContent>
                    <IonGrid className='autonomyEditorGrid'>
                        <IonCard>
                            <IonGrid>
                                <IonRow>
                                    <IonInput
                                        placeholder={this.state.isLoadingTaskData ? "" : "Inserire il titolo della task"}
                                        class='autonomyTitleInput taskTitle'
                                        value={this.state.autonomyTitle}
                                        onIonChange={(e) => {
                                            this.setState({ autonomyTitle: e.detail.value ? e.detail.value : "" });
                                        }}
                                    >
                                        {
                                            this.state.isLoadingTaskData &&
                                            <div className="taskEditorTaskTitleLoadingDiv">
                                                <IonSpinner />
                                            </div>
                                        }
                                        {
                                            this.state.isCreatingTask &&
                                            <IonLoading
                                                backdropDismiss={false}
                                                isOpen={true}
                                                message='Sto creando la task...'
                                            />
                                        }
                                    </IonInput>

                                    <IonButton color={'primary'}
                                        onClick={() => {
                                            if (this.state.task) {
                                                tasksServices.updateTaskTitle(this.state.task.uuid, this.state.autonomyTitle)
                                                    .then((task) => {
                                                        this.setState({
                                                            task: task,
                                                            showTitleToast: true,
                                                        });
                                                    })
                                                    .catch(err => {
                                                        //console.log("[TaskEditor] error updating task title:", err);
                                                    });
                                            }
                                            else {
                                                this.setState({ isCreatingTask: true })
                                                tasksServices.createTask(this.props.currentChild.uuid, this.state.autonomyTitle)
                                                    .then(task => {
                                                        window.analytics.track({
                                                            "event": "Added task",
                                                            "properties": {
                                                                "category": "Task add",
                                                                "label": auth.currentUser.uid + ": " + task.title,
                                                            }
                                                        });
                                                        this.setState({
                                                            task: task,
                                                            taskUuid: task.uuid,
                                                            isCreatingTask: false,
                                                        });
                                                        window.history.pushState({}, 'Maestro', `/tasks/edit?task=${task.uuid}`);
                                                    })
                                                    .catch(err => {
                                                        //console.log("[TaskEditor] error creating task:", err);
                                                    })
                                            }
                                        }}
                                    >
                                        <IonIcon
                                            icon={checkmark}
                                        />
                                    </IonButton>
                                </IonRow>
                            </IonGrid>
                        </IonCard>
                        {
                            this.state.task && this.props.userData &&

                            <IonCard className='taskModeSelect'>
                                <p
                                    className='taskEditorCardTitle'
                                >
                                    Modalità di raccolta dati
                                </p>
                                <IonCardContent>
                                    <IonSelect
                                        cancelText='Indietro'
                                        className="taskEditorDataCollectionModeSelector"
                                        interface="alert"
                                        placeholder='Modalità raccolta dati'
                                        value={this.state.task.dataCollectionMode}
                                        onIonChange={e => {
                                            tasksServices.updateTaskDataCollectionMode(this.state.task.uuid, Number(e.detail.value))
                                                .then((task) => {
                                                    this.setState({ task: task })
                                                })
                                                .catch(err => {
                                                    //console.log("[TaskEditor] update task data collection mode error:", err);
                                                })
                                        }}
                                    >
                                        <IonSelectOption value={0}>
                                            Più e meno
                                        </IonSelectOption> {/* TO LOCALIZE THIS */}
                                        <IonSelectOption value={1}>
                                            Prompt (PT / PP / A)
                                        </IonSelectOption>
                                        <IonSelectOption value={2}>
                                            Voto
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonCardContent>
                            </IonCard>
                        }

                        {
                            this.state.taskUuid &&
                            <IonCard className='stepsList'>
                                <p
                                    className="taskEditorCardTitle"
                                >
                                    Step della task {/* TO LOCALIZE THIS */}
                                </p>
                                <p
                                    className="taskEditorCardSubtitle"
                                >
                                    Modifica il titolo e il video degli step {/* TO LOCALIZE THIS */}
                                </p>
                                <div className="taskEditorStepsList">
                                    {
                                        this.state.isLoadingTaskData &&
                                        <div className="taskEditorTaskStepsLoadingDiv">
                                            <IonSpinner />
                                        </div>
                                    }

                                    <IonReorderGroup
                                        disabled={this.state.disableReordering}
                                        onIonItemReorder={e => this.doReorder(e)}
                                    >
                                        {
                                            this.state.task &&
                                            this.state.task.steps.map((step, i) => {
                                                return (
                                                    <IonRow
                                                        key={step.uuid}
                                                        className='taskEditorStepItem'
                                                    >
                                                        <IonSpinner name='crescent' hidden={step.index !== this.state.didFinishUpdate[0] || !this.state.disableReordering}></IonSpinner>

                                                        <IonReorder hidden={this.state.disableReordering} />
                                                        {
                                                            !this.state.disableReordering &&
                                                            <IonButton
                                                                fill='clear'
                                                                color='danger'
                                                                onClick={() => {
                                                                    tasksServices.deleteStep(this.state.task.uuid, step.uuid)
                                                                        .then(task => {
                                                                            //console.log("[TaskEditor] delete step result:", task);
                                                                            this.setState({ task: task });
                                                                        })
                                                                        .catch(err => {
                                                                            //console.log("[TaskEditor] delete step error:", err);
                                                                        })
                                                                }}
                                                            >
                                                                <IonIcon
                                                                    icon={trash}
                                                                />
                                                            </IonButton>
                                                        }

                                                        <IonTextarea
                                                            className="taskEditorStepTextarea"
                                                            autoGrow={true}
                                                            readonly={!this.state.disableReordering}
                                                            value={step.title}
                                                            inputMode="text"
                                                            debounce={700}
                                                            onIonFocus={() => {
                                                                let index = [step.index]
                                                                this.setState({ didFinishUpdate: index })
                                                            }}
                                                            /* onIonBlur={() => {
                                                                setTimeout(() => {
                                                                    this.setState({ didFinishUpdate: [] })
                                                                }, 700);
                                                            }} */
                                                            onIonChange={(e) => {
                                                                if (e.detail.value === step.title)  // solves the IonChange triggering when leaving component!!
                                                                    return;
                                                                else {
                                                                    step.title = e.detail.value
                                                                    this.setState({ didFinishUpdate: [step.index] })
                                                                    tasksServices.updateStepTitle(this.state.task.uuid, step.uuid, e.detail.value)
                                                                        .then(task => {
                                                                            this.setState({ task: task, didFinishUpdate: [] });
                                                                        })
                                                                        .catch(err => {
                                                                            //console.log("[TaskEditor] error ediding step title:", err);
                                                                        })
                                                                }
                                                            }}
                                                        />
                                                        {!step.imageGsUrl && !step.videoGsUrl &&
                                                            <IonButton
                                                                onClick={() => {
                                                                    this.setState({ currentStep: step }, () => {
                                                                        (document.getElementById("utilsFileInput") as HTMLInputElement).click();
                                                                    });
                                                                }}
                                                                className="taskEditorAddMediaButton"
                                                            >
                                                                <IonIcon hidden={isMobile} icon={cloudUploadOutline} size='large' slot="end" />
                                                                Aggiungi media
                                                            </IonButton>
                                                        }
                                                        {
                                                            step.imageGsUrl &&
                                                            <img
                                                                onClick={() => {
                                                                    this.setState({ currentStep: step, showMediaPopover: true })
                                                                }}
                                                                alt={step.title}
                                                                className="taskEditorMediaPreview"
                                                                src={step.imageGsUrl}
                                                            />
                                                        }
                                                        {step.videoGsUrl &&
                                                            <video
                                                                preload="metadata"
                                                                className="taskEditorVideo"
                                                                src={step.videoGsUrl}
                                                                onClick={() => this.setState({ currentStep: step, showMediaPopover: true })} />
                                                        }
                                                        <input
                                                            style={{ position: "absolute", top: "-100px" }}
                                                            id="utilsFileInput"
                                                            type={"file"}
                                                            accept="image/png,image/gif,image/jpeg,video/mp4"
                                                            onChange={e => {
                                                                console.log(e);

                                                                this.uploadMedia((e.nativeEvent.target as HTMLInputElement).files?.item(0), false);
                                                            }}
                                                        />
                                                    </IonRow>
                                                )
                                            }
                                            )
                                        }
                                    </IonReorderGroup>
                                </div>

                                <IonGrid class='taskEditorButtonsGrid'>

                                    <IonRow>
                                        <IonButton
                                            className="taskEditorAddStepButton"
                                            shape='round'
                                            onClick={() => {
                                                this.setState({ showPopover: true });
                                                // //console.log('record video pressed on mobile');
                                            }}
                                        >
                                            <IonIcon icon={add} />
                                            Aggiungi step
                                        </IonButton>
                                    </IonRow>

                                </IonGrid>
                            </IonCard>
                        }

                        {
                            this.state.task &&
                            <IonGrid class='taskEditorButtonsGrid'>
                                <IonRow class='deleteAutonomyRow'>
                                    <IonButton
                                        className='deleteTask'
                                        fill='clear'
                                        color='danger'
                                        onClick={() => {
                                            this.setState({ isLoading: true });
                                            tasksServices.deleteTask(this.state.task.uuid)
                                                .then(() => {
                                                    //console.log("[TaskEditor] delete autonomy success");
                                                    this.setState({ isLoading: false }, () => {
                                                        this.props.history.replace(`/tasks/list`);
                                                    });
                                                })
                                                .catch(err => {
                                                    //console.log("[TaskEditor] delete autonomy error:", err);
                                                    this.setState({ isLoading: false });
                                                })
                                        }}
                                    >
                                        <IonLabel>Elimina task</IonLabel>
                                    </IonButton>
                                </IonRow>
                            </IonGrid>
                        }

                    </IonGrid>
                </IonContent>

                <IonToast
                    isOpen={this.state.showTitleToast}
                    onDidDismiss={() => this.setState({ showTitleToast: false })}
                    message="Titolo aggiornato" /* TO LOCALIZE THIS */
                    duration={1000}
                />

                <IonAlert
                    header="Aggiungi uno step alla task" /* TO LOCALIZE THIS */
                    isOpen={this.state.showPopover}
                    onDidDismiss={() => this.setState({ showPopover: false })}
                    inputs={[
                        {
                            name: 'stepTitle',
                            type: 'text'
                        }
                    ]}
                    buttons={[
                        {
                            text: 'Annulla', /* TO LOCALIZE THIS */
                            role: 'cancel',
                            handler: () => {
                                this.setState({ showPopover: false });
                            }
                        },
                        {
                            text: 'Crea step',
                            handler: (alertData) => {
                                this.setState({ isLoading: true });
                                tasksServices.createStep(
                                    this.state.task.uuid,
                                    (alertData.stepTitle as string).trim()
                                )
                                    .then(task => {
                                        this.setState({
                                            task: task,
                                            isLoading: false,
                                        });
                                        window.analytics.track({
                                            "event": "Created Step",
                                            "properties": {
                                                "category": "Step add",
                                                "label": auth.currentUser.uid + ": " + (alertData.stepTitle as string).trim(),
                                            }
                                        });

                                    })
                                    .catch(err => {
                                        this.setState({ isLoading: false });
                                        //console.log("[TaskEditor] error creating new step:", err);
                                    });
                            }
                        }
                    ]}
                />

                {/* <IonPopover
                    event={this.state.popoverEvent}
                    isOpen={this.state.showAddCoverHelp}
                    onDidDismiss={() => this.setState({ showAddCoverHelp: false })}
                    //className="taskEditorAddCoverHelpPopover"
                    showBackdrop={false}
                >
                    <p
                        className="taskEditorAddCoverHelpParagraph"
                    >
                        Aggiungere una copertina può aiutare a riconoscere la task nella lista.
                    </p>
                </IonPopover> */}

                <IonPopover
                    isOpen={this.state.showAddVideoHelp}
                    onDidDismiss={() => this.setState({ showAddVideoHelp: false })}
                    className="taskEditorAddCoverHelpPopover"
                    event={this.state.popoverEvent}
                    showBackdrop={false}
                >
                    <p
                        className="taskEditorAddCoverHelpParagraph"
                    >
                        Aggiungere un video può essere utile quando si utilizza la tecnica del video modeling.{/* TO LOCALIZE THIS */}
                    </p>
                </IonPopover>

                <IonPopover
                    backdropDismiss={false}
                    isOpen={this.state.showMediaPopover}
                    onDidDismiss={() => {
                        this.setState({ showMediaPopover: false })
                    }}
                    className='taskEditorPopover'
                    mode="md"
                >
                    <IonIcon onClick={() => {
                        if (this.state.didChangeMedia)
                            this.setState({ showDiscardAlert: true })
                        else {
                            if (this.state.uploadedMediaIds.length > 0) {
                                //console.log(this.state.uploadedMediaIds)
                                this.state.uploadedMediaIds.forEach(id => {
                                    if (id === storageRef(storage, this.state.currentStep.imageGsUrl).name || id === storageRef(storage, this.state.currentStep.videoGsUrl).name)
                                        return
                                    deleteObject(storageRef(storage, `users-media/${auth.currentUser.uid}/tasks/${this.state.task.uuid}/${id}`))
                                    this.state.uploadedMediaIds.splice(this.state.uploadedMediaIds.indexOf(id), 1)

                                });
                            }
                            this.setState({ showMediaPopover: false })
                        }
                    }}
                        icon={closeOutline}
                        size='large'
                        style={{ right: '0', position: 'absolute', cursor: 'pointer' }} />
                    {this.state.currentStep &&
                        <>
                            <h1 className='taskEditorPopoverTitle'>
                                Modifica lo step <i>"{this.state.currentStep.title}"</i>
                            </h1>
                            <IonGrid className='taskEditorPopoverMainGrid'>
                                <IonRow className='taskEditorPopoverRow' >
                                    {this.state.currentStep.imageGsUrl &&
                                        <img
                                            className='taskEditorPopoverImage'
                                            src={this.state.currentStep.imageGsUrl}
                                            alt={this.state.currentStep.title}
                                        />
                                    }
                                    {this.state.currentStep.videoGsUrl &&
                                        <video controls className='taskEditorPopoverVideo' src={this.state.currentStep.videoGsUrl} />
                                    }
                                </IonRow>
                                <IonRow className='taskEditorPopoverRow'>
                                    <IonButton
                                        color="danger"
                                        className='taskEditorPopoverButton'
                                        onClick={() => {
                                            if (this.state.currentStep.imageGsUrl !== null)
                                                this.removeMedia(this.state.currentStep.imageGsUrl)
                                            else if (this.state.currentStep.videoGsUrl !== null)
                                                this.removeMedia(this.state.currentStep.videoGsUrl)

                                            this.setState({ showMediaPopover: false })
                                        }}>
                                        Rimuovi media
                                    </IonButton>
                                </IonRow>
                                <IonRow className='taskEditorPopoverRow'>
                                    <IonButton
                                        className='taskEditorPopoverButton'
                                        onClick={() => {
                                            (document.getElementById("utilsFileInputChange") as HTMLInputElement).click();
                                        }}>
                                        Cambia Contenuto
                                    </IonButton>
                                </IonRow>
                                <IonRow className='taskEditorPopoverRow'>
                                    <IonButton
                                        className='taskEditorPopoverButton'
                                        onClick={() => {
                                            if (!this.state.didChangeMedia) {
                                                this.setState({ showMediaPopover: false });
                                                //console.log('User did not edit');
                                            }
                                            else {
                                                //remove the uploaded ones
                                                this.state.uploadedMediaIds.forEach(id => {
                                                    if (id === storageRef(storage, this.state.currentStep.imageGsUrl).name || id === storageRef(storage, this.state.currentStep.videoGsUrl).name)
                                                        return
                                                    deleteObject(storageRef(storage, `users-media/${auth.currentUser.uid}/tasks/${this.state.task.uuid}/${id}`))

                                                });
                                                //remove the original
                                                deleteObject(storageRef(storage, this.state.originalMedia.url))
                                                this.setState({ didChangeMedia: false, showMediaPopover: false })
                                                this.updateStepOnDb();

                                            }
                                        }}>
                                        Aggiorna
                                    </IonButton>
                                </IonRow>
                                <input
                                    style={{ position: "absolute", top: "-100px" }}
                                    id="utilsFileInputChange"
                                    type={"file"}
                                    accept="image/png, image/gif, image/jpeg, video/mp4"
                                    onChange={e => {
                                        this.uploadMedia((e.nativeEvent.target as HTMLInputElement).files?.item(0), true);
                                        this.setState({ didChangeMedia: true })
                                    }}
                                />
                            </IonGrid>
                        </>
                    }
                </IonPopover>

                <IonAlert
                    isOpen={this.state.showDiscardAlert}
                    header='Attenzione'
                    message={`Salvare le modifiche allo step ${this.state.currentStep?.title} ?`}
                    buttons={[
                        {
                            text: 'Salva',
                            handler: () => {
                                this.state.uploadedMediaIds.forEach(id => {
                                    if (id === storageRef(storage, this.state.currentStep.imageGsUrl).name || id === storageRef(storage, this.state.currentStep.videoGsUrl).name)
                                        return
                                    deleteObject(storageRef(storage, `users-media/${auth.currentUser.uid}/tasks/${this.state.task.uuid}/${id}`))

                                });
                                //remove the original
                                deleteObject(storageRef(storage, this.state.originalMedia.url))
                                this.setState({ didChangeMedia: false, showMediaPopover: false })
                                this.updateStepOnDb();
                            }
                        },
                        {
                            text: 'Non salvare',
                            handler: () => {
                                let tempStep = this.state.currentStep;
                                //console.log("Non salvare, step prima", tempStep);

                                let tempTask = this.state.task;
                                if (this.state.originalMedia.type === 'video')
                                    tempStep.videoGsUrl = this.state.originalMedia.url
                                else if (this.state.originalMedia.type === 'image')
                                    tempStep.imageGsUrl = this.state.originalMedia.url
                                tempTask.steps[tempTask.steps.indexOf(tempStep)] = tempStep;
                                //console.log("UPDATED TASK", tempTask);

                                this.setState({ task: tempTask })
                                this.setState({ showMediaPopover: false, showDiscardAlert: false, didChangeMedia: false, currentStep: null })
                                if (this.state.uploadedMediaIds.length > 0) {
                                    this.deleteUnusedImages();
                                }
                            }
                        },
                        {
                            text: 'Annulla',
                            handler: () => {
                                this.setState({ showDiscardAlert: false })
                                return
                            }
                        }
                    ]}
                />
                <IonLoading
                    backdropDismiss={false}
                    isOpen={this.state.isLoading}
                    onDidDismiss={() => {
                        this.setState({ isLoading: false });
                    }}
                    message={"Attendere prego..."} /* TO LOCALIZE THIS */
                    duration={10000}
                />

            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        autonomiesList: state.autonomies.list,
        currentChild: state.children.currentChild,
        userData: state.auth.userData
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutonomyEditor);