import React from 'react';
import { IonApp, isPlatform } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import 'theme/variables.css';

import { Provider as StoreProvider } from 'react-redux';
import { store, persistor } from './common/store';
import { PersistGate } from 'redux-persist/integration/react'
import pushNotificationHandler from 'common/pushNotificationHandler'
import magicLinkHandler from 'common/magicLinkHandler'
import Router from 'Router';
import { SplashScreen } from '@capacitor/core';
import MessageOverlay from 'modules/core/components/MessageOverlay';
import LoadingSpinner from 'modules/core/components/LoadingSpinner';
import CookieAlert from 'modules/legal/cookieManager/components/CookieAlert';
import TagManager from 'react-gtm-module';
import Authentication from 'firebase';
import "firebase/remote-config";
import "firebase/analytics";
import storeHandler from 'common/storeHandler';
import { setupIonicReact } from '@ionic/react';

setupIonicReact({})

declare global {
  interface Window { analytics: any }
}

class App extends React.Component {

  componentDidMount() {
    pushNotificationHandler();
    magicLinkHandler();
    storeHandler();

    const tagManagerArgs = { gtmId: 'GTM-WDMTW5Q' };
    TagManager.initialize(tagManagerArgs);
    SplashScreen.hide();
  }


  render() {
    return (
      <StoreProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <IonApp>
            <Authentication />
            {
              !(isPlatform('capacitor') || isPlatform('cordova')) &&
              <CookieAlert />
            }
            <Router />
            <LoadingSpinner />
            <MessageOverlay />

          </IonApp>
        </PersistGate>
      </StoreProvider>
    );
  };
}
export default App;
