import React from "react";
import {
    IonIcon, IonItem, IonList, IonPopover, IonTitle,
} from "@ionic/react";
import { connect } from 'react-redux';
import { Child } from "../store/reducers";
import { childrenActions } from "../store/actions";
import { closeOutline } from "ionicons/icons";
import "./ChildrenSwitch.css";
import { auth } from "firebase";

type Props = {
    isOpen: boolean,
    childrenList: Child[],

    setCurrentChild: (child: Child | null, userUuid: string) => void,

    onDidDismiss?: () => void,
    onSelectedChild?: (Child: Child) => void,
}

type State = {
    userUuid: string | null,
}

class ChildrenSwitch extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            userUuid: null,
        }
    }

    componentDidMount(): void {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ userUuid: userData.uid });
            }
            else {
                this.setState({ userUuid: null });
            }
        })
    }

    render() {
        return (
            <IonPopover
                isOpen={this.props.isOpen}
                onDidDismiss={() => {
                    if (this.props.onDidDismiss) {
                        this.props.onDidDismiss();
                    }
                }}
                className="childrenSwitchPopover"
                mode="md"
            >
                <IonIcon
                    onClick={() => {
                        this.props.onDidDismiss();
                    }}
                    icon={closeOutline}
                    size='large'
                    style={{ right: '0', position: 'absolute', cursor: 'pointer' }} />

                <IonTitle
                    className="childrenSwitchPopoverTitle"
                >
                    Cambia scheda di lavoro {/* TO LOCALIZE THIS */}
                </IonTitle>
                <IonList>
                    {
                        this.props.childrenList &&
                        this.props.childrenList.map(child => {
                            return (
                                <IonItem
                                    key={child.uuid}
                                    className="childrenSwitchChildListItem"
                                    onClick={() => {
                                        this.props.setCurrentChild(child, this.state.userUuid);
                                        if (this.props.onSelectedChild) {
                                            this.props.onSelectedChild(child);
                                        }
                                        this.props.onDidDismiss()
                                    }}
                                >
                                    {/* <IonImg
                                        className={"childrenSwitchChildAvatarImg"}
                                        src={`https://avataaars.io/?avatarStyle=Transparent`}
                                    /> */}
                                    <p>
                                        {`${child.firstName ? child.firstName : ""} ${child.lastName ? child.lastName : ""}`}
                                    </p>
                                </IonItem>
                            )
                        })
                    }
                </IonList>

            </IonPopover>
        );
    }
}

const mapStateToProps = state => {
    return {
        childrenList: state.children.list,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentChild: (child: Child | null, userUuid: string) => {
            dispatch(childrenActions.setCurrentChild(child, userUuid))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenSwitch);