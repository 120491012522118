
import { IonGrid, IonRow, IonSlide } from '@ionic/react';
import React from 'react';
// animazione: https://lottiefiles.com/47229-the-reader
import exportImg from '../../../assets/icons/export.svg';
import exportDark from '../../../assets/icons/exportDark.svg';
import './PurchaseSlide.css';

type Props = {
}

type State = {
}

class PurchaseExport extends React.Component<Props, State> {

    render() {

        return (
            <IonSlide className='purchaseSlide'>

                <IonGrid className='onboardingSlideGrid'>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <picture className='purchaseSlidePicture'>
                            <source
                                srcSet={exportDark}
                                media="(prefers-color-scheme:dark)" />
                            <img
                                src={exportImg}
                                alt=""
                            />
                        </picture>
                    </div>
                    <IonRow className='onboardingSlideHeaderRow'>
                        <h2 className='onboardingSlideHeader'>
                            Esportazione dati avanzata
                        </h2>
                    </IonRow>
                    <IonRow className='onboardingSlideBodyRow'>
                        <p className='onboardingSlideBodyParagraph'>
                            Esporta i dati in formato xlsx {/* TO LOCALIZE THIS */}
                        </p>
                    </IonRow>
                </IonGrid>

            </IonSlide>
        );
    }
}

export default (PurchaseExport);