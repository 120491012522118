
import {
    IonGrid, IonRow, IonSlide
} from '@ionic/react';
import React from 'react';
// animazione: https://lottiefiles.com/47229-the-reader
import modeling from '../../../assets/icons/modeling.svg';
import modelingDark from '../../../assets/icons/modelingDark.svg';
import './PurchaseSlide.css';

type Props = {
}

type State = {
}

class PurchaseModeling extends React.Component<Props, State> {

    render() {

        return (
            <IonSlide className='purchaseSlide'>
                <IonGrid /* className='onboardingSlideGrid' */>

                    <IonRow className='onboardingSlideAnimationRow'>
                    </IonRow>
                    
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <picture className='purchaseSlidePicture'>
                            <source
                                srcSet={modelingDark}
                                media="(prefers-color-scheme:dark)" />
                            <img
                                src={modeling}
                                alt=""
                            />
                        </picture>
                    </div>

                    <IonRow className='onboardingSlideHeaderRow'>
                        <h2 className='onboardingSlideHeader'>
                            Utilizza la tecnica del Video Modeling
                        </h2>
                    </IonRow>
                    <IonRow className='onboardingSlideBodyRow'>
                        <p className='onboardingSlideBodyParagraph'>
                            Associa immagini e video alle tue task {/* TO LOCALIZE THIS */}
                        </p>
                    </IonRow>
                </IonGrid>

                
            </IonSlide>
        );
    }
}

export default (PurchaseModeling);