import React from "react";
import {
    IonCard, IonInput, IonPopover, IonButton, IonIcon,
} from "@ionic/react";
import { connect } from 'react-redux';
import { Child } from "modules/children/store/reducers";
import { pbServices } from "../store/services";
import './PBCreation.css';
import { closeOutline } from "ionicons/icons";

type Props = {
    isOpen: boolean,
    /* history: any, */
    currentChild: Child | null,
    /* currentAutonomy: Autonomy, */
    onDidDismiss?: () => void,
    onSubmit: () => void,
    onPbCreated: (newPbList) => void,
}

type State = {
    title: string,
}

class PBCreation extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
        }
    }

    render() {
        return (
            <IonPopover
                isOpen={this.props.isOpen}
                onDidDismiss={() => {
                    if (this.props.onDidDismiss) {
                        this.props.onDidDismiss()
                    }
                }}
                className="pbCreationPopover"
                mode="md"
            >
                <IonIcon onClick={() => this.props.onDidDismiss()} icon={closeOutline} size='large' style={{ right: '0', position: 'absolute', cursor: 'pointer' }} />

                <div className="pbCreationMainDiv">
                    <p
                        className="pbCreationPopoverTitle"
                    >
                        Aggiungi comportamento problema
                    </p>

                    <IonCard>
                        <IonInput
                            placeholder='Inserisci il titolo del problema'
                            value={this.state.title}
                            onIonChange={(e) => {
                                this.setState({ title: e.detail.value });
                            }}
                        >
                        </IonInput>
                    </IonCard>

                    <IonButton
                        disabled={this.state.title === ""}
                        onClick={() => {
                            this.props.onSubmit();
                            if (this.props.currentChild) {
                                pbServices.createPb(this.state.title, this.props.currentChild.uuid, true)
                                    .then((newPbList) => {

                                        this.props.onPbCreated(newPbList);
                                    })
                                    .catch(err => {
                                        //console.log("[PBCreation] error creating problem behavior:", err);
                                    })
                            }
                        }}
                    >
                        Crea
                    </IonButton>
                </div>
            </IonPopover>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentChild: state.children.currentChild,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PBCreation);