import React from "react";
import {
    IonTitle, IonContent, IonHeader, IonToolbar, IonPage,
    IonGrid, IonIcon, IonRow, IonButton, IonText,
} from "@ionic/react";
import { connect } from 'react-redux';
import { Task } from "modules/tasks/store/reducers";
import { Child } from "modules/children/store/reducers";
import FamilyMenuTabBar from "modules/user/pages/FamilyMenuTabBar";
import ChildrenSwitchButton from "modules/children/components/ChildrenSwitchButton";
import ChildrenSwitch from "modules/children/components/ChildrenSwitch";
import { tasksServices } from "modules/tasks/store/services";
import { analytics, barChartOutline, timer } from "ionicons/icons";
import { User } from "modules/user/store/reducers";
import TaskSkeleton from "../components/TaskSkeleton";
import { auth } from "firebase";
import NotificationsBanner from "modules/tasks/components/NotificationsBanner";
import { isPlatform } from "@ionic/core";
import { userService } from "modules/user/store/services";
import { Device } from "@ionic-native/device";
import { Player } from "@lottiefiles/react-lottie-player";
import loadingAnimation from "../../../assets/animations/waitingDots.json";
import "./DataAnalysisHome.css";
import Tutorial from "../../../common/In-App Tutorial/Tutorial";
import { dataCollectionHomeSteps } from "common/tutorialSteps";

type Props = {
    currentChild: Child | null,
    history: any,
    userData: User
}

type State = {
    autonomiesList: Task[],
    showChildSwitch: boolean,

    addDeviceBannerVisible: boolean,

    isLoadingTasks: boolean,

    isNewUser: boolean,
}

class DataCollectionHome extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            autonomiesList: [],
            showChildSwitch: false,

            addDeviceBannerVisible: false,

            isLoadingTasks: false,

            isNewUser: false,
        };
    }

    componentDidMount() {
        auth.onAuthStateChanged(user => {
            if (user) {
                if ((isPlatform('cordova') || isPlatform('capacitor'))) {
                    userService.fetchDevices(user.uid)
                    .then(devices => {
                        const filteredDevices = devices.filter(device => { return device.deviceId === Device.uuid })
                        if (filteredDevices.length < 1) {
                            this.setState({ addDeviceBannerVisible: true });
                        }
                    })
                    .catch(err => {
                        console.error("[TasksList] fetch devices error:", err); // TO REMOVE
                    });
                }

                if (this.props.currentChild && this.props.currentChild.uuid) {
                    this.getTasksList(this.props.currentChild.uuid);
                }

                if (user.metadata.creationTime) {
                    const nowDate = new Date();
                    const tempDate = new Date(user.metadata.creationTime);
                    tempDate.setMinutes(tempDate.getMinutes() + 5);
                    if (tempDate > nowDate) {
                        this.setState({ isNewUser: true });
                        setInterval(() => {
                            if (this.state.autonomiesList && (this.state.autonomiesList.length < 1) && this.props.currentChild && this.props.currentChild.uuid) {
                                this.getTasksList(this.props.currentChild.uuid);
                            }
                        }, 2000);
                    }
                    else {
                        setInterval(() => {
                            if (this.props.currentChild && this.state.autonomiesList && (this.state.autonomiesList.length < 1) && this.props.currentChild.uuid) {
                                this.getTasksList(this.props.currentChild.uuid);
                            }
                        }, 2000);
                    }
                }
                else {
                    setInterval(() => {
                        if (this.props.currentChild && this.state.autonomiesList && (this.state.autonomiesList.length < 1) && this.props.currentChild.uuid) {
                            this.getTasksList(this.props.currentChild.uuid);
                        }
                    }, 2000);
                }
            }
        });
    }

    getTasksList(childUuid: string) {
        this.setState({ isLoadingTasks: true });
        tasksServices.fetchAutonomiesList(childUuid)
            .then(tasks => {
                //console.log("[DataCollectionHome] fetch autonomies list:", autonomies);
                this.setState({
                    isLoadingTasks: false,
                    autonomiesList: tasks,
                })
            })
            .catch(err => {
                //console.log("[DataCollectionHome] fetch autonomies list error:", err);
                this.setState({ isLoadingTasks: false });
            })
    }

    render() {
        return (

            <IonPage>

                <IonHeader mode="md">
                    <IonToolbar>
                        <IonTitle>
                            Presa dati {/* TO LOCALIZE THIS */}
                        </IonTitle>
                        <Tutorial hidden={this.state.isLoadingTasks} steps={dataCollectionHomeSteps} />

                        <ChildrenSwitchButton
                            slot="end"
                            onClick={() => {
                                this.setState({ showChildSwitch: true });
                            }}
                        />
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    {
                        this.state.addDeviceBannerVisible &&
                        <NotificationsBanner
                            onPermissionGranted={() => {
                                this.setState({ addDeviceBannerVisible: false });
                            }}
                        />
                    }

                    <IonGrid className="dataCollectionHomeContentGrid">
                        {
                            this.state.isNewUser &&
                            (!this.props.currentChild) &&
                            <div className="dataAnalysisHomeLoadingDiv">
                                <div>
                                    <Player
                                        autoplay
                                        loop
                                        src={loadingAnimation}
                                    />
                                </div>
                                <p className="dataAnalysisHomeLoadingText">
                                    Stiamo preparando il tuo ambiente di lavoro 🙂 {/* TO BE LOCALIZED */}
                                </p>
                            </div>


                        }

                        {
                            this.props.currentChild &&
                            <div className="dataAnalysisHomeCard">
                                <p className="dataAnalysisHomeCardTitle">
                                    Scegli la task di cui raccogliere dati {/* TO LOCALIZE THIS */}
                                </p>
                                <div className="dataAnalysisHomeCardContentDiv">
                                    {
                                        this.state.isLoadingTasks &&
                                        <div>
                                            <TaskSkeleton skeletonNumber={5} buttonNumber={3} />
                                            {/* <IonSpinner /> */}
                                        </div>
                                    }

                                    {
                                        !this.state.isLoadingTasks &&
                                        this.state.autonomiesList.length === 0 &&
                                        <IonRow
                                            className="dataCollectionHomeCreateNewTaskCTARow"
                                            onClick={() => {
                                                this.props.history.push("/tasks/edit");
                                            }}
                                        >
                                            <IonText>
                                                Non sono presenti task di cui effettuare la presa dati. {/* TO LOCALIZE THIS */}
                                                Per crearne una, fai tap qui.
                                            </IonText>
                                        </IonRow>
                                    }

                                    <div style={{ marginTop: "20px" }}>
                                        {
                                            !this.state.isLoadingTasks &&
                                            this.state.autonomiesList.map(task => {
                                                return (
                                                    <div
                                                        key={task.uuid}
                                                        className="dataAnalysisTaskItemDiv"
                                                    >

                                                        <div className="dataAnalysisTaskTitleDiv">
                                                            <p
                                                                className="dataCollectionItemTitle"
                                                                key={task.uuid}
                                                                onClick={() => {
                                                                    this.props.history.push(`/data-collection/collect?task=${task.uuid}`);
                                                                }}
                                                            >

                                                                {task.title}
                                                            </p>
                                                        </div>

                                                        <div className="dataAnalysisTaskButtonsMainDiv">

                                                            <div className="dataCollectionHomeButtonsDiv">
                                                                <IonRow
                                                                    className="dataCollectionGraphButtonRow viewSessionsRow"
                                                                    slot="end"
                                                                    onClick={() => {
                                                                        window.analytics.track({
                                                                            "event": "View session",
                                                                            "properties": {
                                                                                "category": "View Session",
                                                                                "label": auth.currentUser.uid,
                                                                            }
                                                                        })
                                                                        this.props.history.push(`/tasks/sessions?task=${task.uuid}`);
                                                                    }}
                                                                >
                                                                    <IonButton
                                                                        fill="clear"
                                                                    >
                                                                        <IonIcon
                                                                            className="dataAnalysisHomeButtonIcon"
                                                                            icon={timer}
                                                                            color='secondary'
                                                                        />

                                                                    </IonButton>
                                                                    <p className="dataCollectionButtonLabelParagraph">
                                                                        Visualizza<br />sessioni  {/* TO LOCALIZE THIS */}
                                                                    </p>
                                                                </IonRow>

                                                                <IonRow
                                                                    className="dataCollectionGraphButtonRow viewChartRow"
                                                                    slot="end"
                                                                    onClick={() => {
                                                                        window.analytics.track({
                                                                            "event": "View Chart",
                                                                            "properties": {
                                                                                "category": "Chart",
                                                                                "label": auth.currentUser.uid,
                                                                            }
                                                                        })
                                                                        this.props.history.push(`/data-collection/chart?task=${task.uuid}`);
                                                                    }}
                                                                >
                                                                    <IonButton
                                                                        fill="clear"
                                                                    >
                                                                        <IonIcon
                                                                            className="dataAnalysisHomeButtonIcon"
                                                                            icon={barChartOutline}
                                                                            color='secondary'
                                                                        />

                                                                    </IonButton>
                                                                    <p className="dataCollectionButtonLabelParagraph viewChartParagraph">
                                                                        Visualizza<br />grafico  {/* TO LOCALIZE THIS */}
                                                                    </p>
                                                                </IonRow>

                                                                <IonRow
                                                                    className="dataCollectionGraphButtonRow dataCollectionButtonRow"
                                                                    slot="end"
                                                                    onClick={() => {
                                                                        this.props.history.push(`/data-collection/collect?task=${task.uuid}`);
                                                                    }}
                                                                >
                                                                    <IonButton
                                                                        fill="clear"
                                                                    >
                                                                        <IonIcon
                                                                            className="dataAnalysisHomeButtonIcon"
                                                                            icon={analytics}
                                                                        />

                                                                    </IonButton>
                                                                    <p id='dataCollection' className="dataCollectionButtonLabelParagraph dataCollectionParagraph">
                                                                        <b>
                                                                            Presa<br />dati  {/* TO LOCALIZE THIS */}
                                                                        </b>
                                                                    </p>
                                                                </IonRow>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div>
                            </div>
                        }
                    </IonGrid>
                </IonContent>
                <FamilyMenuTabBar />

                <ChildrenSwitch
                    isOpen={this.state.showChildSwitch}
                    onDidDismiss={() => {
                        this.setState({
                            showChildSwitch: false,
                        })
                    }}
                    onSelectedChild={(child) => {
                        this.getTasksList(child.uuid);
                    }}
                />

            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentChild: state.children.currentChild,
        userData: state.auth.userData
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataCollectionHome);