import { Plugins } from "@capacitor/core";
import { IonGrid, IonImg, IonRow } from "@ionic/react";
import React from "react";
import { connect } from 'react-redux';
import notificationsImage from '../../../assets/icons/notify.svg';
import "./NotificationsBanner.css";

const { PushNotifications } = Plugins;

type Props = {
    onPermissionGranted: () => void,
}

type State = {
}

class NotificationsBanner extends React.Component<Props, State> {

    render() {
        return (
            <IonGrid
                className="activateNotificationsGrid"
                onClick={() => {
                    PushNotifications.requestPermission()
                        .then(result => {
                            if (result.granted) {
                                // Register with Apple / Google to receive push via APNS/FCM
                                PushNotifications.register()
                                    .then(() => {
                                        this.props.onPermissionGranted();
                                    })
                                    .catch(err => {
                                        //console.log("[NotificationsBanner] error registering push notifications", err);
                                    });
                            } else {
                                //console.log('[pushNotificationHandler - requestPermission] not granted:', JSON.stringify(result))
                            }
                        })
                        .catch(error => {
                            //console.log('[push notification requestPermission] error:', error)
                        });
                }}
            >
                <IonRow className="activateNotificationsRow">
                    <IonImg
                        class='activateNotificationsImage'
                        src={notificationsImage}
                    />
                </IonRow>

                <IonRow className="activateNotificationsRow">
                    <p className='activateNotificationCtaText'>
                        Consenti l'invio di notifiche. Ti invieremo notifiche per:
                        <ul>
                            <li>Aggiornarti sui tentativi di accesso al tuo account</li>
                            <li>Avvisarti se qualche professionista o genitore ti invita ad accedere ad una scheda di lavoro</li>
                            <li>Aggiornarti su miglioramenti nell'uso di Maestro</li>
                        </ul>
                    </p>
                </IonRow>
            </IonGrid>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsBanner);