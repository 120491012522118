import React from "react";
import { initializeApp } from 'firebase/app';
import { getAuth, indexedDBLocalPersistence, initializeAuth, User } from 'firebase/auth';
import { getDatabase, ref, get, update, push, onValue } from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";
import { connect } from "react-redux";
import { getStorage } from "@firebase/storage";
import { authActions } from "modules/authentication/store/actions";
import { isPlatform } from "@ionic/core";
import { childrenService } from "modules/children/store/services";
import { childrenActions } from "modules/children/store/actions";
import { Child } from "modules/children/store/reducers";
import { appVersion } from "appVersion";
import { browserName, deviceType, fullBrowserVersion, isMobile, mobileModel, mobileVendor, osName, osVersion } from "react-device-detect";

export const firebaseConfig = {
	apiKey: "AIzaSyCWDwH6QnDCdUzfX1pwJSSnIwsJer8_wMY",
	authDomain: "estro-maestro.firebaseapp.com",
	databaseURL: "https://estro-maestro.firebaseio.com",
	projectId: "estro-maestro",
	storageBucket: "estro-maestro.appspot.com",
	messagingSenderId: "258050009074",
	appId: "1:258050009074:web:764ca18754b43ef019ed6b",
	measurementId: "G-62P02GV032"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

export const auth =
	isPlatform("ios") ?
		initializeAuth(app, {
			persistence: indexedDBLocalPersistence
		}) :
		getAuth();
export const database = getDatabase();
export const functions = getFunctions(undefined, "europe-west1");
export const storage = getStorage();


type Props = {
	authenticationSuccess: (userData: User) => void,
	logout: () => void,

	setChildren: (children: Child[]) => void,
	setCurrentChild: (child: Child | null, userUuid: string) => void,
};

type State = {
};

class Authentication extends React.Component<Props, State> {

	componentDidMount() {
		auth.onAuthStateChanged(async (userData) => {
			//console.log("[firebase] User data", userData); // FOR DEBUGGING
			if (userData !== null) {
				this.props.authenticationSuccess(userData);
				// get user data 
				get(ref(database, `u/${userData.uid}`))
					.then(data => {
						if (!data.val() || !data.val()["e"]) {
							update(ref(database, `u/${userData.uid}`), {
								e: userData.email,
							})
						}
					})
					.catch(err => {
						console.error("[firebase.tsx] error getting user data:", err);
					});

				get(ref(database, `p/${userData.uid}`))
					.then(data => {
						if (!data.exists() || !data.val()["e"]) {
							update(ref(database, `p/${userData.uid}`), {
								e: userData.email,
							})
						}
					})
					.catch(err => {
						console.error("[firebase.tsx] error getting profile data:", err);
					});

				const nowTimestamp = Math.round(new Date().getTime() / 1000);
				let deviceInfo = {
					t: deviceType,
					o: osName + " - " + osVersion,
					b: browserName + " - " + fullBrowserVersion,
					m: isMobile ? mobileVendor + " - " + mobileModel : null,
				}
				push(ref(database, `l/u/${userData.uid}/a`), {
					t: nowTimestamp,
					v: appVersion,
					d: deviceInfo,
				})
					.then(() => {
						//console.log("[firebase] a. push success");
					})
					.catch(err => {
						//console.log("[firebase] error logging:", err);
					})

				const currentSelectedChildRef = ref(database, `u/${userData.uid}/c`)
				onValue(currentSelectedChildRef, async (data) => {
					try {
						const userChildren = await childrenService.fetchChildren();
						this.props.setChildren(userChildren);

						const currentChildUuid: string | null = data.val();

						if (currentChildUuid) {
							const filteredChildren = userChildren.filter(child => { return child.uuid === currentChildUuid });
							if (filteredChildren.length > 0) {
								/* //console.log("[firebase] A"); */ // FOR DEBUGGING
								this.props.setCurrentChild(filteredChildren[0], userData.uid);
							}
							else {
								if (userChildren.length > 0) {
									/* //console.log("[firebase] B"); */ // FOR DEBUGGING
									this.props.setCurrentChild(userChildren[0], userData.uid);
								}
								else {
									/* //console.log("[firebase] C"); */ // FOR DEBUGGING
									this.props.setCurrentChild(null, userData.uid);
								}
							}

						}
						else {
							if (userChildren.length > 0) {
								/* //console.log("[firebase] D"); */ // FOR DEBUGGING
								this.props.setCurrentChild(userChildren[0], userData.uid);
							}
							else {
								/* //console.log("[firebase] E"); */ // FOR DEBUGGING
								this.props.setCurrentChild(null, userData.uid);
							}
						}
					}
					catch (e) {

					}
				});

				const updateProductsState = httpsCallable(functions, "updateProductsState-updateProductsState");
				updateProductsState()
					.then((res) => {
						//console.log("[storeHandler] updateProductsState response data:", res.data);
					})
					.catch(err => {
						console.error("[storeHandler] updateProductsState error calling cloud function:", err);
					});

			}
			else {
				this.props.logout();
				setTimeout(() => {
					if (window.location.pathname !== '/authentication/get-started')
						window.location.replace('/authentication/get-started');
				}, 1000);
				//console.log("We did not authenticate.");
			}
		});
	}

	render() {
		return (
			<></>
		);
	}
}


const mapDispatchToProps = (dispatch: any) => {
	return {
		authenticationSuccess: (userData: User) => {
			dispatch(authActions.authenticationSuccess(userData));
		},

		logout: () => {
			dispatch(authActions.logout());
		},

		setChildren: (children: Child[]) => {
			dispatch(childrenActions.setChildren(children));
		},

		setCurrentChild: (child: Child | null, userUuid: string) => {
			dispatch(childrenActions.setCurrentChild(child, userUuid));
		}
	}
}

export default connect(null, mapDispatchToProps)(Authentication);