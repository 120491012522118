import React from "react";
import {
    IonPage, IonTitle, IonContent, IonIcon, IonHeader,
    IonToolbar, IonButton, IonGrid, IonChip, IonCard, IonImg, IonCardHeader, IonCardTitle, IonCardContent,
} from "@ionic/react";
import { happy } from "ionicons/icons";
import { connect } from 'react-redux';
import FamilyMenuTabBar from "modules/user/pages/FamilyMenuTabBar";
import { blogService } from "../store/services";
import { Category, Post } from "../store/reducers";
import './BlogHome.css';

type Props = {
    history: any,
}

type State = {
    posts: Post[],
    categories: Category[],
    selectedCategories: number[],
}

class BlogHome extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            categories: [],

            selectedCategories: [],
        }
    }

    componentDidMount() {
        this.getPosts();

        blogService.getCategories()
            .then(categories => {
                this.setState({ categories: categories });
            })
            .catch((err) => {
                //console.log('[BlogHome] getting categories error:', err);
            })
    }

    getPosts(categories?: number[]) {
        blogService.getPosts(categories)
            .then(posts => {
                this.setState({ posts: posts });
            })
            .catch((err) => {
                //console.log('[BlogHome] getting posts error:', err);
            })
    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonTitle>Blog</IonTitle>
                        <IonButton
                            shape='round'
                            slot='end'
                        >
                            <IonIcon
                                icon={happy}
                                size='large'
                            />
                        </IonButton>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className='blogHomeContentGrid'>
                        <div className='blogHomeChipDiv'>
                            {
                                this.state.categories.map(category => {
                                    return (
                                        <IonChip
                                            key={category.uuid}
                                            onClick={() => {
                                                const newArray = this.state.selectedCategories.slice();

                                                if (this.state.selectedCategories.includes(category.uuid)) {
                                                    var index = newArray.indexOf(category.uuid);
                                                    if (index !== -1) {
                                                        newArray.splice(index, 1);
                                                    }
                                                }
                                                else {
                                                    newArray.push(category.uuid);
                                                }

                                                this.setState({ selectedCategories: newArray }, () => {
                                                    this.getPosts(this.state.selectedCategories);
                                                });
                                            }}
                                            className={this.state.selectedCategories.includes(category.uuid) ? 'blogHomeCategorySelectedChip' : 'blogHomeCategoryChip'}
                                        >
                                            {category.name}
                                        </IonChip>
                                    )
                                })
                            }
                        </div>

                        {
                            this.state.posts.map(post => {
                                return (
                                    <IonCard
                                        key={post.uuid}
                                        onClick={() => {
                                            this.props.history.push(`/blog/${post.uuid}`)
                                        }}
                                    >
                                        <IonImg
                                            src={post._embedded["wp:featuredmedia"].length > 0 ? post._embedded["wp:featuredmedia"][0].source_url : null}
                                        />
                                        <IonCardHeader>
                                            <IonCardTitle>{post.title.rendered}</IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}>
                                            </div>
                                        </IonCardContent>
                                    </IonCard>
                                )
                            })
                        }
                    </IonGrid>
                </IonContent>
                <FamilyMenuTabBar />
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogHome);