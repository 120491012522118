
import { IonGrid, IonRow, IonSlide } from '@ionic/react';
import React from 'react';
// animazione: https://lottiefiles.com/47229-the-reader
import share from '../../../assets/icons/share.svg';
import './PurchaseSlide.css';

type Props = {
}

type State = {
}

class PurchaseShare extends React.Component<Props, State> {

    render() {

        return (
            <IonSlide className='purchaseSlide'>
                <IonGrid className='onboardingSlideGrid'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <picture className='purchaseSlidePicture'>
                            <source
                                srcSet={share}
                                media="(prefers-color-scheme:dark)"
                            />
                            <img
                                src={share}
                                alt=""
                            />
                        </picture>

                    </div>
                    <IonRow className='onboardingSlideHeaderRow'>
                        <h2 className='onboardingSlideHeader'>
                            Collaborazione sulle schede di lavoro
                        </h2>
                    </IonRow>
                    <IonRow className='onboardingSlideBodyRow'>
                        <p className='onboardingSlideBodyParagraph'>
                            Condividi le tue schede di lavoro con chi vuoi (Tecnici, Assistenti e Analisti del Comportamento, genitori, docenti) {/* TO LOCALIZE THIS */}
                        </p>
                    </IonRow>
                </IonGrid>
            </IonSlide>
        );
    }
}

export default (PurchaseShare);