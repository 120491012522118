import {
    IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonItem,
    IonList, IonLoading, IonPage, IonTitle, IonToolbar
} from "@ionic/react";
import { addOutline, radioButtonOn } from "ionicons/icons";
import ChildrenSwitch from "modules/children/components/ChildrenSwitch";
import ChildrenSwitchButton from "modules/children/components/ChildrenSwitchButton";
import { childrenActions } from "modules/children/store/actions";
import { Child } from "modules/children/store/reducers";
import React from "react";
import { connect } from 'react-redux';
import FamilyMenuTabBar from "./FamilyMenuTabBar";
import { auth, functions } from "firebase";
import { httpsCallable } from "firebase/functions";
import './Children.css';
import Tutorial from "common/In-App Tutorial/Tutorial";
import { childrenSteps } from "common/tutorialSteps";

type Props = {
    history: any,
    currentChild: Child | null,
    childrenList: Child[],

    setCurrentChild: (child: Child, userUuid: string) => void,
}

type State = {
    showChildSwitch: boolean,

    isLoading: boolean,
    childCreationLoading: boolean,
    userUuid: string | null,
}

class Children extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            showChildSwitch: false,

            isLoading: false,
            childCreationLoading: false,
            userUuid: null,
        }
    }

    componentDidMount(): void {
        auth.onAuthStateChanged(user => {
            if (user) {
                this.setState({ userUuid: user.uid });
            }
            else {
                this.setState({ userUuid: null });
            }
        })
    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonTitle>Gestione delle schede</IonTitle> {/* TO LOCALIZE THIS */}
                        <Tutorial hidden={this.state.isLoading} steps={childrenSteps} />
                        <ChildrenSwitchButton
                            slot="end"
                            onClick={() => {
                                this.setState({ showChildSwitch: true });
                            }}
                        />
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className='childrenContentGrid'>
                        {
                            <IonCard className='childrenCard'>
                                <IonCardHeader>
                                    <IonCardTitle>Lista delle schede</IonCardTitle>
                                    <IonCardSubtitle className="childrenCardSubtitle">
                                        Seleziona una scheda per modificarla o per invitare professionisti
                                    </IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className='childrenCardContent'>
                                    {
                                        this.props.childrenList.length === 0 &&
                                        <p>
                                            Per iniziare crea una nuova scheda premendo sul pulsante + in basso a destra. {/* TO LOCALIZE THIS */}
                                        </p>
                                    }
                                    <IonList className='childrenList'>
                                        {/* {
                                            this.state.isLoading &&
                                            <div className="childrenListLoadingDiv">
                                                <IonSpinner />
                                            </div>
                                        } */}

                                        {
                                            this.props.childrenList.length > 0 &&
                                            this.props.childrenList.map((child) => {
                                                return (
                                                    <IonItem
                                                        key={child.uuid}
                                                        routerLink={`/dashboard?child=${child.uuid}`}
                                                        onClick={() => {
                                                            this.props.setCurrentChild(child, this.state.userUuid);
                                                        }}
                                                    >
                                                        {
                                                            child.firstName || child.lastName ?
                                                                (
                                                                    <>
                                                                        {child.firstName}
                                                                        {' '}
                                                                        {child.lastName}
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <div
                                                                        style={{
                                                                            color: 'gray'
                                                                        }}
                                                                    >
                                                                        <i>Nome assente</i>
                                                                    </div>
                                                                )
                                                        }

                                                        <IonIcon
                                                            icon={radioButtonOn}
                                                            color='success'
                                                            slot='end'
                                                            style={{ fontSize: '28px' }}
                                                            hidden={this.props.currentChild ? this.props.currentChild.uuid !== child.uuid : true}
                                                        />
                                                    </IonItem>
                                                )
                                            })
                                        }
                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        }
                    </IonGrid>
                    <IonFab className="addChild" vertical='bottom' horizontal='end' slot='fixed'>
                        <IonFabButton
                            onClick={() => {
                                this.setState({ childCreationLoading: true });
                                const createChild = httpsCallable(functions, "createChild-createChild");
                                createChild({
                                })
                                    .then(data => {
                                        this.setState({ childCreationLoading: false });
                                        //console.log("[Children] received data:", data.data);
                                        if ((data.data as any).error) {
                                            //console.log("[Children] returned error:", (data.data as any).error);
                                        }
                                        else {
                                            //console.log("[Children] child create successfully.");
                                            this.props.history.push(`/dashboard?child=${(data.data as any).child.uuid}`);
                                        }
                                    })
                                    .catch(err => {
                                        this.setState({ childCreationLoading: false });
                                        //console.log("[Children] error calling create child cloud function:", err);
                                    })
                            }}
                        >
                            <IonIcon icon={addOutline} />
                        </IonFabButton>
                    </IonFab>
                </IonContent>
                <FamilyMenuTabBar />

                <ChildrenSwitch
                    isOpen={this.state.showChildSwitch}
                    onDidDismiss={() => {
                        this.setState({
                            showChildSwitch: false,
                        })
                    }}
                    onSelectedChild={(childUuid) => {
                    }}
                />

                <IonLoading
                    isOpen={this.state.childCreationLoading}
                    backdropDismiss={false}
                    message="Sto creando una nuova scheda di lavoro" /* TO BE LOCALIZED */
                />

            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentChild: state.children.currentChild,
        childrenList: state.children.list,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentChild: (child: Child | null, userUuid: string) => {
            dispatch(childrenActions.setCurrentChild(child, userUuid));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Children);