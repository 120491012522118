import { Redirect, Route, Switch } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { store } from 'common/store';
import TasksList from 'modules/tasks/pages/TasksList';
import TaskPlayer from 'modules/tasks/pages/TaskPlayer';
import TaskEditor from 'modules/tasks/pages/TaskEditor';
import BlogHome from 'modules/blog/pages/BlogHome';
import ChildDashboard from 'modules/user/pages/ChildDashboard';
import UserSettings from 'modules/user/pages/UserSettings';
import BlogPostViewer from 'modules/blog/pages/BlogPostViewer';
import PBList from 'modules/problem-behaviour/pages/PBList';
import PBCreation from 'modules/problem-behaviour/pages/PBCreation';
import AutonomySessionNotes from 'modules/tasks/pages/AutonomySessionNotes';
import DataCollectionHome from 'modules/data-collection/pages/DataCollectionHome';
import TaskStepsDataCollection from 'modules/data-collection/pages/TaskStepsDataCollection';
import Children from 'modules/user/pages/Children';
import TaskChart from 'modules/data-collection/pages/TaskChart';
import SessionList from 'modules/data-collection/pages/SessionList';
import PrivacyPolicy from 'modules/legal/pages/PrivacyPolicy';
import TermsConditions from 'modules/legal/pages/TermsConditions';
import HowTo from 'modules/tutorials/pages/HowTo';
import GetStarted from 'modules/authentication/pages/GetStarted';
import MagicLink from 'modules/authentication/pages/MagicLink';
import Invitation from 'modules/children/pages/Invitation';
import Purchase from 'modules/purchase/pages/Purchase';
import PaymentCompleted from 'modules/purchase/pages/PaymentCompleted';
import Login from 'modules/authentication/pages/Login';
import ResetPassword from 'modules/authentication/pages/ResetPassword';
import EmailSent from 'modules/authentication/pages/EmailSent';
import PaymentProcessing from 'modules/purchase/pages/PaymentProcessing';
import UpdateEmail from 'modules/user/pages/UpdateEmail';
import Chat from 'modules/chat/pages/Chat';

function PrivateRoute({ ...rest }: any) {
    let isAuthenticated = store.getState().auth.userData ? true : false;
    //console.log("[Router] isAuthenticated", isAuthenticated);
    return (
        <Route
            {...rest}
            render={isAuthenticated ? rest['render'] : () => <Redirect to="/authentication/get-started" />}
        />
    );
}


function Router() {
    return (
        <IonReactRouter>
            <IonRouterOutlet>
                <Switch>
                    {/* <Route path="/onboarding" component={Onboarding} exact={true} /> */}

                    <Route exact path="/authentication/get-started" component={GetStarted} />
                    <Route exact path="/authentication/login" component={Login} />
                    <Route exact path="/authentication/reset-password" component={ResetPassword} />
                    <Route exact path="/authentication/password-reset-email-sent" component={EmailSent} />
                    <Route exact path="/authentication/magic-link" component={MagicLink} />
                    <Route path="/invitation" component={Invitation} />


                    <Route
                        path='/legal/privacy-policy'
                        render={(routeProps) => <PrivacyPolicy {...routeProps} />}
                    />

                    <Route
                        path='/legal/terms-conditions'
                        render={(routeProps) => <TermsConditions {...routeProps} />}
                    />

                    <Route
                        path='/legal/cookie-policy'
                        render={(routeProps) => <PrivacyPolicy {...routeProps} />}
                    />

                    <Route
                        path='/how-to'
                        render={(routeProps) => <HowTo {...routeProps} />}
                    />

                    <Route
                        path='/legal/cookie-policy'
                        render={(routeProps) => <PrivacyPolicy {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/dashboard/chat"
                        render={(routeProps) => <Chat  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/dashboard"
                        render={(routeProps) => <ChildDashboard  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/settings"
                        render={(routeProps) => <UserSettings  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/settings/update-email"
                        render={(routeProps) => <UpdateEmail  {...routeProps} />}
                    />


                    <PrivateRoute
                        exact path="/children"
                        render={(routeProps) => <Children  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/tasks/list"
                        render={(routeProps) => <TasksList  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/tasks/edit"
                        render={(routeProps) => <TaskEditor  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/autonomies/task/notes"
                        render={(routeProps) => <AutonomySessionNotes  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/tasks/player"
                        render={(routeProps) => <TaskPlayer {...routeProps} />}
                    />


                    <PrivateRoute
                        exact path="/autonomies/problems/new"
                        render={(routeProps) => <PBCreation  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/problem-behaviours"
                        render={(routeProps) => <PBList  {...routeProps} />}
                    />


                    <PrivateRoute
                        exact path="/data-collection/home"
                        render={(routeProps) => <DataCollectionHome {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/data-collection/collect"
                        render={(routeProps) => <TaskStepsDataCollection  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/data-collection/chart"
                        render={(routeProps) => <TaskChart  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/tasks/sessions"
                        render={(routeProps) => <SessionList  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/blog"
                        render={(routeProps) => <BlogHome  {...routeProps} />}
                    />
                    <PrivateRoute
                        path="/blog/:blogPostId"
                        render={(routeProps) => <BlogPostViewer  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/purchase"
                        render={(routeProps) => <Purchase  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/payment-completed"
                        render={(routeProps: any) => <PaymentCompleted  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/processing-payment"
                        render={(routeProps: any) => <PaymentProcessing  {...routeProps} />}
                    />

                    <Redirect to="/data-collection/home" />
                </Switch>
            </IonRouterOutlet>
        </IonReactRouter>
    );
}

export default Router;