import {
    IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon,
    IonImg, IonItem, IonList, IonPage, IonRow, IonTitle,
    IonToolbar, isPlatform
} from "@ionic/react";
import { add, ellipsisVertical, play } from "ionicons/icons";
import { autonomiesActions } from 'modules/tasks/store/actions';
import ChildrenSwitch from "modules/children/components/ChildrenSwitch";
import ChildrenSwitchButton from "modules/children/components/ChildrenSwitchButton";
import { Child } from "modules/children/store/reducers";
import FamilyMenuTabBar from "modules/user/pages/FamilyMenuTabBar";
import { User } from "modules/user/store/reducers";
import React from "react";
import { connect } from 'react-redux';
import TelegramBanner from "../components/TelegramBanner";
import { Task, Step } from "../store/reducers";
import { tasksServices } from "../store/services";
import { auth, database } from "firebase";
import { get, ref as dbRef, update } from "firebase/database";
import TaskSkeleton from "modules/data-collection/components/TaskSkeleton";
import './TasksList.css';
import Tutorial from "common/In-App Tutorial/Tutorial";
import { taskListSteps } from "common/tutorialSteps";

type Props = {
    history: any,
    userData: User,
    currentChild: Child | null,

    selectAutonomy: (autonomyUuid: string | null) => void,
}

type State = {
    child: Child | null,
    tasksList: Task[],
    showChildSwitch: boolean,
    showTelegram: boolean,

    isLoadingTasks: boolean,
    userUuid: string | null,
}

class TasksList extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            child: null,
            tasksList: [],
            showChildSwitch: false,
            showTelegram: false,

            isLoadingTasks: false,
            userUuid: null,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(user => {
            if (user) {
                this.setState({ userUuid: user.uid });

                get(dbRef(database, `u/${user.uid}/z/t`))
                    .then(data => {
                        this.setState({ showTelegram: data.val() ? false : true })
                    })
                    .catch(err => {
                        console.error("[TasksList] error getting user telegram preference:", err);
                    });

            }
            else {
                this.setState({ userUuid: null });
            }
        })

        if (this.props.currentChild && this.props.currentChild.uuid) {
            this.getAutonomiesForChild(this.props.currentChild.uuid);
        }
    }

    getAutonomiesForChild(childUuid: string) {
        this.setState({ isLoadingTasks: true });
        tasksServices.fetchAutonomiesList(childUuid)
            .then(tasks => {
                this.setState({
                    isLoadingTasks: false,
                    tasksList: tasks
                });
            })
            .catch(err => {
                //console.log("[TasksList] fetch tasks error:", err);
                this.setState({ isLoadingTasks: false });
            })
    }

    showPlayButton(steps: Step[]): boolean {
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].videoGsUrl || steps[i].imageGsUrl) { // TO DO: VIDEO PUBLIC URL
                return true;
            }
        }
        return false;
    }


    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonTitle>Task</IonTitle>
                        <Tutorial steps={taskListSteps} />
                        <ChildrenSwitchButton
                            slot="end"
                            onClick={() => {
                                this.setState({
                                    showChildSwitch: true,
                                })
                            }}
                        />
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className='autonomySettingsContentGrid'>
                        {
                            !this.props.currentChild &&
                            <IonGrid>
                                <IonRow class='autonomySettingsHintText'>

                                    <div
                                        className="tasksListGuideHintDiv"
                                        onClick={() => {
                                            this.props.history.replace("/children");
                                        }}
                                    >
                                        <p>
                                            <i>
                                                Per prima cosa è necessario creare o selezionare una scheda di lavoro dal menu in basso. {/* TO LOCALIZE THIS */}
                                            </i>
                                        </p>
                                    </div>
                                </IonRow>
                            </IonGrid>
                        }
                        {
                            this.props.currentChild &&
                            <IonCard className='autonomySettingsAutonomiesCard taskList'>
                                <IonCardHeader>
                                    <IonCardTitle>
                                        {`Task${this.props.currentChild && this.props.currentChild.firstName ? ` di ${this.props.currentChild.firstName}` : ''}`}
                                    </IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {
                                        this.state.isLoadingTasks &&
                                        <div>
                                            {/* <IonSpinner /> */}
                                            <TaskSkeleton skeletonNumber={3} buttonNumber={1} />
                                        </div>
                                    }
                                    {
                                        !this.state.isLoadingTasks &&
                                        this.state.tasksList.length > 0 &&
                                        <IonList className='tasksListTasksList'>
                                            {
                                                this.state.tasksList.map(task => {
                                                    return (

                                                        <IonItem
                                                            key={task.uuid}
                                                            className='autonomySettingsListItem'
                                                        >
                                                            <IonGrid class='autonomyGrid'>

                                                                <p className="dataCollectionItemTitle">
                                                                    {task.title}
                                                                </p>

                                                                {
                                                                    task.imageUrl &&
                                                                    isPlatform("desktop") &&
                                                                    <IonRow class="tasksListTaskPreviewRow">
                                                                        <IonImg
                                                                            src={task.imageUrl}
                                                                        />
                                                                    </IonRow>
                                                                }

                                                                {
                                                                    this.showPlayButton(task.steps) &&
                                                                    <IonRow
                                                                        className="tasksListPlayTaskRow playButton"
                                                                        onClick={() => {
                                                                            this.props.history.push(`/tasks/player?task=${task.uuid}`);
                                                                        }}
                                                                    >
                                                                        <IonButton
                                                                            fill="clear"
                                                                            onClick={() => {
                                                                                this.props.history.push(`/tasks/player?task=${task.uuid}`);
                                                                            }}
                                                                        >
                                                                            <IonIcon
                                                                                icon={play}
                                                                                size="large"
                                                                            />
                                                                        </IonButton>
                                                                        <p className="tasksListButtonLabelParagraph">
                                                                            Riproduci  {/* TO BE LOCALIZED */}
                                                                        </p>
                                                                    </IonRow>
                                                                }

                                                                <IonRow
                                                                    className="tasksListEditTaskRow editButton"
                                                                    onClick={() => {
                                                                        this.props.selectAutonomy(task.uuid);
                                                                        this.props.history.push(`/tasks/edit?task=${task.uuid}`);
                                                                    }}
                                                                >
                                                                    <IonButton
                                                                        fill="clear"
                                                                    >
                                                                        <IonIcon
                                                                            icon={ellipsisVertical}
                                                                            size="large"
                                                                        />
                                                                    </IonButton>
                                                                    <p className="tasksListButtonLabelParagraph">Modifica</p> {/* TO LOCALIZE THIS */}
                                                                </IonRow>
                                                            </IonGrid>
                                                        </IonItem>
                                                    )
                                                })}
                                        </IonList>
                                    }

                                    {
                                        !this.state.isLoadingTasks &&
                                        this.state.tasksList.length < 1 &&
                                        this.props.currentChild &&
                                        <IonGrid>
                                            {/* <IonRow class='autonomySettingsHintText'>
                                                <IonText>Qui puoi creare o modificare una task.</IonText>
                                            </IonRow> */}

                                            <IonRow class='autonomySettingsHintText'>
                                                {/* <IonText>Non sono presenti task, premere sul pulsante + in basso a destra.</IonText> */}

                                                <div
                                                    onClick={() => {
                                                        window.analytics.track({
                                                            "event": "View Tutorial",
                                                            "properties": {
                                                                "category": "Tutorial",
                                                                "label": auth.currentUser.uid,
                                                            }
                                                        })
                                                        window.open("https://ma.estro.ai/how-to", "_system");
                                                    }}
                                                    className="tasksListGuideHintDiv"
                                                >
                                                    <p className="tasksListGuideEmojiDiv">
                                                        🧐
                                                    </p>
                                                    <p>
                                                        <i>
                                                            Fossimo in te, leggeremmo il breve pdf scritto per farti risparmiare tempo e ottenere il massimo nel tuo lavoro grazie a Maestro. Clicca qui.
                                                        </i>
                                                    </p>
                                                </div>
                                            </IonRow>
                                        </IonGrid>
                                    }
                                </IonCardContent>
                            </IonCard>
                        }

                        {/* {
                            this.state.tasksList.length >= 1 &&
                            this.state.showTelegram &&
                            <TelegramBanner
                                onCloseBanner={() => {
                                    this.setState({ showTelegram: false });
                                    update(dbRef(database, `u/${this.state.userUuid}/z`), {
                                        t: false,
                                    })
                                        .then(() => {

                                        })
                                        .catch(err => {
                                            //console.log("[TasksList error updating user telegram personalization]", err); // TO DEBUG
                                        });
                                }}
                                onJoinButtonClick={() => {
                                    this.setState({ showTelegram: false });
                                    update(dbRef(database, `u/${this.state.userUuid}/z`), {
                                        t: true,
                                    })
                                        .then(() => {

                                        })
                                        .catch(err => {
                                            //console.log("[TasksList error updating user telegram personalization]", err); // TO DEBUG
                                        });
                                }}
                            />
                        } */}
                    </IonGrid>

                    {
                        this.props.currentChild &&
                        <IonFab
                            vertical="bottom"
                            horizontal="end"
                            slot="fixed"
                            className={this.state.tasksList.length > 0 ? " addTask" : "tasksListAddTaskButtonAnimated addTask"}
                        >
                            <IonFabButton
                                onClick={() => {
                                    window.analytics.track({
                                        "event": "Add task button",
                                        "properties": {
                                            "category": "Task add",
                                            "label": auth.currentUser.uid,
                                        }
                                    })
                                    this.props.selectAutonomy(null);
                                    this.props.history.push('/tasks/edit')
                                }}
                            >
                                <IonIcon icon={add} />
                            </IonFabButton>
                        </IonFab>
                    }
                </IonContent>

                <FamilyMenuTabBar />

                <ChildrenSwitch
                    isOpen={this.state.showChildSwitch}
                    onDidDismiss={() => {
                        this.setState({
                            showChildSwitch: false,
                        })
                    }}
                    onSelectedChild={(child) => {
                        this.getAutonomiesForChild(child.uuid);
                    }}
                />
            </IonPage >
        );
    }
}

const mapStateToProps = state => {
    return {
        currentChild: state.children.currentChild,
        userData: state.auth.userData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectAutonomy: (autonomyUuid: string) => {
            dispatch(autonomiesActions.selectAutonomy(autonomyUuid))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksList);