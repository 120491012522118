import { tutorialsConstants } from './constants';

export interface TutorialsStore  {
    showOnboarding: boolean,
}

const initialState: TutorialsStore = {
    showOnboarding: true,
}

const tutorials = (state = initialState, action: any) => {
    switch (action.type) {

        case tutorialsConstants.SHOW_ONBOARDING:
            return {
                ...state,
                showOnboarding: action.show,
            }
            
        default:
            return state

    }
}

export default tutorials;