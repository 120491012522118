import { User } from "firebase/auth";
import { authConstants } from "./constants";

export interface AuthenticationStore  {
  userData: User | null,
}

const initialState: AuthenticationStore = {
  userData: null,
}

const Authentication = (state = initialState, action: any) => {

  switch (action.type) {

    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.userData
      }

    case authConstants.LOGOUT:
    case authConstants.LOGIN_FAILED:
      return {
        ...state,
        userData: null,
      }

    default:
      return state

  }
}

export default Authentication;
