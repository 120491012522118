import { push, update } from "@firebase/database";
import {
    IonAccordion, IonAccordionGroup, IonBackButton,
    IonButton, IonButtons, IonCard, IonContent, IonFab, IonFabButton,
    IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel,
    IonList, IonLoading, IonPage, IonPopover, IonRow, IonSpinner, IonTitle,
    IonToast, IonToolbar
} from "@ionic/react";
import { fromQueryToObjOfParams } from "common/queryUtils";
import { auth, database } from "firebase";
import { ref } from "firebase/database";
import { addOutline, checkmarkSharp } from "ionicons/icons";
import { Task, Session, Step } from "modules/tasks/store/reducers";
import { tasksServices } from "modules/tasks/store/services";
import { Child } from "modules/children/store/reducers";
import PBCreation from "modules/problem-behaviour/pages/PBCreation";
import PBReport from "modules/problem-behaviour/pages/PBReport";
import { ProblemBehavior } from "modules/problem-behaviour/store/reducers";
import { pbServices } from "modules/problem-behaviour/store/services";
import React from "react";
import { connect } from 'react-redux';
import alarmIcon from '../../../assets/icons/alarm.svg';
import BooleanStep from "../components/BooleanStep";
import PromptStep from "../components/PromptStep";
import VotesStep from "../components/VotesStep";
import './TaskStepsDataCollection.css';
import Tutorial from "../../../common/In-App Tutorial/Tutorial";
import { dataCollectionDataCollectionSteps } from "common/tutorialSteps";
import NotesCreation from "../components/NotesCreation";

interface Interaction {
    stepUuid?: string,
    value?: number | string,
    timestamp: number,
}

type AutonomyProps = {
    history: any,

    currentChild: Child,

    currentSession: Session,
}

type State = {
    /* autonomyTitle: string | null, */
    taskTitle: string,

    lastStepInteracted: string | null,
    selectedTask: Task | null,
    stepsList: Step[],
    stepVote: number | null,
    stepKey: number,
    stepVoteKey: number,
    assignedVotes: number[],
    disabledButtons: number[],
    stepsPoints: number[],

    interactions: Interaction[],
    currentSessionUuid: string | null,

    endSession: boolean,
    showPbPopover: boolean,
    problemBehaviorList: ProblemBehavior[],
    showPBReportAddDetailsPopover: boolean,
    selectedPbUuid: string,
    showPbReportedToast: boolean,
    showPbCreationPopover: boolean,
    pbActionsSheetButtons: any[],

    isLoadingTask: boolean,
    isLoadingPb: boolean,
    taskHasMedia: boolean,

    showNotesCreationPopover: boolean,
}

class TaskStepsDataCollection extends React.Component<AutonomyProps, State> {
    button = document.getElementsByClassName('button') as HTMLCollectionOf<HTMLButtonElement>

    constructor(props) {
        super(props);
        this.state = {
            /* autonomyTitle: this.props.currentAutonomy ? this.props.currentAutonomy.title : null, */
            taskTitle: '',
            stepVote: 0,

            lastStepInteracted: null,
            selectedTask: null,
            stepsList: [],
            stepKey: -1,
            assignedVotes: [],
            stepVoteKey: -1,
            interactions: [],
            disabledButtons: [],
            stepsPoints: [],
            currentSessionUuid: null,

            endSession: true, //handles the component will unmount to end the session or not
            showPbPopover: false,
            problemBehaviorList: [],
            showPBReportAddDetailsPopover: false,
            selectedPbUuid: '',
            showPbReportedToast: false,
            showPbCreationPopover: false,
            pbActionsSheetButtons: [],

            isLoadingTask: false,
            isLoadingPb: false,
            taskHasMedia: false,

            showNotesCreationPopover: false,
        }
    }


    componentDidMount() {
        const taskId = fromQueryToObjOfParams(this.props.history.location.search as string)["task"];

        if (taskId) {
            this.setState({ isLoadingTask: true });
            tasksServices.fetchTask(taskId)
                .then(task => {
                    this.setState({ isLoadingTask: false });
                    if (task) {
                        this.setState({
                            selectedTask: task,
                            stepsList: task.steps,
                        });
                        task.steps.forEach(step => {
                            if (step.imageGsUrl !== null || step.videoGsUrl !== null) {
                                this.setState({ taskHasMedia: true })
                                return
                            }
                        })
                    }
                })
                .catch(err => {
                    this.setState({ isLoadingTask: false });
                    //console.log("[TaskStepsDataCollection] error fetching task:", err);
                });
            this.fetchPbList(this.props.currentChild.uuid);
        }
    }

    fetchPbList(childUuid: string) {
        pbServices.fetchPbList(childUuid)
            .then(pbList => {
                this.setState({ problemBehaviorList: pbList });
            })
            .catch(err => {
                //console.log("[PBList] error fetching pb list:", err)
            });
    }

    async createInteraction(stepUuid?: string, value?: number | string, pbUuid?: string) {
        let nowTimestamp = Math.floor(new Date().getTime());

        if (!pbUuid) {
            this.setState({ lastStepInteracted: stepUuid });

            let interaction: Interaction = {
                stepUuid: stepUuid,
                value: value,
                //p: pbUuid ? pbUuid : '',
                timestamp: nowTimestamp,
            }

            if (this.state.currentSessionUuid === null) {
                try {
                    const newSessionUuid = await this.createSession(this.props.currentChild.uuid, this.state.selectedTask.uuid, this.state.selectedTask.dataCollectionMode)
                    this.setState({ currentSessionUuid: newSessionUuid });
                }
                catch (e) {
                    console.error("[TaskStepsDataCollection] error creating new session:", e);
                }
            }

            if (this.state.currentSessionUuid) {
                const interactionToUpdate = this.state.interactions.filter(i => i.stepUuid === stepUuid);

                if (interactionToUpdate.length > 0 /* && !interaction.p */) {
                    if (interactionToUpdate.length === 1) {
                        try {
                            await update(ref(database, `s/${this.props.currentChild.uuid}/${this.state.currentSessionUuid}/d/${interactionToUpdate[0].timestamp}`), {
                                v: value,
                            });
                        }
                        catch (e) {
                            console.error("[TaskStepsDataCollection] error updating data:", e);
                        }
                        interaction.timestamp = interactionToUpdate[0].timestamp;
                    }

                    let newInteractions = this.state.interactions.map(i => (i.stepUuid === interaction.stepUuid) ? interaction : i);
                    this.setState({ interactions: newInteractions });
                }
                else {
                    this.state.interactions.push(interaction);
                    try {
                        await update(ref(database, `s/${this.props.currentChild.uuid}/${this.state.currentSessionUuid}/d/${nowTimestamp}`), {
                            s: stepUuid,
                            v: value,
                        });
                    }
                    catch (e) {
                        console.error("[TaskStepsDataCollection] error registering data:", e);
                    }
                }
            }
        }
        else {
            if (this.state.currentSessionUuid === null) {
                try {
                    const newSessionUuid = await this.createSession(this.props.currentChild.uuid, this.state.selectedTask.uuid, this.state.selectedTask.dataCollectionMode)
                    this.setState({ currentSessionUuid: newSessionUuid });
                }
                catch (e) {
                    console.error("[TaskStepsDataCollection] error creating new session:", e);
                }
            }

            if (this.state.currentSessionUuid) {
                const objToWrite = {
                    p: pbUuid
                };
                if (value) {
                    Object.assign(objToWrite, {
                        v: String(value).trim(),
                    });
                }
                if (stepUuid) {
                    Object.assign(objToWrite, {
                        s: stepUuid,
                    });
                }

                try {
                    await update(ref(database, `s/${this.props.currentChild.uuid}/${this.state.currentSessionUuid}/d/${nowTimestamp}`), objToWrite);
                }
                catch (e) {
                    console.error("[TaskStepsDataCollection] error registering data:", e);
                }
            }
        }
    }

    async componentWillUnmount() {
        let nowTimestamp = Math.floor(new Date().getTime() / 1000);

        if (this.state.interactions.length > 0) {
            try {
                await update(ref(database, `s/${this.props.currentChild.uuid}/${this.state.currentSessionUuid}/`), {
                    e: nowTimestamp
                });
            }
            catch (e) {
                console.error("[TaskStepsDataCollection] error ending session:", e);
            }

            this.props.history.push('/data-collection/home');
        }
    }

    async createSession(childUuid: string, taskUuid: string, mode: number): Promise<string | null> {
        const nowTimestamp = Math.floor(new Date().getTime() / 1000);
        let newSessionUuid: string | null = null;
        try {
            const newSessionResult = await push(ref(database, `s/${childUuid}/`), {
                t: taskUuid,
                m: mode,
                s: nowTimestamp,
                u: auth.currentUser.uid
            });
            //console.log("[TaskStepsDataCollection] session id:", newSessionResult.key); // TO DEBUG
            newSessionUuid = newSessionResult.key;

        }
        catch (e) {
            console.error("[TaskStepsDataCollection] error creating new session:", e);
        }
        return newSessionUuid;
    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/data-collection/home" />
                        </IonButtons>
                        <Tutorial hidden={this.state.isLoadingTask} steps={dataCollectionDataCollectionSteps} />
                        <IonTitle>
                            {this.state.selectedTask ? this.state.selectedTask.title : 'Nuova task'}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonGrid className='autonomyTasksContentGrid'>
                        <IonCard style={{ boxShadow: 'none' }}>
                            <IonGrid>
                                <IonRow>
                                    <IonInput
                                        class='autonomyTitleInput'
                                        value={this.state.selectedTask ? this.state.selectedTask.title : ''}
                                        readonly={true}
                                    />
                                </IonRow>
                            </IonGrid>
                        </IonCard>

                        {
                            this.state.isLoadingTask &&
                            <div className="taskStepsDataCollectionSpinnerDiv">
                                <IonSpinner />
                            </div>
                        }

                        {
                            this.state.isLoadingPb &&
                            <IonLoading
                                isOpen={true}
                                backdropDismiss={false}
                                message='Creando il comportamento problema...' /* TO BE LOCALIZED */
                            />
                        }

                        {
                            !this.state.isLoadingTask &&
                            this.state.selectedTask &&
                            this.state.stepsList.length === 0 &&
                            <p
                                className="taskStepsDataCollectionAddStepCTAParagraph"
                                onClick={() => {
                                    this.props.history.push(`/tasks/edit?task=${this.state.selectedTask.uuid}`)
                                }}
                            >
                                Non sono presenti step per questa task. Fai tap qui per crearne uno.
                            </p>
                        }
                        <IonAccordionGroup class="stepsList" value={this.state.stepsList[0]?.uuid} multiple>

                            {
                                this.state.selectedTask &&
                                this.state.stepsList.map((step, i) => {
                                    return (
                                        <>
                                            {/* STEP WITH MEDIA */}
                                            {this.state.taskHasMedia &&
                                                <IonAccordion value={step.uuid} >
                                                    <IonItem slot="header">
                                                        <p className="taskStepsDataCollectionTitleParagraph">
                                                            <IonLabel>{step.index + 1} - {step.title}</IonLabel>
                                                        </p>
                                                    </IonItem>

                                                    <IonList slot="content" className="taskStepsDataCollectionList">
                                                        <IonRow
                                                            key={step.uuid}
                                                            className='taskStepsDataCollectionRow'
                                                            style={{
                                                                flexDirection: this.state.selectedTask.dataCollectionMode === 1 || this.state.selectedTask.dataCollectionMode === 2 ? "column" : "row",
                                                                alignItems: this.state.selectedTask.dataCollectionMode === 1 ? "" : "center",
                                                                justifyContent: this.state.selectedTask.dataCollectionMode === 1 ? "center" : "",
                                                            }}
                                                        >
                                                            { // + or -
                                                                this.state.selectedTask.dataCollectionMode === 0 &&
                                                                <BooleanStep
                                                                    onInteraction={(value) => {
                                                                        this.createInteraction(step.uuid, value);
                                                                    }}
                                                                />
                                                            }

                                                            { //PP / PT / A
                                                                this.state.selectedTask.dataCollectionMode === 1 &&
                                                                <PromptStep
                                                                    onInteraction={(value) => {
                                                                        this.createInteraction(step.uuid, value);
                                                                    }}
                                                                />
                                                            }

                                                            { // votes
                                                                this.state.selectedTask.dataCollectionMode === 2 &&
                                                                <VotesStep
                                                                    onInteraction={(value) => {
                                                                        this.createInteraction(step.uuid, value);
                                                                    }}
                                                                />
                                                            }
                                                        </IonRow>
                                                        {
                                                            step.imageGsUrl &&
                                                            <img
                                                                className="modelingPlayerMedia"
                                                                src={step.imageGsUrl}
                                                                alt={step.title}
                                                            />
                                                        }
                                                        {
                                                            step.videoGsUrl &&
                                                            <video
                                                                /* id='video-player' */
                                                                preload="auto"
                                                                /* autoPlay={true}
                                                                webkit-playsinline="webkit-playsinline" */
                                                                className="modelingPlayerMedia"
                                                                /* poster={this.state.selectedTask.steps[this.state.currentStep].preview} */
                                                                /* playsInline={true} */
                                                                //muted={this.props.selectedAutonomy.settings.use_sound}
                                                                controls
                                                            >
                                                                <source src={`${step.videoGsUrl}#t=0.1`} />
                                                            </video>
                                                        }
                                                    </IonList>

                                                </IonAccordion>
                                            }
                                            {/* STEP WITH NO MEDIA */}
                                            {!this.state.taskHasMedia &&
                                                <IonRow
                                                    key={step.uuid}
                                                    className='taskStepsDataCollectionRow'
                                                    style={{
                                                        flexDirection: this.state.selectedTask.dataCollectionMode === 1 || this.state.selectedTask.dataCollectionMode === 2 ? "column" : "row",
                                                        alignItems: this.state.selectedTask.dataCollectionMode === 1 ? "" : "center",
                                                        justifyContent: this.state.selectedTask.dataCollectionMode === 1 ? "center" : "",
                                                    }}
                                                >
                                                    <p
                                                        className="taskStepsDataCollectionTitleParagraph"
                                                    /* size={this.state.selectedTask.settings.data_collection_mode === 0 ? '8' : this.state.selectedTask.settings.data_collection_mode === 1 ? '12' : '10'} */
                                                    >
                                                        {step.title}
                                                    </p>

                                                    { // + or -
                                                        this.state.selectedTask.dataCollectionMode === 0 &&
                                                        <BooleanStep
                                                            onInteraction={(value) => {
                                                                this.createInteraction(step.uuid, value);
                                                            }}
                                                        />
                                                    }

                                                    { //PP / PT / A
                                                        this.state.selectedTask.dataCollectionMode === 1 &&
                                                        <PromptStep
                                                            onInteraction={(value) => {
                                                                this.createInteraction(step.uuid, value);
                                                            }}
                                                        />
                                                    }

                                                    { // votes
                                                        this.state.selectedTask.dataCollectionMode === 2 &&
                                                        <VotesStep
                                                            onInteraction={(value) => {
                                                                this.createInteraction(step.uuid, value);
                                                            }}
                                                        />
                                                    }
                                                </IonRow>

                                            }
                                        </>
                                    )
                                })
                            }

                        </IonAccordionGroup>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px" }}>
                            <IonButton
                                class='taskStepsDataCollectionEndSessionButton endSessionButton'
                                onClick={() => {
                                    this.setState({ showNotesCreationPopover: true })
                                }}
                            >
                                Aggiungi note
                                <IonIcon icon={addOutline} slot='end' />
                            </IonButton>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px" }}>
                            <IonButton
                                class='taskStepsDataCollectionEndSessionButton endSessionButton'
                                onClick={() => {
                                    this.setState({ endSession: true })
                                    this.props.history.push('/data-collection/home');
                                }}
                            >
                                Termina sessione
                                <IonIcon icon={checkmarkSharp} slot='end' />
                            </IonButton>
                        </div>

                    </IonGrid>

                    <IonFab
                        vertical="bottom"
                        horizontal="start"
                        slot="fixed"
                    >
                        <IonFabButton
                            className="pbFabButton"
                            onClick={() => {
                                this.fetchPbList(this.props.currentChild.uuid)
                                this.setState({ endSession: false, showPbPopover: true })
                            }}
                            color='light'
                        >
                            <IonImg
                                src={alarmIcon}
                                style={{ width: '30px' }}
                            />
                        </IonFabButton>
                    </IonFab>
                </IonContent>

                {/*  <IonActionSheet
                    isOpen={this.state.showPbPopover}
                    onDidDismiss={() => this.setState({ showPbPopover: false })}
                    header='Segnala un comportamento problema'
                    subHeader='Comportamenti problema esistenti:'
                    buttons={this.getPbActionSheetButtons()}
                >
                </IonActionSheet> */}
                <IonPopover
                    mode="md"
                    className="pbListPopover"
                    backdropDismiss={true}
                    onDidDismiss={() => this.setState({ showPbPopover: false })}
                    isOpen={this.state.showPbPopover}
                >
                    <div className="pbListMainDiv">
                        <h2 className="pbListHeader">Segnala un Comportamento Problema</h2>
                        <p style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => this.setState({ showPbCreationPopover: true })}><i>Aggiungi nuovo</i></p>
                        {this.state.problemBehaviorList.map(pb => {
                            if (pb.active === true || pb.active === null)
                                return (
                                    <IonRow className="pbListRow">
                                        <IonItem onClick={() => this.setState({ showPBReportAddDetailsPopover: true, selectedPbUuid: pb.uuid })} lines="none" className="pbListItem">{pb.name}</IonItem>
                                    </IonRow>
                                )
                        })}
                    </div>
                </IonPopover>

                <NotesCreation
                    currentChild={this.props.currentChild}
                    isOpen={this.state.showNotesCreationPopover}
                    onDidDismiss={() => {
                        this.setState({ showNotesCreationPopover: false })
                    }}
                    currentSessionUuid={this.state.currentSessionUuid}
                />

                <PBReport
                    isOpen={this.state.showPBReportAddDetailsPopover}
                    pbUuid={this.state.selectedPbUuid}
                    onPbReported={(notes) => {
                        this.createInteraction(this.state.lastStepInteracted, notes, this.state.selectedPbUuid)
                        this.setState({
                            showPBReportAddDetailsPopover: false,
                            showPbReportedToast: true,
                            selectedPbUuid: null,
                        })
                    }}
                    onDidDismiss={() => {
                        this.setState({
                            selectedPbUuid: null,
                            showPBReportAddDetailsPopover: false,
                        })
                    }}
                />

                <PBCreation
                    isOpen={this.state.showPbCreationPopover}
                    onDidDismiss={() => this.setState({ showPbCreationPopover: false })}
                    onSubmit={() => this.setState({ isLoadingPb: true })}
                    onPbCreated={(newPbList) => {
                        this.setState({ showPbCreationPopover: false, isLoadingPb: false, problemBehaviorList: newPbList, });
                    }}
                />

                <IonToast
                    isOpen={this.state.showPbReportedToast}
                    onDidDismiss={() => this.setState({ showPbReportedToast: false })}
                    message="Comportamento problema registrato. ✅" /* TO BE LOCALIZED */
                    duration={1000}
                />


            </IonPage >
        );
    }

}

const mapStateToProps = state => {
    return {
        currentSession: state.autonomies.currentSession,
        currentChild: state.children.currentChild,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskStepsDataCollection);